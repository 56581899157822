import Cookies from "js-cookie";
import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { language } from "../helpers/Constants";

/**
 * A class for changing the language within the auth pages of the advisor dashboard
 */
class AuthLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  // Changes the language based on the icon clicked
  changeLanguage(newLanguage) {
    // Changes the language in the i18n file
    this.props.i18n.changeLanguage(newLanguage);
    Cookies.set(language, newLanguage);
    window.location.reload();
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{ position: "absolute", top: "0", right: "0", padding: "5px" }}
        >
          <Button
            color="primary"
            onClick={this.changeLanguage.bind(this, "de")}
          >
            DE
          </Button>
          <span> </span>
          <Button
            color="primary"
            onClick={this.changeLanguage.bind(this, "en")}
          >
            EN
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(AuthLanguage);
