import PropTypes from "prop-types";
import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
} from "reactstrap";

/*
 * Class the generates client details within the client detail page
 */
export default class ClientDetailCard extends React.Component {
  render() {
    const { client } = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <h1>
              {client.name}
            </h1>
          </CardTitle>
        </CardHeader>
      </Card>
    );
  }
}

ClientDetailCard.defaultProps = {
  isAdmin: false,
};

ClientDetailCard.propTypes = {
  client: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
