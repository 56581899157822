import {
  faCaretSquareDown,
  faCaretSquareLeft,
  faHistory,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from "reactstrap";
import { formatDate } from "../../helpers/GenericHelper";
import ModalForm from "../components/modal/ModalForm";
import ModalInfo from "../components/modal/ModalInfo";
import UpdateCaseDetail from "./client/UpdateCaseDetail";

/*
 * Class for displaying the card for Meta Information
 */
class MetaInformationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalInfo: false,
      showModalForm: false,
      isView: false,
      metaInformations: this.props.metaInformations,
      latestMetaInformation:
        this.props.metaInformations?.length > 0
          ? this.props.metaInformations[this.props.metaInformations.length - 1]
          : null,
    };
    this.translation = this.props.t;
    this.modalBodyText = [];

    this.viewMetaDataLog = this.viewMetaDataLog.bind(this);
    this.changeMetaInformation = this.changeMetaInformation.bind(this);
    this.toggleModalInfo = this.toggleModalInfo.bind(this);
    this.toggleModalForm = this.toggleModalForm.bind(this);
    this.updateMetaInformation = this.updateMetaInformation.bind(this);
  }

  toggleModalInfo() {
    this.setState({
      showModalInfo: !this.state.showModalInfo,
    });
  }

  toggleModalForm() {
    this.setState({
      showModalForm: !this.state.showModalForm,
    });
  }

  updateMetaInformation(newMetaInformations, latestMetaInformation) {
    this.setState({
      metaInformations: newMetaInformations,
      latestMetaInformation: latestMetaInformation,
    });
    this.toggleModalForm();
  }

  changeMetaInformation() {
    let { latestMetaInformation } = this.state;
    this.modalFormBodyText = (
      <UpdateCaseDetail
        whistleblowerCase={this.props.whistleblowerCase}
        latestMetaInformation={latestMetaInformation}
        updateMetaInformation={this.updateMetaInformation}
        toggleModalForm={this.toggleModalForm}
      />
    );
    this.toggleModalForm();
  }

  viewMetaDataLog() {
    const { metaInformations } = this.state;
    this.modalBodyText = [];

    const sortedMetaInformation = metaInformations.sort((a, b) => {
      return new Date(b.dateTime) - new Date(a.dateTime);
    });
    for (let [i, metaInformation] of sortedMetaInformation.entries()) {
      this.modalBodyText.push(
        this.generateMetaInformationTable(metaInformation, true)
      );
      if (metaInformation.length - 1 !== i) {
        this.modalBodyText.push(<br></br>);
      }
    }
    this.toggleModalInfo();
  }

  generateMetaInformationTable(metaInformation, withDate = false) {
    return (
      <Table>
        <tbody>
          {withDate ? (
            <tr>
              <th style={{ width: "30%" }}>Date:</th>
              <td>{formatDate(metaInformation?.dateTime, true) ?? "-"}</td>
            </tr>
          ) : null}

          <tr>
            <th style={{ width: "30%" }}>
              {this.translation(`commonText.contactData`)}:
            </th>
            <td>{metaInformation?.contactData ?? "-"}</td>
          </tr>
          <tr>
            <th style={{ width: "30%" }}>
              {this.translation(`commonText.violation`)}:
            </th>
            <td>{metaInformation?.violation ?? "-"}</td>
          </tr>
          <tr>
            <th style={{ width: "30%" }}>
              {this.translation(`commonText.occurrenceTime`)}:
            </th>
            <td>{metaInformation?.occurenceTime ?? "-"}</td>
          </tr>
          <tr>
            <th style={{ width: "30%" }}>
              {this.translation(`commonText.location`)}:
            </th>
            <td>{metaInformation?.location ?? "-"}</td>
          </tr>
          <tr>
            <th style={{ width: "30%" }}>
              {this.translation(`commonText.involvedPeople`)}:
            </th>
            <td>{metaInformation?.involvedPeople ?? "-"}</td>
          </tr>
          <tr>
            <th style={{ width: "30%" }}>
              {this.translation(`commonText.witness`)}:
            </th>
            <td>{metaInformation?.witness ?? "-"}</td>
          </tr>
          <tr>
            <th style={{ width: "30%" }}>
              {this.translation(`commonText.expectations`)}:
            </th>
            <td>{metaInformation?.expectations ?? "-"}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
  render() {
    let { latestMetaInformation } = this.state;
    let { whistleblowerCase } = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <h1>
              {this.translation(`commonText.metaInformation`)}
              <div className="float-right">
                {whistleblowerCase?.whistleblowerId === 0 ? (
                  <Button
                    className="mr-1"
                    color="primary"
                    onClick={this.changeMetaInformation}
                    id="editCaseDetail"
                  >
                    <UncontrolledTooltip
                      placement="top"
                      target="editCaseDetail"
                    >
                      {this.translation(`caseDetail.editCaseDetail`)}
                    </UncontrolledTooltip>
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </Button>
                ) : null}
                <Button
                  className="mr-1"
                  color="primary"
                  onClick={this.viewMetaDataLog}
                  id="caseHistory"
                >
                  <UncontrolledTooltip placement="top" target="caseHistory">
                    {this.translation(`caseDetail.caseDetailHistory`)}
                  </UncontrolledTooltip>
                  <FontAwesomeIcon icon={faHistory} />
                </Button>

                <Button
                  color="primary"
                  id="MetaInformationToggle"
                  onClick={() => this.setState({ isView: !this.state.isView })}
                >
                  {this.state.isView ? (
                    <FontAwesomeIcon icon={faCaretSquareDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretSquareLeft} />
                  )}
                </Button>
                <UncontrolledTooltip
                  target="MetaInformationToggle"
                  placement="top"
                >
                  {!this.state.isView ? this.translation(`commonButtonTexts.expand`) : this.translation(`commonButtonTexts.collapse`)}
                </UncontrolledTooltip>
              </div>
            </h1>
          </CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler="#MetaInformationToggle">
          <CardBody>
            {this.generateMetaInformationTable(latestMetaInformation, false)}
          </CardBody>
        </UncontrolledCollapse>

        <ModalForm
          isOpen={this.state.showModalForm}
          modalTitle={this.translation(`caseDetail.editCaseDetail`)}
          toggleModal={this.toggleModalForm}
          ref={this.modalFormBodyText}
        >
          {this.modalFormBodyText}
        </ModalForm>

        <ModalInfo
          isOpen={this.state.showModalInfo}
          modalTitle={this.translation(`caseDetail.caseDetailHistory`)}
          toggleModal={this.toggleModalInfo}
          modalBodyText={this.modalBodyText}
        ></ModalInfo>
      </Card>
    );
  }
}

export default withRouter(withTranslation()(MetaInformationCard));
