import React from "react";
import i18n from "../../i18n";

const Page401 = () => (
  <div className="text-center">
    <h1 className="display-1 font-weight-bold">401</h1>
    <p className="h1">{i18n.t(`errorMessages.accessForbidden`)}</p>
    <p className="h2 font-weight-normal mt-3 mb-4">
      Unathorized Access. Please contact admin
    </p>
  </div>
);

export default Page401;
