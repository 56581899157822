import React from "react";
import CookieConsent from "react-cookie-consent";
import i18n from "../i18n";

export default function CookieBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText={i18n.t(`commonButtonTexts.okay`)}
      cookieName="cookieConsent"
      style={{
        boxShadow: "0 -3px 4px rgba(126, 142, 177, 0.12)",
        background: "white",
        color: "black",
      }}
      buttonStyle={{
        background: "",
        border: "",
        borderRadius: "",
        boxShadow: "",
        color: "",
        cursor: "",
        flex: "0 0 auto",
        padding: "5px 10px",
        margin: "15px",
      }}
      buttonClasses="btn btn-primary"
      expires={150}
    >
      {i18n.t(`cookie.header`)}
      <div>
        {i18n.t(`cookie.list`)}
        <ul>
          <li>{i18n.t(`cookie.language`)}</li>
          <li>{i18n.t(`cookie.token`)}</li>
        </ul>
      </div>
    </CookieConsent>
  );
}
