import {
  faBan,
  faEdit,
  faFileAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import CaseStatusDropdownInput from "../../../components/CaseStatusDropdownInput";
import CaseStatusEnum from "../../../enums/CaseStatusEnum";
import NoteTypeEnum from "../../../enums/NoteTypeEnum";
import {
  LoadingIndicator,
  formatDate,
  getLastCaseStatusFromProps,
  isEmpty,
} from "../../../helpers/GenericHelper";
import { store } from "../../../redux/store/index";
import AssignAdvisorToCaseForm from "../AssignAdvisorToCaseForm/AssignAdvisorToCaseForm";
import ModalConfirm from "../modal/ModalConfirm";
import ModalError from "../modal/ModalError";
import ModalForm from "../modal/ModalForm";
import ModalInfo from "../modal/ModalInfo";
import CaseGroup from "./CaseGroup";
import CaseTitle from "./CaseTitle/CaseTitle";

/**
 * Card Component for case details and editing of case status
 */
class CaseDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advisorOptions: [],
      currentAdvisors: [],
      originalAdvisors: [],
      pendingAdvisors: [],
      whistleblowercase: {},
      showEditStatus: false,
      showEditAdvisor: false,
      showModalConfirm: false,
      showModalError: false,
      showModalForm: false,
      showModalMessage: false,
      showEditDeadline: false,
      deadline: props.whistleblowerCase?.currentDeadline,
      originalStatus: getLastCaseStatusFromProps(
        props.whistleblowerCase?.caseStatuses
      ),
      currentStatus: props.whistleblowerCase?.caseStatuses
        ? props.whistleblowerCase?.caseStatuses[
          props.whistleblowerCase.caseStatuses?.length - 1
        ]?.statusOfCase
        : "",
      reasonValue: "",
      modalConfirmText: [],
    };

    this.translation = this.props.t;

    this.firstIndex = 0;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.showEditStatus = this.showEditStatus.bind(this);
    this.showEditAdvisor = this.showEditAdvisor.bind(this);
    this.showEditDeadline = this.showEditDeadline.bind(this);
    this.updateCaseStatus = this.updateCaseStatus.bind(this);
    this.addCaseNote = this.addCaseNote.bind(this);
    this.cancelStatusUpdate = this.cancelStatusUpdate.bind(this);
    this.cancelAdvisorUpdate = this.cancelAdvisorUpdate.bind(this);
    this.handleCaseStatusChange = this.handleCaseStatusChange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.deadlineOnChange = this.deadlineOnChange.bind(this);
    this.handleAdvisorOnChange = this.handleAdvisorOnChange.bind(this);
    this.toggleModalConfirm = this.toggleModalConfirm.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);

    this.modalForm = null;
    this.toggleModalForm = this.toggleModalForm.bind(this);
    this.onModalFormSubmit = this.onModalFormSubmit.bind(this);
    this.reasonOnChange = this.reasonOnChange.bind(this);
    this.showReason = this.showReason.bind(this);
    this.submitReasonForClosing = this.submitReasonForClosing.bind(this);
    this.toggleModalMessage = this.toggleModalMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        deadline: this.props.whistleblowerCase?.currentDeadline,
        currentStatus: this.props.whistleblowerCase?.caseStatuses
          ? this.props.whistleblowerCase?.caseStatuses[
            this.props.whistleblowerCase.caseStatuses?.length - 1
          ]?.statusOfCase
          : "",
        originalStatus: getLastCaseStatusFromProps(
          this.props.whistleblowerCase?.caseStatuses
        ),
      });
    }
  }

  async componentDidMount() {
    let currentAdvisors = this.props.whistleblowerCase.users.map((option) => {
      return {
        value: option.id,
        label: `${option.lastName}, ${option.firstName}`,
      };
    });

    let options = [];
    axios.advisorService
      .get(`GetAllClientUsers/${this.props.whistleblowerCase?.client.id}`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          options = response.data.filter(
            (advisor) =>
              !this.props.whistleblowerCase.advisors?.some(
                (existingAdvisor) => advisor.userId === existingAdvisor.userId
              )
          );
          options = response.data.map((option) => {
            return {
              value: option.id,
              label: `${option.lastName}, ${option.firstName}`,
            };
          });

          let sortedOptions = options.sort((a, b) => {
            return a["label"] < b["label"]
              ? -1
              : a["label"] > b["label"]
                ? 1
                : 0;
          });
          this.setState({
            advisorOptions: sortedOptions,
            currentAdvisors: currentAdvisors,
            originalAdvisors: currentAdvisors,
          });
        }
      });

    this.keepAlive = setInterval(() => {
      axios.keepAlive.get('keep-alive')
        .then(() => { })
        .catch(() => { })
    }, 300000);
  }

  componentWillUnmount() {
    // Disable keep alive upon component unmount
    clearInterval(this.keepAlive);
    this.keepAlive = 0;
  }

  showEditStatus() {
    this.setState({
      showEditStatus: !this.state.showEditStatus,
    });
  }

  showEditAdvisor() {
    this.setState({
      showEditAdvisor: !this.state.showEditAdvisor,
    });
  }

  showEditDeadline() {
    if (!this.state.showEditDeadline) {
      let previouseDeadline = this.state.deadline;
      this.setState({
        previouseDeadline: previouseDeadline,
        showEditDeadline: !this.state.showEditDeadline,
      });
    } else {
      let previouseDeadline = this.state.previouseDeadline;
      this.setState({
        deadline: previouseDeadline,
        showEditDeadline: !this.state.showEditDeadline,
        previouseDeadline: null,
      });
    }
  }

  updateCaseStatus() {
    let caseData = this.props.whistleblowerCase;
    if (
      caseData.caseStatuses[caseData.caseStatuses.length - 1].statusOfCase !==
      this.state.currentStatus
    ) {
      let newCaseStatus = {
        statusOfCase: this.state.currentStatus,
        dateTime: new moment().toISOString(),
        whistleblowercase: caseData,
      };
      axios.advisorService
        .post(`case-statuses?whistleblowercaseId=${caseData.id}`, newCaseStatus)
        .then(() => {
          this.addCaseNote();
        })
        .catch((error) => {
          this.errorHandler("Update Case Status", error);
        });
    } else {
      this.showEditStatus();
    }
  }

  async addCaseNotesForAssignedAdvisors(){
    let userAccount = await axios.advisorService
      .get(`account`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });
    
    const addedAdvisors = this.state.currentAdvisors
    .filter(advisor => !this.state.originalAdvisors.includes(advisor))
    .map(advisor => advisor.label)
    .join(',');

    const removedAdvisors = this.state.originalAdvisors
    .filter(advisor => !this.state.currentAdvisors.includes(advisor))
    .map(advisor => advisor.label)
    .join(',');

    const noteAction = addedAdvisors.length > 0 ? 
    this.translation('caseDetail.addAssignedAdvisor') : 
    this.translation('caseDetail.removeAssignedAdvisor');

    const notes = `${addedAdvisors.length > 0 ? addedAdvisors : removedAdvisors} ${noteAction}`;

    const newNote = {
    notes,
    creator: `${userAccount.lastName}, ${userAccount.firstName}`,
    operationType: "CREATED",
    noteType: NoteTypeEnum.AUTOMATIC,
    whistleblowercase: this.props.whistleblowerCase?.id !== null ? { id: this.props.whistleblowerCase?.id } : null,
    };

    axios.advisorService
      .post(`notes`, newNote)
      .then((response) => {
        this.showEditStatus();
        this.props.updateNotes(response.data, true);
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });

  }

  async addCaseNote() {
    let userAccount = await axios.advisorService
      .get(`account`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });

    let newNote = {
      notes:
        this.translation(`caseDetail.changeStatus`) +
        ": " +
        this.translation(
          `enumTranslation.${this.state.currentStatus.toLowerCase()}`
        ) +
        " - " +
        this.translation("commonText.modifier") +
        ": " +
        `${userAccount.lastName}, ${userAccount.firstName}`,
      creator: `${userAccount.lastName}, ${userAccount.firstName}`,
      operationType: "CREATED",
      noteType: NoteTypeEnum.AUTOMATIC,
      whistleblowercase:
        this.props.whistleblowerCase?.id !== null
          ? { id: this.props.whistleblowerCase?.id }
          : null,
    };

    axios.advisorService
      .post(`notes`, newNote)
      .then((response) => {
        this.showEditStatus();
        this.props.updateNotes(response.data, true);
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });
  }

  cancelStatusUpdate() {
    this.setState(
      {
        currentStatus: this.state.originalStatus,
      },
      () => {
        this.showEditStatus();
      }
    );
  }

  cancelAdvisorUpdate() {
    this.setState(
      {
        currentAdvisors: this.state.originalAdvisors,
      },
      () => {
        this.showEditAdvisor();
      }
    );
  }

  errorHandler(currentOperation, error) {
    if (currentOperation === "Update Case Status") {
      this.mainError = this.translation(`errorMessages.caseStatusUpdateError`);
    } else if (currentOperation === "Saving Notes") {
      this.mainError = this.translation(`errorMessages.noteSaveError`);
    } else if (currentOperation === "Get Advisors") {
      this.mainError = this.translation(
        `errorMessages.advisorDropdownOptionError`
      );
    } else if (currentOperation === "Assign Advisors") {
      this.mainError = this.translation(`errorMessages.advisorAssignError`);
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  handleValidSubmit(e) {
    e.preventDefault();

    axios.advisorService
      .put("whistleblowercases/assign-advisors", this.props.whistleblowerCase)
      .then(() => {
        this.addCaseNotesForAssignedAdvisors();
        this.showEditAdvisor();
        this.props.history.push(`/case/${this.props.whistleblowerCase.id}`);
      })
      .catch((error) => {
        this.errorHandler("Assign Advisors", error);
      });
  }

  handleAdvisorOnChange(event) {
    
    this.setState({originalAdvisors:this.state.currentAdvisors})
    
    let whistleblowercase = this.props.whistleblowerCase;
    event = event ?? [];
    let advisors = [];
    for (let advisor of event) {
      let newAdvisor = {
        id: advisor.value,
      };
      advisors.push(newAdvisor);
    }
    let sortedEvent = event.sort((a, b) => {
      return a["label"] < b["label"] ? -1 : a["label"] > b["label"] ? 1 : 0;
    });

    whistleblowercase.users = advisors;

    this.setState({
      whistleblowercase: whistleblowercase,
      currentAdvisors: sortedEvent,

    });

  }

  toggleModalConfirm() {
    let newModalBodyText = [
      this.translation(`caseDetail.statusUpdateWarning`),
      this.translation(`caseDetail.statusUpdateWarning2`),
    ];
    if (this.state.currentStatus === CaseStatusEnum.INPROGRESS) {
      newModalBodyText.push(
        this.translation(`caseDetail.statusUpdateWarningInProgress`)
      );
    } else if (this.state.currentStatus === CaseStatusEnum.CLOSED) {
      newModalBodyText.push(
        this.translation(`caseDetail.statusUpdateWarningClosed`)
      );
    }
    this.setState({
      showModalConfirm: !this.state.showModalConfirm,
      modalConfirmText: newModalBodyText,
    });
  }

  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  handleCaseStatusChange(e) {
    this.setState({
      originalStatus: this.state.currentStatus,
      currentStatus: e.value,
    });
  }

  deadlineOnChange(newDate) {
    this.setState({
      deadline: newDate,
    });
  }

  toggleModalForm() {
    this.setState({
      showModalForm: !this.state.showModalForm,
    });
  }

  submitReasonForClosing() {
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;
    let newNote = {
      notes:
        this.translation(`caseDetail.changedDeadlineTo`) +
        this.state.deadline +
        ` ${this.translation(`statistics.days`)}. ${this.translation(
          `commonText.reason`
        )}: ` +
        this.state.reasonValue,
      creator: accountDetails.login,
      operationType: "CREATED",
      noteType: NoteTypeEnum.AUTOMATIC,
      whistleblowercase:
        this.props.whistleblowerCase?.id !== null
          ? { id: this.props.whistleblowerCase?.id }
          : null,
    };

    let updatedWhistleblowercase = this.props.whistleblowerCase;
    updatedWhistleblowercase.currentDeadline = this.state.deadline;

    this.setState({ reasonvalue: null });
    axios
      .all([
        axios.advisorService.post("notes", newNote),
        axios.advisorService.put(
          "whistleblowercases",
          updatedWhistleblowercase
        ),
      ])
      .then((responseArr) => {
        this.props.updateNotes(responseArr[0].data, false);
        this.modalInfoTitle = this.translation(`caseDetail.deadlineUpdated`);
        this.modalInfoBodyText = this.translation(
          `caseDetail.deadlineUpdatedMessage`
        );
        this.onModalFormSubmit();
      })
      .catch((error) => {
        this.mainError = error.message;
        if (error.response?.data?.title) {
          this.mainError = error.response.data.title;
        }
        this.errorHandler("", error);
      });
  }

  reasonOnChange(e) {
    this.setState({ reasonValue: e.target.value });
  }

  showReason() {
    this.modalForm = (
      <React.Fragment>
        <LoadingIndicator />
        <AvForm onValidSubmit={this.submitReasonForClosing}>
          <AvGroup>
            <AvField
              name="reason"
              type="textarea"
              label={this.translation(`caseDetail.reasonForAdjustingDeadline`)}
              validate={{
                required: {
                  value: true,
                  errorMessage: this.translation(
                    `fieldWarnings.fieldIsRequired`
                  ),
                },
              }}
              value={this.state.reasonValue}
              onChange={this.reasonOnChange}
            />
          </AvGroup>
          <Button className="float-right" color="primary">
            <FontAwesomeIcon
              icon={faFileAlt}
              style={{ marginRight: "0.25rem" }}
            />
            {this.translation(`commonButtonTexts.submit`)}
          </Button>
        </AvForm>
      </React.Fragment>
    );
    this.toggleModalForm();
  }

  toggleModalMessage() {
    this.setState({
      showModalMessage: !this.state.showModalMessage,
    });
  }

  onModalFormSubmit() {
    this.toggleModalForm();
    this.toggleModalMessage();
    this.setState({ showEditDeadline: !this.state.showEditDeadline });
  }

  render() {
    const { whistleblowerCase, updateWhistleblowercaseState, updateNotes } =
      this.props;
    const {
      showEditStatus,
      showEditAdvisor,
      currentStatus,
      showEditDeadline,
      deadline,
    } = this.state;
    return (
      <Card>
        <LoadingIndicator />
        <CardHeader>
          <CaseTitle
            whistleblowerCase={whistleblowerCase}
            updateWhistleblowercaseState={updateWhistleblowercaseState}
            updateNotes={updateNotes}
          ></CaseTitle>
        </CardHeader>
        <CardBody>
          <Table cellPadding="20px">
            <tbody>
              <tr>
                <th>{this.translation(`caseDetail.caseStatus`)}:</th>
                <td>
                  {showEditStatus ? (
                    <CaseStatusDropdownInput
                      onChange={this.handleCaseStatusChange}
                      caseStatus={currentStatus}
                    />
                  ) : currentStatus ? (
                    this.translation(
                      `enumTranslation.${currentStatus.toLowerCase()}`
                    )
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {showEditStatus ? (
                    <React.Fragment>
                      <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        id="saveCaseStatus"
                        onClick={() => this.toggleModalConfirm()}
                        style={{ marginLeft: "5px" }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                        <UncontrolledTooltip
                          placement="right"
                          target="saveCaseStatus"
                        >
                          {this.translation(`commonButtonTexts.save`)}
                        </UncontrolledTooltip>
                      </Button>

                      <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        id="cancelSaveStatus"
                        style={{ marginLeft: "5px" }}
                        onClick={() => this.cancelStatusUpdate()}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        <UncontrolledTooltip
                          placement="right"
                          target="cancelSaveStatus"
                        >
                          {this.translation(`commonButtonTexts.cancel`)}
                        </UncontrolledTooltip>
                      </Button>
                    </React.Fragment>
                  ) : (
                    <>
                      <Button
                        className="float-right"
                        color="primary"
                        size="m"
                        id="editChangeStatusButton"
                        onClick={() => this.showEditStatus()}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <UncontrolledTooltip
                        placement="right"
                        target="editChangeStatusButton"
                      >
                        {this.translation(`caseDetail.changeStatus`)}
                      </UncontrolledTooltip>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                {
                  <CaseGroup
                    whistleblowerCase={whistleblowerCase}
                    updateWhistleblowercaseState={updateWhistleblowercaseState}
                    updateNotes={updateNotes}
                  ></CaseGroup>
                }
              </tr>
              <tr>
                <th>{this.translation(`caseDetail.assignedAdvisor`)}</th>
                <td>
                  {showEditAdvisor ? (
                    <AssignAdvisorToCaseForm
                      id={whistleblowerCase.id}
                      history={this.props.history}
                      onChange={(event) => this.handleAdvisorOnChange(event)}
                      value={this.state.currentAdvisors}
                      options={this.state.advisorOptions}
                    />
                  ) : this.state.currentAdvisors.length !== 0 ? (
                    this.state.currentAdvisors.map((advisor, index) => {
                      let name = advisor.label;
                      if (index !== this.state.currentAdvisors.length - 1) {
                        name += ", ";
                      }
                      return name;
                    })
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {showEditAdvisor ? (
                    <React.Fragment>
                      <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        id="saveCaseAdvisor"
                        onClick={(event) => this.handleValidSubmit(event)}
                        style={{ marginLeft: "5px" }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                        <UncontrolledTooltip
                          placement="right"
                          target="saveCaseAdvisor"
                        >
                          {this.translation(`commonButtonTexts.save`)}
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        id="cancelSaveAdvisor"
                        style={{ marginLeft: "5px" }}
                        onClick={() => this.cancelAdvisorUpdate()}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        <UncontrolledTooltip
                          placement="right"
                          target="cancelSaveAdvisor"
                        >
                          {this.translation(`commonButtonTexts.cancel`)}
                        </UncontrolledTooltip>
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      className="float-right"
                      color="primary"
                      size="m"
                      onClick={() => this.showEditAdvisor()}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <th>{this.translation(`commonText.deadline`)}</th>
                <td>
                  {showEditDeadline ? (
                    <DatePicker
                      selected={Date.parse(deadline)}
                      onChange={this.deadlineOnChange}
                      minDate={Date.now()}
                      maxDate={Date.parse(whistleblowerCase.originalDeadline)}
                      showDisabledMonthNavigation
                    ></DatePicker>
                  ) : deadline !== null ? (
                    formatDate(deadline, false)
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {showEditDeadline ? (
                    <React.Fragment>
                      <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        onClick={this.showReason}
                        id="saveDeadline"
                        style={{ marginLeft: "5px" }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                        <UncontrolledTooltip
                          placement="right"
                          target="saveDeadline"
                        >
                          {this.translation(`commonButtonTexts.save`)}
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        style={{ marginLeft: "5px" }}
                        id="cancelSaveDeadline"
                        onClick={this.showEditDeadline}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        <UncontrolledTooltip
                          placement="right"
                          target="cancelSaveDeadline"
                        >
                          {this.translation(`commonButtonTexts.cancel`)}
                        </UncontrolledTooltip>
                      </Button>
                    </React.Fragment>
                  ) : (
                    <>
                      {" "}
                      <Button
                        className="float-right"
                        color="primary"
                        size="m"
                        id="changeDeadlineButton"
                        onClick={this.showEditDeadline}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <UncontrolledTooltip
                        placement="right"
                        target="changeDeadlineButton"
                      >
                        {this.translation(`caseDetail.changeDeadline`)}
                      </UncontrolledTooltip>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
        <ModalConfirm
          isOpen={this.state.showModalConfirm}
          onClose={this.toggleModalConfirm}
          confirmEvent={this.updateCaseStatus}
          cancelEvent={this.cancelStatusUpdate}
          modalTitle={this.translation(`caseDetail.changeStatus`)}
          modalBodyText={this.state.modalConfirmText}
        ></ModalConfirm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          eventOnSubmit={this.onModalFormSubmit}
          ref={this.modalForm}
          modalTitle={this.translation(`caseDetail.reasonForAdjustingDeadline`)}
        >
          {this.modalForm}
        </ModalForm>
        <ModalInfo
          isOpen={this.state.showModalMessage}
          toggleModal={this.toggleModalMessage}
          modalTitle={this.modalInfoTitle}
          modalBodyText={this.modalInfoBodyText}
          size="lg"
        ></ModalInfo>
      </Card>
    );
  }
}

CaseDetailCard.propTypes = {
  whistleblowerCase: PropTypes.object.isRequired,
  assignedAdvisor: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateNotes: PropTypes.func.isRequired,
  updateWhistleblowercaseState: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(CaseDetailCard));
