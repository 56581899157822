import * as types from "../constants";
import InvensityLogo from "../../assets/img/Invensity_Logo.png"

const initialState = {
  image: InvensityLogo
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_LOGO:
      return {
        ...state,
        image: actions.image
      };
    default:
      return state;
  }
}
