// SessionStorage.js
import Cookies from "js-cookie";

const SessionStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    Cookies.set("jhi-authenticationtoken", tokenObj);
  }
  function _getAccessToken() {
    if (Cookies.get("jhi-authenticationtoken")) {
      return Cookies.get("jhi-authenticationtoken");
    }
    return null;
  }
  function _clearToken() {
    Cookies.remove("jhi-authenticationtoken");
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    clearToken: _clearToken,
  };
})();

export default SessionStorageService;
