import React from "react";
import Loader from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
import BrowserEnums from "../enums/BrowserEnums";
import LastLanguageEnums from "../enums/LastLanguageEnums";
import i18n from "../i18n";
import { Ascending, Default, Descending } from "./Constants";

var moment = require("moment-timezone");

/**
 * Check if an object is empty
 * @param {*} obj   -   object
 *
 * @returns true if the object is empty, false if not
 */
export function isEmpty(obj) {
  if (obj === undefined) return true;
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      if (obj.length === 0) {
        return true;
      }
    } else if (Object.keys(obj).length === 0) {
      return true;
    }
    return false;
  }
}

export function toBeAdded() {
  alert("This feature is to be added");
}

/**
 * This function formats date based on the user's browser settings
 * and would also allow to add time on the format
 * @param date
 * This parameter would only show the date in  format type 4 (Month D, YYYY with month spelled out)
 * @param showTime
 * This parameter is a combination of date and time
 * @returns date that is parsed into a moment
 */
export function formatDate(date, showTime = false) {
  // LLL = Month, day, and year with time
  // LL = Month, day and year only
  const options = showTime ? "LLL" : "LL";

  return moment.tz(date, moment.tz.guess()).format(options);
}

/*
 * This constant allows a loading indicator to be seen when
 * a Promise call is ongoing
 */
export const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader className="loader" type="ThreeDots" height="100" width="100" />
      </div>
    )
  );
};

/**
 * Removes object from array. To be removed, the object's attribute value must match the passed value parameter
 * @param {*} array Array to remove object from
 * @param {*} attribute Attribute of the object
 * @param {*} value
 */
export function removeByAttr(array, attribute, value) {
  var index = array.length;
  while (index--) {
    if (
      array[index] &&
      array[index].hasOwnProperty(attribute) &&
      arguments.length > 2 &&
      array[index][attribute] === value
    ) {
      array.splice(index, 1);
    }
  }
  return array;
}

/**
 * Returns the webpage associated with the browser name
 * @param {*} browser the name of the browser
 */
export function generateBrowserLink(browser) {
  let browserLink = "/";
  switch (browser) {
    case BrowserEnums.CHROME:
      browserLink = "https://www.google.com/chrome/";
      break;
    case BrowserEnums.FIREFOX:
      browserLink = "https://www.mozilla.org/en-US/firefox/";
      break;
    case BrowserEnums.EDGE:
      browserLink = "https://www.microsoft.com/en-us/edge";
      break;
    case BrowserEnums.SAFARI:
      browserLink = "https://www.apple.com/safari/";
      break;
    default:
      break;
  }
  return browserLink;
}

/**
 * Checks if the browser's current language matches those in the
 * language list (as dictated by LastLanguageEnums)
 * @param {*} language the current language of the browser
 */
export function getBrowserLanguage(language) {
  var languageArray = Object.values(LastLanguageEnums).map((lang) =>
    lang.toLowerCase()
  );

  if (languageArray.indexOf(language) === -1) {
    return "en";
  }

  return language;
}

/**
 * returns className for deadlines
 * @param {*} whistleblowerCase
 */
export function deadlineStyleIdentification(whistleblowerCase) {
  var deadline = moment(
    new Date(whistleblowerCase.currentDeadline).toISOString()
  );
  var currentDate = moment().startOf("day");

  let difference = deadline.diff(currentDate, "days");

  if (difference < 0) {
    return "deadline-urgent";
  } else if (difference <= 3) {
    return "deadline-near";
  }
  return null;
}

/**
 * returns className for deadlines
 * @param {*} date
 */
export function deadlineStyleIdentificationByDate(date) {
  var deadline = moment(new Date(date).toISOString());
  var currentDate = moment().startOf("day");

  let difference = deadline.diff(currentDate, "days");

  if (difference < 0) {
    return "deadline-urgent";
  } else if (difference <= 3) {
    return "deadline-near";
  }
  return null;
}

/**
 * Converts date string to a date object for sorting
 * @param {string / element} item
 */
export function sortDateReturnValue(item) {
  return item.props?.children ? new Date(item.props.children) : new Date(item);
}

/**
 * Sorts data by key
 * @param {*} preparedData - list of data to be sorted
 * @param {*} key - key value with data object
 */
export function sortDataByDate(preparedData, key, sortType) {
  preparedData.sort((a, b) => {
    if (sortType === Ascending) {
      return new Date(a[key]).getTime() > new Date(b[key]).getTime() ? 1 : -1;
    } else {
      return new Date(a[key]).getTime() < new Date(b[key]).getTime() ? 1 : -1;
    }
  });
  return preparedData;
}

export function getLastCaseStatusFromProps(caseStatuses) {
  let originalStatus = caseStatuses
    ? caseStatuses[caseStatuses?.length - 1]?.statusOfCase
    : "";

  return originalStatus;
}

/**
 * Sorts non date data by key
 * @param {*} preparedData - list of data to be sorted
 * @param {*} key - key value with data object
 */
export function sortData(preparedData, key, sortType) {
  preparedData.sort((a, b) => {
    if (sortType === Ascending) {
      return a[key] > b[key] ? 1 : -1;
    } else {
      return a[key] < b[key] ? 1 : -1;
    }
  });
  return preparedData;
}

/**
 * This function gets the date format based on the user's browser language setting and the formatOption being set.
 * @param formatOption - Optional parameter. Import the momentLongDateFormat from the src/helpers/enum. To select further dateFormat.
 *  * Here are the list of key(string): value(string) pair to give an idea on the expected return based on the key used : 
        {"LT": "h:mm A",
        "LTS": "h:mm:ss A",
        "L": "MM/DD/YYYY",
        "LL": "MMMM Do YYYY",
        "LLL": "MMMM Do YYYY LT",
        "LLLL": "dddd, MMMM Do YYYY LT"}
 * @param showTime - Optional boolean value. If true, allow the user to select time. Otherwise if false.
 * @returns string dateFormat to be used to format dates.
 */
export function getDateFormat(formatOption = "L", showTime = false) {
  // Set prefferedLanguage "en" as default
  const languageSelected = i18n.language;

  if (showTime) {
    // Get time based on the input date.
    const retrievedTime = moment
      .localeData(languageSelected)
      .longDateFormat("LT");
    const retrievedDate = moment
      .localeData(languageSelected)
      .longDateFormat(formatOption);
    return `${retrievedDate} ${retrievedTime}`;
  } else {
    return moment.localeData(languageSelected).longDateFormat(formatOption);
  }
}

// Accepts array of objects and object property names
// Sorts array alphabetically (A-Z) by property value
export function sortByPropValue(objectArray, property, subProperty = null) {
  if (Array.isArray(objectArray) && objectArray.length > 0) {
    if (subProperty === null) {
      var result = objectArray.sort((object1, object2) =>
        object1[property] !== undefined
          ? object1[property]?.toString()?.toUpperCase() >
            object2[property]?.toString()?.toUpperCase()
            ? 1
            : object2[property]?.toString()?.toUpperCase() >
              object1[property]?.toString()?.toUpperCase()
            ? -1
            : 0
          : 0
      );
      return result;
    } else {
      var results = objectArray.sort((object1, object2) =>
        object1[property] !== undefined
          ? object1[property][subProperty]?.toString()?.toUpperCase() >
            object2[property][subProperty]?.toString()?.toUpperCase()
            ? 1
            : object2[property][subProperty]?.toString()?.toUpperCase() >
              object1[property][subProperty]?.toString()?.toUpperCase()
            ? -1
            : 0
          : 0
      );
      return results;
    }
  } else {
    return [];
  }
}

/**
 * Returns the next sort type
 * @param {*} currentSortType the current sortType
 * @param {*} nextSortBy the next field to be sorted
 * @param {*} prevSortBy previous field that was sorted
 * @returns
 */
export function getNextSortType(currentSortType, nextSortBy, prevSortBy) {
  if (nextSortBy !== prevSortBy) {
    return Ascending;
  } else {
    switch (currentSortType) {
      case Ascending:
        return Descending;
      case Descending:
        return Default;
      default:
        return Ascending;
    }
  }
}

/**
 * Returns latest status of a message
 * @param {*} message message to check the latest status of
 * @returns string containing latest status
 */
export function getMessageLatestStatus(message) {
  // sort to ensure statuses are in ascending order by id
  let statuses = message.messageStatuses.sort((a, b) => a.id - b.id);
  return statuses[message.messageStatuses.length - 1]?.statusOfMessage;
}
