import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import React from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { Button } from "reactstrap";
import axios from "../../../axios/Axios";
import { isEmpty, removeByAttr } from "../../../helpers/GenericHelper";
import ModalError from "../modal/ModalError";
import NoteTypeEnum from "../../../enums/NoteTypeEnum"
/**
 * Form that contains the input fields to allow user to
 * link a case
 */
class AddLinkedCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caseOptions: [],
      description: "",
      userAccount: null,
      caseToAdd: {},
      showModalError: false,
    };

    this.translation = this.props.t;

    this.firstIndex = 0;
    this.itemsPerPage = 20;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.handleCaseOnChange = this.handleCaseOnChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.linkCase = this.linkCase.bind(this);
    this.addCaseNote = this.addCaseNote.bind(this);
    this.errorHandler = this.errorHandler.bind(this);

    this.toggleModalError = this.toggleModalError.bind(this);
  }

  async componentDidMount() {
    // Get pages count to be able to get data that are possibly in other pages
    let axiosCount = await axios.advisorService
      .get(
        `whistleblowercases/count?clientId.equals=${this.props.parentCase?.client?.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Case Dropdown Error", error);
      });
    let pagesCount = Math.ceil(axiosCount / this.itemsPerPage);
    let axiosCallsArray = [];
    let finalDataArray = [];
    let arrayAfterRemoval = [];

    // Push axios commands to be executed to an array
    for (var index = 0; index < pagesCount; index++) {
      axiosCallsArray.push(
        axios.advisorService.get(
          `whistleblowercases?page=${index}&clientId.equals=${this.props.parentCase?.client?.id}`
        )
      );
    }

    // Execute axiosCallsArray
    Promise.all(axiosCallsArray)
      .then((promiseResponse) => {
        for (var index2 = 0; index2 < promiseResponse.length; index2++) {
          promiseResponse[index2].data.forEach((data) => {
            // Do not include current case user is in to the options dropdown
            if (data.id !== this.props.parentCase?.id) {
              finalDataArray.push(data);
            }
          });
        }

        if (isEmpty(this.props.linkedCases)) {
          //If no linked cases yet, cases dropdown will not be affected
          arrayAfterRemoval = finalDataArray;
        } else {
          // Remove already linked cases from finalDataArray
          this.props.linkedCases.forEach((linkedCases) => {
            arrayAfterRemoval = removeByAttr(
              finalDataArray,
              "id",
              linkedCases?.linkedCase?.id
            );
          });
        }

        // Generate options for dropdown
        let options = [];
        options = arrayAfterRemoval.map((data) => {
          return {
            value: data.id,
            label: `[${data.id}] ${data.title}`,
          };
        });

        this.setState({
          caseOptions: options,
        });
      })
      .catch((error) => {
        this.errorHandler("Case Dropdown Error", error);
      });


    this.keepAlive = setInterval(() => {
      axios.keepAlive.get('keep-alive')
        .then(() => { })
        .catch(() => { })
    }, 300000);
  }

  componentWillUnmount() {
    // Disable keep alive upon component unmount
    clearInterval(this.keepAlive);
    this.keepAlive = 0;
  }

  // Links case to the parent case
  linkCase() {
    if (this.state.caseToAdd === null) {
      if (this.props.externalOnSubmitEvent) {
        this.props.externalOnSubmitEvent(null);
      }
    } else {

      let newLinkedCase = {
        linkedCase: { id: this.state.caseToAdd?.id},
        description: this.state.description,
        parentCase: { id: this.props.parentCase?.id },
      };

      axios.advisorService
        .post(`links`, newLinkedCase)
        .then((response) => {
          this.addCaseNote(newLinkedCase);
          if (this.props.externalOnSubmitEvent) {
            this.props.externalOnSubmitEvent(response.data);
          }
        })
        .catch((error) => {
          this.errorHandler("Link Save Error", error);
        });
    }
  }

  async addCaseNote(newLinkedCase) {
    let userAccount = await axios.advisorService
      .get(`account`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });

    let newNote = {
      notes: `${this.state.caseToAdd.title}` + this.translation(`caseDetail.linkedCaseTo`) + `${this.props.parentCase?.title}` 
      + ". " + this.translation(`caseDetail.reasonForLinkingCase`) + `${newLinkedCase.description}`,
      creator: `${userAccount.lastName}, ${userAccount.firstName}`,
      operationType: "CREATED",
      noteType: NoteTypeEnum.AUTOMATIC,
      whistleblowercase:
        this.props.parentCase?.id !== null
          ? { id: this.props.parentCase?.id }
          : null,
    };
    axios.advisorService
      .post(`notes`, newNote)
      .then(()=>{
        window.location.reload();
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Retrieve Advisor Data":
        this.mainError = this.translation(
          `errorMessages.advisorDetailsRetrievalError`
        );
        break;
      case "Case Dropdown Error":
        this.mainError = this.translation(
          `errorMessages.caseDropdownOptionError`
        );
        break;
      case "Link Save Error":
        this.mainError = this.translation(`errorMessages.linkSaveError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Handles the change to the description field
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  // Handles change to the case dropdown box
  handleCaseOnChange(event) {
    this.setState({ caseToAdd: {id:event.value, title:event.label}});
  }

  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  render() {
    return (
      <div>
        <AvForm onValidSubmit={this.linkCase}>
          {this.translation(`linkCase.caseToLink`)}
          <Select
            autoFocus={true}
            options={this.state.caseOptions}
            onChange={this.handleCaseOnChange}
            placeholder={this.translation("commonText.select")}
            noOptionsMessage={() => this.translation("commonText.noOptions")}
          />
          <br />
          <AvGroup>
            <AvField
              name="linkDescription"
              label={this.translation(`linkCase.description`)}
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: this.translation(
                    `fieldWarnings.fieldIsRequired`
                  ),
                },
              }}
              onChange={this.handleDescriptionChange}
            />
            <br />
            <div className="float-right">
              <Button color="primary">
                <FontAwesomeIcon icon={faLink} />{" "}
                {this.translation(`linkCase.linkCase`)}
              </Button>
            </div>
          </AvGroup>
        </AvForm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </div>
    );
  }
}

export default withTranslation()(AddLinkedCase);
