import React from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import CaseStatusEnum from "../enums/CaseStatusEnum";
/*
 * Dropdown containing case statuses
 */
class CaseStatusDropdownInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateData: [],
    };

    this.translation = this.props.t;
    this.caseStatuses = Object.values(CaseStatusEnum);
  }

  componentDidMount() {
    // Sets the options in the dropdown depending on the initial case status value
    switch (this.props.caseStatus) {
      case CaseStatusEnum.NEW:
        this.caseStatuses = [CaseStatusEnum.NEW, CaseStatusEnum.ACCEPTED];
        break;
      case CaseStatusEnum.ACCEPTED:
        this.caseStatuses = [CaseStatusEnum.ACCEPTED, CaseStatusEnum.INPROGRESS];
        break;
      case CaseStatusEnum.INPROGRESS:
        this.caseStatuses = [CaseStatusEnum.INPROGRESS, CaseStatusEnum.CLOSED];
        break;
      case CaseStatusEnum.CLOSED:
        this.caseStatuses = [CaseStatusEnum.CLOSED, CaseStatusEnum.NEW];
        break;
      default:
        break;
    }
    var options = [];
    options = this.caseStatuses.map((states) => ({
      value: states,
      label: this.translation(`enumTranslation.${states.toLowerCase()}`),
    }));
    this.setState({ stateData: options });
  }

  render() {
    return (
      <Select
        value={this.state.stateData.find(
          (dataEntry) => dataEntry.value === this.props.caseStatus
        )}
        options={this.state.stateData}
        isMulti={false}
        {...this.props}
      />
    );
  }
}

export default withTranslation()(CaseStatusDropdownInput);
