import React from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { FormGroup, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DATERANGE = "dateRange";
const MONTH = "month";
const MONTHYEAR = "monthYear";
const YEAR = "year";
const LASTDAYS = "lastDays";

class FilterStatisticByDateDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
    };
    this.translation = this.props.t;

    this.options = [
      {
        value: DATERANGE,
        label: this.translation("statistics.filterDateRange"),
      },
      { value: MONTH, label: this.translation("statistics.filterMonth") },
      {
        value: MONTHYEAR,
        label: this.translation("statistics.filterMonthYear"),
      },
      { value: YEAR, label: this.translation("statistics.filterYear") },
      { value: LASTDAYS, label: this.translation("statistics.filterLastDays") },
    ];

    this.changeFilter = this.changeFilter.bind(this);
    this.onChangeDays = this.onChangeDays.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onChangeMonthYear = this.onChangeMonthYear.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
    this.createFilterComponent = this.createFilterComponent.bind(this);
  }

  changeFilter(event) {
    this.setState({ filter: event });
  }

  onChangeMonth(selectedMonth) {
    this.setState({ month: selectedMonth });
    this.props.setMonth(selectedMonth);
  }

  onChangeDays(days) {
    this.setState({ days: days.target.value });
    this.props.setDays(days.target.value);
  }

  onChangeStartDate(date) {
    this.setState({ startDate: date });
    this.props.setStartDate(date);
  }

  onChangeEndDate(date) {
    this.setState({ endDate: date });
    this.props.setEndDate(date);
  }

  onChangeMonthYear(date) {
    this.setState({ monthYear: date });
    this.props.setMonthYear(date);
  }

  onChangeYear(date) {
    this.setState({ year: date });
    this.props.setYear(date);
  }

  createFilterComponent() {
    let { startDate, endDate, days, month, year, monthYear } = this.state;
    switch (this.state.filter?.value) {
      case DATERANGE:
        return (
          <>
            <FormGroup>
              <DatePicker
                placeholderText={this.translation("statistics.filterDateFrom")}
                selected={startDate}
                onChange={(date) => this.onChangeStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </FormGroup>
            <FormGroup>
              <DatePicker
                placeholderText={this.translation("statistics.filterDateTo")}
                selected={endDate}
                onChange={(date) => this.onChangeEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </FormGroup>
          </>
        );
      case MONTH:
        return (
          <FormGroup>
            <DatePicker
              selected={month}
              onChange={(selectedMonth) => this.onChangeMonth(selectedMonth)}
              dateFormat="MMMM"
              showMonthYearPicker
              showTwoColumnMonthYearPicker
            />
          </FormGroup>
        );
      case MONTHYEAR:
        return (
          <FormGroup>
            <DatePicker
              selected={monthYear}
              onChange={(date) => this.onChangeMonthYear(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showTwoColumnMonthYearPicker
            />
          </FormGroup>
        );
      case YEAR:
        return (
          <DatePicker
            selected={year}
            onChange={(date) => this.onChangeYear(date)}
            showYearPicker
            dateFormat="yyyy"
          />
        );
      case LASTDAYS:
        return (
          <Input
            value={days}
            onChange={this.onChangeDays}
            type="number"
            name="number"
            min="1"
            placeholder="Last X Days"
          />
        );
      default:
        return null;
    }
  }

  render() {
    let filterComponent = this.createFilterComponent();
    return (
      <>
        <FormGroup>
          <span>{this.translation(`statistics.filter`)}</span>
          <Select
            isClearable={true}
            options={this.options}
            onChange={this.changeFilter}
            value={this.state.filter}
            placeholder={this.translation(`DynamicTable.select`)}
          />
        </FormGroup>

        {filterComponent || <FormGroup>{filterComponent}</FormGroup>}
      </>
    );
  }
}

export default withTranslation()(FilterStatisticByDateDropdown);
