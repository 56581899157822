import classNames from "classnames";
import Cookies from "js-cookie";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Collapse, Form, Input, Nav, Navbar } from "reactstrap";
import axios from "../axios/Axios";
import RoleEnums from "../enums/RoleEnums";
import LastLanguageEnums from "../enums/LastLanguageEnums";
import ModalError from "../pages/components/modal/ModalError";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { store } from "../redux/store/index";
import { language } from "../helpers/Constants";

const NavbarToggle = connect((store) => ({
  app: store.app,
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarSearch = connect((store) => ({
  sidebar: store.sidebar,
}))(({ sidebar }) => {
  return (
    <Form inline>
      <Input
        type="text"
        placeholder=""
        aria-label="Search"
        className={classNames(
          "form-control-lite",
          !sidebar.isOnRight || "text-right"
        )}
      />
    </Form>
  );
});

class NavbarClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalError: false,
    };

    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.changeLastLanguage = this.changeLastLanguage.bind(this);
    this.changeCookieLanguage = this.changeCookieLanguage.bind(this);
    this.isLanguageSelected = this.isLanguageSelected.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  isLanguageSelected(languageString) {
    var currentLanguage = Cookies.get(language);
    if (currentLanguage === languageString) {
      return true;
    }
    return false;
  }

  // Changes the language based on the icon clicked
  changeLastLanguage(language) {
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;

    if (accountDetails.authorities.includes(RoleEnums.USER)) {
      axios.account.get(`account`).then((accountResponse) => {
        let user = accountResponse.data;
        user.lastLanguage = language.toUpperCase();
        user.langKey = language;
        axios.account.post(`account`, user).then(() => {
          this.changeCookieLanguage(language);
        });
      });
    } else if (accountDetails.authorities.includes(RoleEnums.ADMIN)) {
      let adminDetails = accountDetails;
      adminDetails.lastLanguage = language.toUpperCase();
      axios.account
        .put(`users`, adminDetails)
        .then(() => {
          this.changeCookieLanguage(language);
        })
        .catch((error) => {
          this.errorHandler("Change Language", error);
        });
    }
  }

  // Changes the language in the i18n file
  changeCookieLanguage(newLanguage) {
    this.props.i18n.changeLanguage(newLanguage);
    Cookies.set(language, newLanguage);
    window.location.reload();
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Change Language":
        this.mainError = this.translation(`errorMessages.languageChangeError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    var NavbarLanguage = (
      <Collapse navbar>
        <Nav className={"ml-auto"} navbar style={{ padding: "5px" }}>
          {/* Creates the buttons based from the language list in LastLanguageEnums */}
          {Object.values(LastLanguageEnums).map((lang, i) => (
            <Button
              key={i}
              color="primary"
              style={{ margin: "2px" }}
              onClick={this.changeLastLanguage.bind(this, lang.toLowerCase())}
              disabled={this.isLanguageSelected(lang.toLowerCase())}
            >
              {lang}
            </Button>
          ))}
        </Nav>
      </Collapse>
    );
    return (
      <Navbar expand className="navbar-theme">
        <React.Fragment>
          <NavbarToggle />
          <NavbarSearch />
          {NavbarLanguage}
          <ModalError
            isOpen={this.state.showModalError}
            onClose={this.toggleModalError}
            mainError={this.mainError}
            errorReason={this.errorReason}
            errorResponse={this.errorResponse}
            modalTitle="Error"
          ></ModalError>
        </React.Fragment>
      </Navbar>
    );
  }
}

export default connect((store) => ({
  sidebar: store.sidebar,
}))(withTranslation()(NavbarClass));
