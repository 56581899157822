import React from "react";
import { isEmpty } from "../../../../helpers/GenericHelper";
import i18n from "../../../../i18n";
import inputGenerator from "./inputGenerator";
import { Button } from "reactstrap";

/**
 * Generates the question and the input fields with answer
 *
 * @param {*} category  - category object from the json file
 * @param {*} answers   - list of answers
 * @param {*} events  - events for the inputs of the question
 * @param {*} isEdit  - boolean containing if it is on edit mode
 * @param {*} addDynamicField - event for adding dynamic field
 * @param {*} removeDynamicQuestion   - event for removing the dynamic field
 */
export default function generateQuestion(
  category,
  answers,
  events,
  isEdit,
  addDynamicField,
  removeDynamicQuestion
) {
  let input = [];
  let answer = "";
  if (!isEmpty(category.fields)) {
    category?.fields?.forEach((field, i) => {
      answer = getAnswer(answers, field.questionId);
      if (field.design === 1) {
        input.push(
          <tr
            key={`field-${i}`}
            style={{ width: "100%" }}
            className="border-top"
          >
            <td
              style={{
                width: "20%",
                display: "flex",
                border: "none",
                justifyContent: "space-between",
              }}
            >
              {inputGenerator(field, answer, events, isEdit)}
            </td>
            <td style={{ width: "10%" }}>
              {field.question ? i18n.t(`questionnaire.${field.question}`) : " "}
            </td>
          </tr>
        );
      } else if (field.design === 2) {
        input.push(
          <tr
            key={`field-${i}`}
            style={{ width: "100%" }}
            className="border-top"
          >
            <td
              style={{
                width: "110%",
                height: "150px",
                display: "flex",
                border: "none",
                justifyContent: "space-between",
              }}
            >
              {inputGenerator(field, answer, events, isEdit)}
            </td>
            <td style={{ width: "10%" }}>
              {field.question ? i18n.t(`questionnaire.${field.question}`) : " "}
            </td>
          </tr>
        );
      } else if (field.design === 3) {
        input.push(
          <tr
            key={`field-${i}`}
            style={{ width: "100%" }}
            className="border-top"
          >
            <td style={{ width: "20%" }}>
              {field.question ? i18n.t(`questionnaire.${field.question}`) : " "}
            </td>
            <td
              style={{
                width: "20%",
                display: "flex",
                border: "none",
                justifyContent: "space-between",
              }}
            >
              {inputGenerator(field, answer, events, isEdit)}{" "}
            </td>
          </tr>
        );
      } else if (field.design === 4) {
        input.push(
          <tr key={`field-${i}`} style={{ width: "100%" }}>
            <td style={{ width: "20%", borderTop: "none" }}>
              {field.question ? i18n.t(`questionnaire.${field.question}`) : " "}
            </td>
            <td style={{ width: "80%", borderTop: "none" }}>
              {inputGenerator(field, answer, events, isEdit)}
            </td>
          </tr>
        );
      } else if (field.design === 5) {
        input.push(
          <tr
            key={`field-${i}`}
            style={{ width: "100%" }}
            className="border-top"
          >
            <td
              style={{
                width: "50%",
                display: "flex",
                border: "none",
                justifyContent: "space-between",
              }}
            >
              {inputGenerator(field, answer, events, isEdit)}
            </td>
            <td style={{ width: "10%" }}>
              {field.question ? i18n.t(`questionnaire.${field.question}`) : " "}
            </td>
          </tr>
        );
      } else if (field.design === 6) {
        input.push(
          <tr
            key={`field-${i}`}
            style={{ width: "100%" }}
            className="border-top"
          >
            <td style={{ width: "20%" }}>
              {field.question ? i18n.t(`questionnaire.${field.question}`) : " "}
            </td>
            <td
              style={{
                width: "26%",
                display: "flex",
                border: "none",
                justifyContent: "space-between",
              }}
            >
              {inputGenerator(field, answer, events, isEdit)}{" "}
            </td>
          </tr>
        );
      } else if (field.design === 7) {
        if (field.questionId === 89) {
          input.push(
            <tr
              key={`field-${i}`}
              style={{ width: "100%" }}
              className="border-top"
            >
              <td style={{ width: "10%" }}>
                {field.question
                  ? i18n.t(`questionnaire.${field.question}`)
                  : " "}
              </td>
              <td
                style={{
                  width: "50%",
                  border: "none",
                  justifyContent: "space-between",
                }}
              >
                {inputGenerator(field, answer, events, isEdit)}{" "}
              </td>
            </tr>
          );
        } else {
          input.push(
            <tr
              key={`field-${i}`}
              style={{ width: "100%" }}
              className="border-top"
            >
              <td style={{ width: "10%" }}>
                {field.question
                  ? i18n.t(`questionnaire.${field.question}`)
                  : " "}
              </td>
              <td
                style={{
                  width: "50%",
                  display: "flex",
                  border: "none",
                  justifyContent: "space-between",
                }}
              >
                {inputGenerator(field, answer, events, isEdit)}{" "}
              </td>
            </tr>
          );
        }
      } else {
        input.push(
          <tr
            key={`field-${i}`}
            style={{ width: "100%" }}
            className="border-top"
          >
            <>
              <td style={{ width: "20%" }}>
                {field.question
                  ? i18n.t(`questionnaire.${field.question}`)
                  : " "}
              </td>
              <td
                style={{
                  width: "20%",
                  display: "flex",
                  border: "none",
                  justifyContent: "space-between",
                }}
              >
                {inputGenerator(field, answer, events, isEdit)}
              </td>
            </>
          </tr>
        );
      }

      let extraFields = category.extraFields?.filter(
        (extraField) =>
          extraField.requiredAnswerForExtraField &&
          extraField.dependentOn === field.questionId
      );
      if (extraFields && extraFields.length > 0) {
        input = input.concat(
          generateExtraField(
            extraFields,
            isEdit,
            answers,
            events,
            addDynamicField,
            removeDynamicQuestion
          )
        );
      }
    });
  }
  return <>{input}</>;
}

/**
 * Use for generating extra field that is dependent on the main questions
 *
 * @param {*} extraFields - array of field object
 * @param {*} isEdit - boolean if it is editable
 * @param {*} answers - array of answers
 * @param {*} events - array of different input event
 * @param {*} addDynamicField - event for adding dynamic field
 * @param {*} removeDynamicQuestion - event for removing dynamic field
 */
function generateExtraField(
  extraFields,
  isEdit,
  answers,
  events,
  addDynamicField,
  removeDynamicQuestion
) {
  let input = [];
  for (const extraField of extraFields) {
    let neededAnswer = getAnswer(answers, extraField.dependentOn);

    // check if the answer of the dependent question is the same as the requiredAnswerForExtraField
    if (
      neededAnswer !== undefined &&
      neededAnswer.toString() ===
        extraField.requiredAnswerForExtraField.toString()
    ) {
      if (extraField.multiplePossible) {
        let dynamicFields = generateDynamicField(
          extraField,
          answers,
          events,
          isEdit,
          addDynamicField,
          removeDynamicQuestion
        );
        input.push(dynamicFields);
      } else {
        for (const extraQuestion of extraField.fields) {
          let answer = getAnswer(
            answers,
            extraQuestion.questionId,
            extraField.multiplePossible ?? false
          );
          input.push(
            <tr key={extraQuestion.questionId} style={{ width: "100%" }}>
              <td style={{ width: "20%" }}>
                {i18n.t(`questionnaire.${extraQuestion.question}`)}
              </td>
              <td style={{ width: "80%" }}>
                  {inputGenerator(extraQuestion, answer, events, isEdit)}
              </td>
            </tr>
          );
        }
      }
    }
  }
  return input;
}

/**
 * Generate Dynamic Field
 */
function generateDynamicField(
  extrafield,
  answers,
  events,
  isEdit,
  addDynamicField,
  removeDynamicQuestion
) {
  let input = [];
  let numberofDynamicField = 1;
  let answersList;

  // check number of dynamic field to generate based on answers
  for (const field of extrafield.fields) {
    answersList = getAnswer(answers, field.questionId, true);
    // check order of answers which will be used on determining the number of dynamic field
    numberofDynamicField = Math.max(
      ...answersList.map((answer) => answer.order),
      extrafield.defaultNumberOfField
    );
  }

  // printing of dynamic field
  for (let index = 1; index <= numberofDynamicField; index++) {
    if (numberofDynamicField > extrafield.defaultNumberOfField) {
      input.push(
        <tr key={`${index}-remove`}>
          <td style={{ border: "none" }}></td>
          <td style={{ border: "none" }}>
            <Button
              color="danger"
              onClick={() =>
                removeDynamicQuestion(index, extrafield.dependentOn)
              }
              disabled={!isEdit}
            >
              {i18n.t(`commonButtonTexts.delete`)}
            </Button>
          </td>
        </tr>
      );
    }
    for (const field of extrafield.fields) {
      let answer =
        getAnswer(answers, field.questionId, true).find(
          (answerObject) => answerObject.order === index
        )?.answer ?? null;
      input.push(
        <tr key={field.questionId + "-" + index} style={{ width: "100%" }}>
          <td style={{ width: "70%", border: "0" }}>
            {i18n.t(`questionnaire.${field.question}`)}
          </td>
          <td style={{ width: "30%", border: "0" }}>
            {inputGenerator(field, answer, events, isEdit, index)}
          </td>
        </tr>
      );
    }
    // add horizontal line at the end of every dynamic field
    if (index !== numberofDynamicField) {
      input.push(
        <tr>
          <td colSpan="100%"></td>
        </tr>
      );
    }
  }

  // button for adding dynamic field
  input.push(
    <tr style={{ width: "100%" }}>
      <td style={{ width: "70%" }}></td>
      <td style={{ width: "30%" }}>
        <Button
          color="primary"
          disabled={!isEdit}
          onClick={() => addDynamicField(extrafield, numberofDynamicField + 1)}
        >
          {i18n.t(`questionnaire.${extrafield.buttonLabel}`)}
        </Button>
      </td>
    </tr>
  );

  return input;
}

/**
 * Gets specific answer from the list of answers
 * @param {*} answers - list of answers to be searched from
 * @param {*} id -  id of question to be searched
 * @param {*} multiplePossible - boolean to return multiple answer
 */
function getAnswer(answers, id, multiplePossible = false) {
  let filteredAnswer = null;
  if (multiplePossible) {
    filteredAnswer = answers.filter((answer) => answer.questionId === id);
    return filteredAnswer;
  } else {
    filteredAnswer = answers.find((answer) => answer.questionId === id);
    return filteredAnswer?.answer;
  }
}
