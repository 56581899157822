import React from "react";
import { MoreVertical } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import TableFilter from "react-table-filter";
import { } from "react-table-filter/lib/styles.css";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import { Ascending, recordsPerPage } from "../helpers/Constants";
import { LoadingIndicator } from "../helpers/GenericHelper";
import i18n from "../i18n";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "reactstrap/lib/Button";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import { getDateFormat, sortByPropValue } from "../helpers/GenericHelper";

/**
 * this Table will Take any Data
 * provide the Data and the Props as descriped in the State
 */

class DynamicTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* Tabledata
      data: [
        {
          key: value
          ...
        },
        ...
      ] 
      
      */
      data: props.data,
      // The internal Data to Display filtered Table Data
      dataLatestState: props.data,
      // Determins the Title of the Table if set
      tableTitle: props.tableTitle,
      /*Enter the Columns in the following way with there Settings
        [
            {
                //You can Ignore everything that isnt Required if you dont want to use it
                type:                   /Type: String               /Default:-       /Required: true    /Type of Column data/dropdown
                header:                 /Type: String               /Default:accessor/Required: false   /Header Title
                accessor:               /Type: String               /Default:-       /Required: true    /Key to access the Data/only Required if not type Dropdown
                show: true,             /Type; String               /Default:"false" /Required: false   /Determines if the Selected Column will be shown in the Table 
                filterkey:              /Type: String               /Default:-       /Required: false   /Key by which the Column should be filtered(Key as present in single Item)
                itemDisplayValueFunc:   /Type: function(itemValue)  /Default:-       /Required: false   /Optional Function that returns the Value that is displayed in the filter list for each item(Default is the item value - Item[key])
                itemSortValueFunc:      /Type: function(itemValue)  /Default:-       /Required: false   /Optional Function that returns the Value that is used while sorting (Default is the item value - Item[key])
                alignleft:              /Type: String               /Default:"false" /Required: false   /Decides while side filter list should be aligned w.r.t Column. Allowed "true"/"false"
                casesensitive:          /Type: String               /Default:"false" /Required: false   /Case Sensitivity during sort. Allowed "true"/"false"
                showsearch:             /Type: String               /Default:"false" /Required: false   /Display/Hide the search input. Allowed "true"/"false"
                link:                   /Type: String               /Default:-       /Required: false   /The base Link String
                linkAccessor:           /Type: Sting                /Default:-       /Required: false   /The link additon this will be addet behind the link if exsisting
                addionalInfoAccessor:   /Type: Sting                /Default:-       /Required: false   /Key to access an additional info in one column of the data. Data must be an array
                tableSize               /Type: Sting                /Default:-       /Required: false   /Changes the size of the table
                paginationSize          /Type: Sting                /Default:"lg"    /Required: false   /Changes the size of the Pagination component
                dontShowDataPerPageOption /Type:boolean             /Default:-       /Required: false   /If exist in props, the table will not show the option for changing records per page
                linkFunc                /Type: function             /Default:-       /Required: false   /Optional function that will be called on clicking a Link
                linkFuncParameter       /Type: String               /Default:-       /Required: false   /The accessor for the parameter linkFunc 
                columnWidth             /Type: String               /Default:-       /Requreid: false   /The size of the column
                tableId                      /Type: String               /Default:-       /Required: false   /Id of the table
              },
            ...
        ]
      */
      columns: props.columns,
      deleteAction: props.deleteAction,
      addAction: props.addAction,
      viewDetailsAction: props.viewDetailsAction,
      updateAction: props.updateAction,
      caseNotesAction: props.caseNotesAction,
      messageNotesAction: props.messageNotesAction,
      moveAction: props.moveAction,
      dropdown: props.dropdown,
      currentPage: 0,
      recordsPerPage: props.recordPerPage
        ? props.recordPerPage
        : recordsPerPage[0],
      pagesCount: 0,
      sortType: props.sortType ?? null,
      sortBy: props.sortBy ?? null,
      removeMenuPortalTarget: props.removeMenuPortalTarget,
      fetchData: props.fetchData,
      hasMoreData: props.hasMoreData,
      infiniteScroll: props.infiniteScroll ?? false,
      showDeadline: props.showDeadline,
      isAdmin: props.isAdmin,
      tableId: props.tableId,
      ...props,
    };
    this.filterUpdated = this.filterUpdated.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeRecordsPerPage = this.changeRecordsPerPage.bind(this);
    this.paginateTable = this.paginateTable.bind(this);
  }
  componentDidMount() {
    if (!this.state.infiniteScroll) {
      this.paginateTable(this.state.data, this.state.recordsPerPage);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          data: this.props.data,
          dataLatestState: this.props.data,
          fetchData: this.props.fetchData,
          hasMoreData: this.props.hasMoreData,
          sortType: this.props.sortType ?? null,
          sortBy: this.props.sortBy ?? null,
        },
        () => {
          if (!this.state.infiniteScroll) {
            this.paginateTable(this.state.data, this.state.recordsPerPage);
          }
        }
      );
    }
  }

  paginateTable(tableData, recordPerPage) {
    let alltableData = [];
    // Check if the incoming Data is already Paginated with more then one Page
    if (Array.isArray(tableData[0])) {
      for (let i = 0; i < tableData.length; i++) {
        alltableData = [...alltableData, ...tableData[i]];
      }
    } else {
      alltableData = tableData;
    }
    let pagesCount = Math.ceil(alltableData.length / recordPerPage?.value);
    let paginatedTableData = [];

    for (let page = 0; page < pagesCount; page++) {
      let data = alltableData.splice(0, recordPerPage?.value);
      paginatedTableData.push(data);
    }
    this.setState(
      {
        dataLatestState: paginatedTableData,
        pagesCount: pagesCount,
        currentPage: pagesCount === 1 ? 0 : this.state.currentPage,
      },
      () => {
        // set current page to last page if it exceeds the total page
        if (this.state.currentPage >= pagesCount && pagesCount !== 0) {
          this.setState({ currentPage: pagesCount - 1 });
        }
      }
    );
  }

  handlePageChange(event, index) {
    event.preventDefault();
    this.setState({ currentPage: index });
    window.scrollTo(0, 200);
  }

  changeRecordsPerPage = (recordsPerPage) => {
    this.setState(
      {
        recordsPerPage: recordsPerPage,
        currentPage: 0,
      },
      () => {
        if (!this.state.infiniteScroll) {
          this.paginateTable(this.state.data, this.state.recordsPerPage);
        }
      }
    );
  };

  filterUpdated = (newData, filterConfiguration) => {
    this.setState(
      {
        dataLatestState: newData,
      },
      () => {
        if (!this.state.infiniteScroll) {
          this.paginateTable(
            this.state.dataLatestState,
            this.state.recordsPerPage
          );
        }
      }
    );
  };

  fetchData = () => {
    if (this.props.fetchData) {
      this.props.fetchData();
    }
  };

  render() {
    const tableContent = (
      <>
        <Table
          striped
          className="my-0"
          size={this.props.tableSize ? this.props.tableSize : undefined}
        >
          <thead>
            <RenderColumnHeader state={this.state} func={this.filterUpdated} />
          </thead>
          <tbody>
            <RenderTableData state={this.state} />
          </tbody>
        </Table>
      </>
    );

    return (
      <React.Fragment>
        <LoadingIndicator />
        <br />
        {this.state.infiniteScroll ? (
          <InfiniteScroll
            dataLength={this.state.data.length}
            next={this.fetchData}
            hasMore={this.state.hasMoreData}
            loader={
              <p style={{ textAlign: "center" }}>
                <br />
                <br />
                <b>{i18n.t("DynamicTable.loading")}</b>
              </p>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <br />
                <br />
                <b>{i18n.t("DynamicTable.endOfList")}</b>
              </p>
            }
          >
            {tableContent}
          </InfiniteScroll>
        ) : (
          tableContent
        )}
        <br />
        {this.state.infiniteScroll ? null : (
          <Row>
            <Col>
              <RenderPaginationBody
                state={this.state}
                handlePageChange={this.handlePageChange}
                size={
                  this.props.paginationSize ? this.props.paginationSize : null
                }
              />
            </Col>
            {this.props.dontShowDataPerPageOption ? null : (
              <Col>
                <RenderPaginationEntries
                  state={this.state}
                  changeRecordsPerPage={this.changeRecordsPerPage}
                />
              </Col>
            )}
          </Row>
        )}
      </React.Fragment>
    );
  }
}

function RenderPaginationEntries(props) {
  return (
    <React.Fragment>
      <div className="float-right" style={{ width: "250px" }}>
        <Col lg="12">
          <div class="row">
            <div
              class="col-sm-7"
              style={{ transform: "translate(40px, 8px)", fontSize: ".75rem" }}
            >
              <Label>{i18n.t(`commonText.entriesPerPage`)}</Label>
            </div>
            <div class="col-sm-5">
              <Select
                options={recordsPerPage}
                onChange={props.changeRecordsPerPage}
                value={props.state.recordsPerPage}
                menuPortalTarget={
                  props.state.removeMenuPortalTarget
                    ? undefined
                    : document.querySelector("body")
                }
              />
            </div>
          </div>
        </Col>
      </div>
    </React.Fragment>
  );
}

function RenderPaginationBody(props) {
  return (
    <React.Fragment>
      <Pagination
        size={props.size ? props.size : "lg"}
        aria-label="Page navigation example"
        style={{ transform: "translateY(1px)" }}
      >
        <PaginationItem disabled={props.state.currentPage <= 0}>
          <PaginationLink
            onClick={(e) => props.handlePageChange(e, 0)}
            first
            href="#"
          />
        </PaginationItem>
        <PaginationItem disabled={props.state.currentPage <= 0}>
          <PaginationLink
            onClick={(e) =>
              props.handlePageChange(e, props.state.currentPage - 1)
            }
            previous
            href="#"
          />
        </PaginationItem>
        {[...Array(props.state.pagesCount)].map((page, i) => (
          <PaginationItem active={i === props.state.currentPage} key={i}>
            <PaginationLink
              href="#"
              onClick={(e) => props.handlePageChange(e, i)}
            >
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem
          disabled={props.state.currentPage >= props.state.pagesCount - 1}
        >
          <PaginationLink
            onClick={(e) =>
              props.handlePageChange(e, props.state.currentPage + 1)
            }
            next
            href="#"
          />
        </PaginationItem>
        <PaginationItem
          disabled={props.state.currentPage >= props.state.pagesCount - 1}
        >
          <PaginationLink
            onClick={(e) =>
              props.handlePageChange(e, props.state.pagesCount - 1)
            }
            last
            href="#"
          />
        </PaginationItem>
      </Pagination>
    </React.Fragment>
  );
}

function TableDropDownColumn(props) {
  const DropdownLink = (props) => {
    return (
      <Link
        to={props.link.linkString.concat(
          props.row[props.link.linkAccessorAdditon]
        )}
      >
        <DropdownItem>{props.link.label}</DropdownItem>
      </Link>
    );
  };

  const DropdownAction = (props) => {
    let thisaction;
    if (
      props.action.actionFunc === "delete" ||
      props.action.actionFunc === "messageNotesDelete"
    ) {
      thisaction = props.state.deleteAction;
    } else if (props.action.actionFunc === "add") {
      thisaction = props.state.addAction;
    } else if (
      props.action.actionFunc === "update" ||
      props.action.actionFunc === "messageNotesEdit"
    ) {
      thisaction = props.state.updateAction;
    } else if (props.action.actionFunc === "view") {
      thisaction = props.state.viewDetailsAction;
    } else if (props.action.actionFunc === "caseNotes") {
      thisaction = props.state.caseNotesAction;
    } else if (props.action.actionFunc === "messageNotes") {
      thisaction = props.state.messageNotesAction;
    } else if (props.action.actionFunc === "moveCase") {
      thisaction = props.state.moveAction;
    } else {
      thisaction = props.state[props.action.actionFunc + "Action"];
    }

    return (
      <div>
        {props.action.actionFunc === "caseNotes" ||
        props.action.actionFunc === "messageNotes" ||
        props.action.actionFunc === "moveCase" ? (
          <DropdownItem
            onClick={() =>
              thisaction(
                props.row[props.action.actionProps],
                props.action.actionFunc
              )
            }
          >
            {props.action.label}
          </DropdownItem>
        ) : props.action.actionFunc === "messageNotesEdit" ||
          props.action.actionFunc === "messageNotesDelete" ? (
          <DropdownItem
            onClick={() =>
              thisaction(
                props.row[props.action.actionProps],
                props.row[props.action.additionalParam]
              )
            }
          >
            {props.action.label}
          </DropdownItem>
        ) : (
          <DropdownItem
            onClick={() => thisaction(props.row[props.action.actionProps])}
          >
            {props.action.label}
          </DropdownItem>
        )}
      </div>
    );
  };

  const DropdownBody = (props) => {
    return (
      <DropdownMenu right>
        {props.dropdown.links?.length > 0 &&
          Object.entries(props.dropdown.links).map(([key, link]) => {
            return <DropdownLink key={key} row={props.row} link={link} />;
          })}
        {props.dropdown.actions?.length > 0 &&
          Object.entries(props.dropdown.actions).map(([key, action]) => {
            return (
              <DropdownAction
                key={key}
                row={props.row}
                action={action}
                state={props.state}
              />
            );
          })}
      </DropdownMenu>
    );
  };

  return (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownBody
        row={props.row}
        dropdown={props.dropdown}
        state={props.state}
      />
    </UncontrolledDropdown>
  );
}

function generateSortButtonTooltip(sortBy, sortType, accessor) {
  return sortType === null || sortBy !== accessor ? (
    <span>{i18n.t("DynamicTable.sortAscending")}</span>
  ) : sortType === Ascending ? (
    <span>{i18n.t("DynamicTable.sortDescending")}</span>
  ) : (
    <span>{i18n.t("DynamicTable.returnToDefaultSort")}</span>
  );
}

function RenderColumnHeader(props) {
  return props.state.infiniteScroll ? (
    <>
      {Object.entries(props.state.columns).map(([key, columnHeader]) => {
        if (columnHeader.show === "true") {
          return (
            <th
              className="bid-floor-col"
              style={{
                textAlign: columnHeader.headerAlign
                  ? columnHeader.headerAlign
                  : "left",
                width: columnHeader.columnWidth ?? "",
              }}
              key={key}
              filterkey={columnHeader.filterkey ? columnHeader.filterkey : ""}
              itemdisplayvaluefunc={
                columnHeader.itemdisplayvaluefunc
                  ? columnHeader.itemdisplayvaluefunc
                  : undefined
              }
              itemsortvaluefunc={
                columnHeader.itemsortvaluefunc
                  ? columnHeader.itemsortvaluefunc
                  : undefined
              }
              alignleft={
                columnHeader.alignleft ? columnHeader.alignleft : "false"
              }
              casesensitive={
                columnHeader.casesensitive
                  ? columnHeader.casesensitive
                  : "false"
              }
              showsearch={
                columnHeader.showsearch ? columnHeader.showsearch : "true"
              }
            >
              {columnHeader.filterFunc ? (
                <Row>
                  <Col>
                    <InputGroup
                      style={{
                        width: columnHeader.width,
                      }}
                    >
                      <div
                        style={{
                          width: columnHeader.filterComponentWidth,
                          position: "relative",
                          zIndex: 5,
                        }}
                      >
                        {columnHeader.filterType === "dropdown" ? (
                          <Select
                            options={sortByPropValue(
                              columnHeader.filterOptions,
                              "label"
                            )}
                            isMulti
                            onChange={(event) =>
                              columnHeader.filterFunc(
                                event,
                                columnHeader.accessor
                              )
                            }
                            isClearable={true}
                            value={columnHeader?.filterValue}
                            placeholder={i18n.t("DynamicTable.select")}
                            styles={{
                              control: (base) => ({
                                ...base,
                                fontWeight: "normal",
                              }),
                              container: (provided) => ({
                                ...provided,
                                fontWeight: "normal",
                              }),
                            }}
                          />
                        ) : columnHeader.filterType === "date" ? (
                          <Input
                            type="date"
                            format={getDateFormat()}
                            name="date"
                            style={{ height: "38px" }}
                            onChange={(event) =>
                              columnHeader.filterFunc(
                                event,
                                columnHeader.accessor
                              )
                            }
                          />
                        ) : columnHeader.filterType === "dateRange" ? (
                          <>
                            <Label>Start</Label>
                            <Input
                              type="date"
                              format={getDateFormat()}
                              name="date"
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                            <Label>End</Label>
                            <Input
                              type="date"
                              format={getDateFormat()}
                              name="date"
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc2(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                          </>
                        ) : columnHeader.filterType === "dateRange + number" ? (
                          <>
                            <Label>Start</Label>
                            <Input
                              type="date"
                              format={getDateFormat()}
                              name="date"
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                            <Label>End</Label>
                            <Input
                              type="date"
                              format={getDateFormat()}
                              name="date"
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc2(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                            <Label>Availability</Label>
                            <Input
                              type="number"
                              name="availability"
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc3(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                          </>
                        ) : columnHeader.filterType === "number range" ? (
                          <>
                            <Input
                              type="number"
                              name="numberStart"
                              placeholder={i18n.t("DynamicTable.MinValue")}
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                            <Input
                              type="number"
                              name="numberEnd"
                              placeholder={i18n.t("DynamicTable.MaxValue")}
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc2(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                          </>
                        ) : columnHeader.filterType ===
                          "dropdown + number range" ? (
                          <>
                            <Select
                              options={sortByPropValue(
                                columnHeader.filterOptions,
                                "label"
                              )}
                              isMulti
                              onChange={(event) =>
                                columnHeader.filterFunc(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                              isClearable={true}
                              value={columnHeader?.filterValue}
                              placeholder={i18n.t("DynamicTable.select")}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  fontWeight: "normal",
                                }),
                                container: (provided) => ({
                                  ...provided,
                                  fontWeight: "normal",
                                }),
                              }}
                            />
                            <Input
                              type="number"
                              name="numberStart"
                              placeholder={i18n.t("DynamicTable.MinValue")}
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc2(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                            <Input
                              type="number"
                              name="numberEnd"
                              placeholder={i18n.t("DynamicTable.MaxValue")}
                              style={{ height: "38px" }}
                              onChange={(event) =>
                                columnHeader.filterFunc3(
                                  event,
                                  columnHeader.accessor
                                )
                              }
                            />
                          </>
                        ) : (
                          <Input
                            placeholder="Filter"
                            onChange={(event) =>
                              columnHeader.filterFunc(
                                event,
                                columnHeader.accessor
                              )
                            }
                            value={columnHeader?.filterValue}
                            style={{ height: "38px" }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          zIndex: 5,
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          {columnHeader.sortFunc ? (
                            <Button
                              id={
                                props.state.tableId
                                  ? `sortFunction-${columnHeader.accessor}${props.state.tableId}`
                                  : `sortFunction-${columnHeader.accessor}`
                              } // set tableId for Home page where there are more than one DynamicTable instances;
                              // this is to allow the tooltip to properly render
                              color="link"
                              onClick={() => {
                                columnHeader.sortFunc(columnHeader.accessor);
                              }}
                              size="sm"
                              style={{ width: "20px" }}
                            >
                              {/**
                               * The icon stays at default if the sortType (asc, desc) is null
                               * or if the current active sort (sortBy) is not similar to the
                               * value in columnHeader.accessor
                               */}
                              <FontAwesomeIcon
                                icon={
                                  props.state.sortType === null ||
                                  props.state.sortBy !== columnHeader.accessor
                                    ? faSort
                                    : props.state.sortType === Ascending
                                    ? faSortUp
                                    : faSortDown
                                }
                              />
                              <UncontrolledTooltip
                                target={
                                  props.state.tableId
                                    ? `sortFunction-${columnHeader.accessor}${props.state.tableId}`
                                    : `sortFunction-${columnHeader.accessor}`
                                }
                              >
                                {generateSortButtonTooltip(
                                  props.state.sortBy,
                                  props.state.sortType,
                                  columnHeader.accessor
                                )}
                              </UncontrolledTooltip>
                            </Button>
                          ) : null}
                        </InputGroupAddon>
                      </div>
                    </InputGroup>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col>{columnHeader.header ? columnHeader.header : null}</Col>
              </Row>
            </th>
          );
        }
        return null;
      })}
    </>
  ) : (
    <TableFilter
      rows={props.state.data}
      onFilterUpdate={props.func}
      rowClass={null}
      initialFilters={null}
      rowComponent={null}
    >
      {Object.entries(props.state.columns).map(([key, columHeader]) => {
        if (columHeader.show === "true") {
          return (
            <th
              key={key}
              style={
                columHeader.columnWidth
                  ? { width: columHeader.columnWidth }
                  : {}
              }
              filterkey={columHeader.filterkey ? columHeader.filterkey : ""}
              alignleft={
                columHeader.alignleft ? columHeader.alignleft : "false"
              }
              casesensitive={
                columHeader.casesensitive ? columHeader.casesensitive : "false"
              }
              showsearch={
                columHeader.showsearch ? columHeader.showsearch : "true"
              }
              itemDisplayValueFunc={columHeader.itemDisplayValueFunc ?? null}
            >
              {columHeader.header
                ? columHeader.header
                : columHeader.accessor.toString()}
            </th>
          );
        }
        return null;
      })}
    </TableFilter>
  );
}

function RenderTableData(props) {
  let allKeys = [];
  let items = Array.isArray(
    props.state.dataLatestState[props.state.currentPage]
  )
    ? props.state.dataLatestState[props.state.currentPage]
    : props.state.dataLatestState;
  //Creates a Loop to Create a List of the Keys i want to have in the Table
  props.state.columns.forEach((column) => {
    if (column.show === "true" && column.accessor) {
      allKeys.push(column.accessor.toString());
    }
  });

  const RenderRow = (props) => {
    return props.keys.map((key, index) => {
      let result = props.columns.filter((obj) => {
        return obj.accessor === key;
      });
      let link = "";
      let linkFunc = null;
      if (result[0] !== undefined) {
        link =
          result[0] !== undefined &&
          result[0].linkAccessor !== undefined &&
          result[0].link !== undefined
            ? result[0].link.concat(props.data[result[0].linkAccessor])
            : result[0].link;
        linkFunc = result[0].linkFunc;
      }
      return link || linkFunc ? (
        <td key={`${key}-${props.data.id}`}>
          <Link
            to={link ? link : "#"}
            onClick={
              linkFunc
                ? () => linkFunc(props.data[result[0].linkFuncParameter])
                : undefined
            }
          >
            <Row key={`${key}-${props.data.id}`}>
              <Col key={`${key}-${props.data.id}`}>{props.data[key]}</Col>
            </Row>
            {props.columns[index]?.addionalInfoAccessor &&
            Array.isArray(
              props.data[props.columns[index]?.addionalInfoAccessor]
            )
              ? props.data[props.columns[index].addionalInfoAccessor]?.map(
                  (additonalInfo, i) => (
                    <Row key={i}>
                      <Col key={i}>{additonalInfo}</Col>
                    </Row>
                  )
                )
              : null}
          </Link>
        </td>
      ) : (
        <td style={{ whiteSpace: "pre" }} key={`${key}-${props.data.id}`}>
          <Row key={`${key}-${props.data.id}`}>
            <Col key={`${key}-${props.data.id}`}>
              {props.columns[index].itemDisplayValueFunc === undefined
                ? props.data[key]
                : props.columns[index].itemDisplayValueFunc(props.data[key])}
            </Col>
          </Row>
          {props.columns[index]?.addionalInfoAccessor &&
          Array.isArray(props.data[props.columns[index]?.addionalInfoAccessor])
            ? props.data[props.columns[index].addionalInfoAccessor]?.map(
                (additonalInfo, i) => (
                  <Row key={i}>
                    <Col key={i}>{additonalInfo}</Col>
                  </Row>
                )
              )
            : null}
        </td>
      );
    });
  };

  props.state.columns.forEach((column) => {
    if (column.show === true) {
      allKeys.push(column.accessor);
    }
  });

  return items.map((row, index) => {
    return (
      <tr
        key={index}
        id={row.popover ? `${props.state.tableId}-popover-${index}` : null}
      >
        {row.popover ? (
          <UncontrolledPopover
            placement="bottom"
            trigger="legacy hover"
            target={`${props.state.tableId}-popover-${index}`}
            style={{ position: "relative" }}
          >
            <PopoverBody>{row.popoverData}</PopoverBody>
          </UncontrolledPopover>
        ) : null}
        <RenderRow
          key={index}
          data={row}
          hasPopOver={row.popover ? true : false}
          popoverData={row.popoverData}
          id={`popover-${index}`}
          keys={allKeys}
          columns={props.state.columns}
          showDeadline={props.state.showDeadline}
          isAdmin={props.state.isAdmin}
          style={{ position: "relative" }}
        />

        {props.state.dropdown !== undefined && (
          <td className="text-center">
            <TableDropDownColumn
              key={index}
              row={row}
              dropdown={props.state.dropdown}
              state={props.state}
            />
          </td>
        )}
      </tr>
    );
  });
}

export default DynamicTable;
