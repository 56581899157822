import React from "react";
import { MoreVertical } from "react-feather";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import roleEnum from "../../../enums/RoleEnums";
import { isEmpty } from "../../../helpers/GenericHelper";
import AdvisorModalClientList from "../../components/AdvisorModalClientList/AdvisorModalClientList";
import ModalDelete from "../../components/modal/DeleteModal";
import ModalError from "../../components/modal/ModalError";
import ModalInfo from "../../components/modal/ModalInfo";

/**
 * Class for displaying advisor list for admins
 */
class AdminAdvisorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      advisors: [],
      showModalError: false,
      showModalDelete: false,
      showModalInfo: false,
    };

    this.translation = this.props.t;
    this.syncConnector = "sync_connector";

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.modalTitle = "";
    this.modalBodyText = "";

    this.modalDeleteTitle = "";
    this.modalDeleteBody = "";
    this.modalDeleteButtonText = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.deleteAdvisor = this.deleteAdvisor.bind(this);
    this.toggleModalInfo = this.toggleModalInfo.bind(this);
    this.viewAdvisorClients = this.viewAdvisorClients.bind(this);
  }

  componentDidMount() {
    axios.account
      .get("get-all-users")
      .then((response) => {
        if (!isEmpty(response.data)) {
          let users = response.data.filter((user) =>
            user.authorities.includes(roleEnum.USER)
          );
          this.setState({ advisors: users });
        }
      })
      .catch((error) => {
        this.errorHandler("Generate Advisor List", error);
      });
  }

  deactivateAdvisor(login) {
    this.modalDeleteTitle = this.translation("advisors.deactivateAdvisorTitle");
    this.modalDeleteBody = this.translation(
      "advisors.deactivateAdvisorMessage"
    );
    this.modalDeleteButtonText = this.translation(
      "commonButtonTexts.deactivate"
    );
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      axios.account.put(`users/deactivate/${login}`).then(() => {
        let index = this.state.advisors.findIndex(
          (advisor) => advisor.login === login
        );
        let updatedAdvisors = this.state.advisors;
        updatedAdvisors[index].activated = false;

        this.setState({ advisors: updatedAdvisors });
      });
    };
  }

  deleteAdvisor(login) {
    this.modalDeleteTitle = this.translation("advisors.deleteAdvisorTitle");
    this.modalDeleteBody = this.translation("advisors.deleteAdvisorMessage");
    this.modalDeleteButtonText = this.translation("commonButtonTexts.delete");

    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      axios.account.delete(`users/${login}/delete`).then(() => {
        let updatedAdvisors = this.state.advisors.filter(
          (advisor) => advisor.login !== login
        );
        this.setState({ advisors: updatedAdvisors });
      });
    };
  }

  reactivateAdvisor(login) {
    this.modalDeleteTitle = this.translation("advisors.activateAdvisorTitle");
    this.modalDeleteBody = this.translation("advisors.activateAdvisorMessage");
    this.modalDeleteButtonText = this.translation("commonButtonTexts.activate");
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      axios.account.put(`users/reactivate/${login}`).then(() => {
        let index = this.state.advisors.findIndex(
          (advisor) => advisor.login === login
        );
        let updatedAdvisors = this.state.advisors;
        updatedAdvisors[index].activated = true;

        this.setState({ advisors: updatedAdvisors });
      });
    };
  }

  // Toggles the Boolean that affects the appearance of the deletion modal.
  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  toggleModalInfo() {
    this.setState({
      showModalInfo: !this.state.showModalInfo,
    });
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    if (currentOperation === "Generate Advisor List") {
      this.mainError = this.translation(`errorMessages.advisorListLoadError`);
    } else {
      this.mainError = this.translation(`errorMessages.internalServerError`);
      this.errorResponse = error.message;
      if (!this.state.showModalError) {
        this.toggleModalError();
      }
      return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  prepareTableData = (advisors) => {
    if (Array.isArray(advisors) && advisors.length > 0) {
      let newTableData = [];

      advisors.forEach((advisor) => {
        let entry = {
          id: advisor.id,
          name: advisor.firstName + " " + advisor.lastName,
          email: advisor.email,
          activated: advisor.activated ? "Active" : "Inactive",
          advisor: advisor,
          action: (
            <UncontrolledDropdown
              className="d-inline-block"
              style={{ transform: "translateX(6px)" }}
            >
              <DropdownToggle tag="a">
                <MoreVertical />
              </DropdownToggle>
              <DropdownMenu right>
                {advisor.activated ? (
                  <DropdownItem
                    onClick={this.deactivateAdvisor.bind(this, advisor.login)}
                  >
                    {this.translation(`commonButtonTexts.deactivate`)}
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    onClick={this.reactivateAdvisor.bind(this, advisor.login)}
                  >
                    {this.translation(`commonButtonTexts.activate`)}
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={this.deleteAdvisor.bind(this, advisor.login)}
                >
                  {this.translation(`commonButtonTexts.delete`)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  viewAdvisorClients(advisor) {
    this.modalBodyText = (
      <AdvisorModalClientList parentPage="advisorList" advisor={advisor} />
    );
    this.modalTitle = `${advisor.lastName}, ${
      advisor.firstName
    }: ${this.translation(`adminClientList.assignedClients`)}`;
    this.toggleModalInfo();
  }

  render() {
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`commonText.id`),
        accessor: "id",
        show: "false",
        filterkey: "id",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation(`fieldLabels.name`),
        accessor: "name",
        show: "true",
        filterkey: "name",
        showsearch: "true",
        linkFunc: this.viewAdvisorClients,
        linkFuncParameter: "advisor",
      },
      {
        type: "data",
        header: this.translation(`fieldLabels.email`),
        accessor: "email",
        show: "true",
        filterkey: "email",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation(`advisors.accountStatus`),
        accessor: "activated",
        show: "true",
        filterkey: "activated",
        showsearch: "true",
        alignleft: "true",
      },
    ];
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.translation(`commonText.advisors`)}</HeaderTitle>
        </Header>
        <Card>
          <CardHeader>
            <CardTitle>
              <h1>
                {this.translation(`commonText.advisors`)}
                {/* <Link to={`/admin/advisors/add`}>
                  <Button
                    color="primary"
                    size="s"
                    className="float-right"
                    id="add-advisor-button"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </Button>
                  <UncontrolledTooltip target="add-advisor-button">
                    {this.translation(`adminAdvisorAdd.addAdvisor`)}
                  </UncontrolledTooltip>
                </Link> */}
              </h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            {Array.isArray(this.state.advisors) &&
              (this.state.advisors.length > 0 ? (
                <DynamicTable
                  data={this.prepareTableData(this.state.advisors)}
                  infiniteScroll
                  columns={preparedColumns}
                />
              ) : (
                <div className="text-center">
                  <Label>
                    {this.translation(`adminAdvisorList.noAdvisor`)}
                  </Label>
                </div>
              ))}
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalDelete
          isOpen={this.state.showModalDelete}
          onClose={this.toggleModalDelete}
          event={this.modalDeleteEvent}
          modalTitle={this.modalDeleteTitle}
          modalBodyText={this.modalDeleteBody}
          buttonText={this.modalDeleteButtonText}
        ></ModalDelete>
        <ModalInfo
          isOpen={this.state.showModalInfo}
          modalTitle={this.modalTitle}
          toggleModal={this.toggleModalInfo}
          modalBodyText={this.modalBodyText}
          footerStyle={{ "padding-right": "1.5rem" }}
        ></ModalInfo>
      </Container>
    );
  }
}

export default withTranslation()(AdminAdvisorList);
