import {
  faBan,
  faCaretSquareDown,
  faCaretSquareLeft,
  faEdit,
  faFilePdf,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import { formatDate, isEmpty } from "../../../helpers/GenericHelper";
import ModalError from "../modal/ModalError";
import generateQuestion from "./components/generateQuestion";
import questionnaire from "./questionnaire.json";

/**
 * Class for displaying the case questionnaires
 */
class CaseQuestionnaireCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialState: false,
      editQuestions: false,
      showQuestions: false,
      showModalError: false,
      answers: [],
      categoriesList: [],
      answersToBeRemoved: [],
    };
    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";
    this.keepAlive = 0;

    this.editQuestions = this.editQuestions.bind(this);
    this.toggleShowQuestion = this.toggleShowQuestion.bind(this);
    this.getDatabaseAnswers = this.getDatabaseAnswers.bind(this);
    this.defaultDateObjectAddition = this.defaultDateObjectAddition.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.saveQuestionnaire = this.saveQuestionnaire.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.urgencyDateCalculation = this.urgencyDateCalculation.bind(this);
    this.addDynamicField = this.addDynamicField.bind(this);
    this.removeDynamicQuestion = this.removeDynamicQuestion.bind(this);
    this.removeValuesOfHiddenFields =
      this.removeValuesOfHiddenFields.bind(this);
    this.removeValuesOfDynamicFields =
      this.removeValuesOfDynamicFields.bind(this);
    this.removeValues = this.removeValues.bind(this);
  }

  async componentDidMount() {
    this.getDatabaseAnswers();
  }

  // Toggle for showing the questions
  toggleShowQuestion() {
    this.setState({
      showQuestions: !this.state.showQuestions,
    });
  }

  // Toggle for editing the questions. Cancelling reloads the page so that answers are reverted back to their previous states.
  editQuestions(action) {
    if (action === "Editing") {
      this.keepAlive = setInterval(() => {
        axios.keepAlive
          .get("keep-alive")
          .then(() => { })
          .catch(() => { });
      }, 300000);
    }
    if (action === "Cancelling") {
      clearInterval(this.keepAlive);
      this.keepAlive = 0;

      this.setState({
        answers: _.cloneDeep(this.state.oldAnswers),
        editQuestions: !this.state.editQuestions,
      });
    } else {
      this.setState({
        oldAnswers: _.cloneDeep(this.state.answers),
        editQuestions: !this.state.editQuestions,
      });
    }
  }

  // Gets answers from the database if there are any
  async getDatabaseAnswers() {
    let answers = await axios.advisorService
      .get(
        `questionnaire-answers?whistleblowercaseId.equals=${this.props.whistleblowerCase.id}`
      )
      .then((response) => {
        return response.data;
      });

    // check if there is questionId : 0 which is the date case is recieved. if there is none add it to list of answers
    if (!answers.find((answer) => answer.questionId === 0)) {
      answers.push(this.defaultDateObjectAddition(0));
    }

    // check if there is questionId : 87 which is the date of forwarding. if there is none add it to list of answers
    if (!answers.find((answer) => answer.questionId === 87)) {
      answers.push(this.defaultDateObjectAddition(87));
    }

    // check if there is questionId : 89 which is the resubmission date. if there is none add it to list of answers
    if (!answers.find((answer) => answer.questionId === 89)) {
      answers.push(this.defaultDateObjectAddition(89));
      this.setState({ initialState: !this.state.initialState });
    }
    this.setState({ answers: answers });
  }

  defaultDateObjectAddition(questionId) {
    const { whistleblowerCase } = this.props;

    let answerObject = {
      id: null,
      questionId: questionId,
      answer: null,
      whistleblowercase: { id: whistleblowerCase.id },
      order: null,
    };

    switch (questionId) {
      case 0:
        answerObject.answer = formatDate(
          whistleblowerCase.caseStatuses[0].dateTime,
          true
        );
        break;
      case 87:
        answerObject.answer = formatDate(moment());
        break;
      case 89:
        answerObject.answer = formatDate(moment().add(14, "days"));
        break;
      default:
        break;
    }

    return answerObject;
  }

  // Handles changes to the questionnaire field
  handleFieldChange(event, field, order = null) {
    let { answers } = this.state;
    const { whistleblowerCase } = this.props;
    let fieldType = field.fieldType;

    let currentAnswer;
    if (order) {
      currentAnswer = answers.find(
        (answer) =>
          answer.questionId === field.questionId && answer.order === order
      );
    } else {
      currentAnswer = answers.find(
        (answer) => answer.questionId === field.questionId
      );
    }
    let index;

    if (currentAnswer) {
      index = answers.indexOf(currentAnswer);
    }

    let newAnswer;
    switch (fieldType) {
      case "boolean":
      case "checkbox":
        newAnswer = event.target.checked;
        break;
      case "enum":
      case "textarea":
        newAnswer = event.target.value;
        break;
      default:
        break;
    }

    if (!currentAnswer) {
      answers.push({
        id: null,
        questionId: field.questionId,
        answer: newAnswer,
        whistleblowercase: { id: whistleblowerCase.id },
        order: order,
      });
    } else {
      answers[index].answer = newAnswer;
    }

    // Sets date for the resubmission date field.
    if (field.questionId === 88) {
      let urgencyDateObject = answers.find(
        (answer) => answer.questionId === 89
      );
      let urgencyDateIndex = answers.indexOf(urgencyDateObject);
      answers[urgencyDateIndex].answer = this.urgencyDateCalculation(
        event.target.value
      );
    }

    // See if the question changed has fields that would be hidden depending on the question's answer
    let extraFieldObject = questionnaire.categories.find((category) => {
      return category.extraFields?.find(
        (extraField) => extraField.dependentOn === field.questionId
      );
    });

    // If there are, the values of those fields are removed if the user hides them
    if (extraFieldObject !== undefined && field.questionId !== 16) {
      answers = this.removeValuesOfHiddenFields(
        newAnswer,
        extraFieldObject,
        answers
      );
    }
    // Remove values of the dynamic fields if hidden
    else if (extraFieldObject !== undefined && field.questionId === 16) {
      answers = this.removeValuesOfDynamicFields(
        newAnswer,
        extraFieldObject,
        answers
      );
    }
    this.setState({ answers: answers });
  }

  removeValuesOfHiddenFields(newAnswer, extraFieldObject, answers) {
    // Checks if the answer for the parent question is the answer needed to hide the fields to be hidden or not
    if (
      newAnswer !==
      extraFieldObject?.extraFields[0]?.requiredAnswerForExtraField
    ) {
      let fieldsToCheck = extraFieldObject?.extraFields[0]?.fields;
      let answerToRemove;
      fieldsToCheck.forEach((fields) => {
        answerToRemove = answers.find(
          (answer) => answer.questionId === fields.questionId
        );

        // Begins removing the answers for answers that are recently hidden
        if (answerToRemove !== undefined) {
          answers = this.removeValues(answerToRemove, answers);
        }
      });
    }

    return answers;
  }

  removeValuesOfDynamicFields(newAnswer, extraFieldObject, answers) {
    // Checks if the answer for the parent question is the answer needed to hide the fields to be hidden or not
    if (
      newAnswer !==
      extraFieldObject?.extraFields[0]?.requiredAnswerForExtraField
    ) {
      let fieldsToCheck = extraFieldObject?.extraFields[0]?.fields;
      let answersToRemove;

      // For dynamic fields, multiple fields of it are possible, so they are filtered from the answers array instead of being
      // located individually
      fieldsToCheck.forEach((fields) => {
        answersToRemove = answers.filter(
          (answer) => answer.questionId === fields.questionId
        );
        if (!isEmpty(answersToRemove)) {
          answersToRemove.forEach((answerToRemove) => {
            answers = this.removeValues(answerToRemove, answers);
          });
        }
      });
    }

    return answers;
  }

  // Common method for removing values for both normal hidden fields and dynamic fields
  removeValues(answerToRemove, answers) {
    let index = answers.indexOf(answerToRemove);

    // For existing answers that have already been saved to the database, its value is left as null
    if (answerToRemove.id !== null && index > -1) {
      answers[index].answer = null;
    }

    // For answers that are not yet saved to the database, it is removed from the answers array
    else if (answerToRemove.id === null && index > -1) {
      answers.splice(index, 1);
    }

    return answers;
  }

  urgencyDateCalculation(urgencyType) {
    let urgencyDate = "";
    switch (urgencyType) {
      case "lowUrgency":
        urgencyDate = formatDate(moment().add(14, "days"));
        break;
      case "medUrgency":
        urgencyDate = formatDate(moment().add(5, "days"));
        break;
      case "highUrgency":
        urgencyDate = formatDate(moment().add(1, "days"));
        break;
      default:
        break;
    }
    return urgencyDate;
  }

  addDynamicField(extraField, order) {
    let { answers } = this.state;
    const { whistleblowerCase } = this.props;
    if (extraField.multiplePossible) {
      for (const field of extraField.fields) {
        let newFields = {
          id: null,
          questionId: field.questionId,
          answer: "",
          whistleblowercase: { id: whistleblowerCase.id },
          order: order,
        };
        answers.push(newFields);
      }

      this.setState({ answers: answers });
    }
  }

  // Save answers of the questionnaire
  async saveQuestionnaire() {
    if (this.state.answersToBeRemoved.length > 0) {
      axios.advisorService.post(
        `questionnaire-answers/deleteMultiple`,
        this.state.answersToBeRemoved
      );
      this.setState({ answersToBeRemoved: [] });
    }

    await axios.advisorService
      .post(`questionnaire-answers/saveAll`, this.state.answers)
      .then((response) => {
        if (this.state.editQuestions === true) {
          clearInterval(this.keepAlive);
          this.keepAlive = 0;
          this.editQuestions();
        }
        this.getDatabaseAnswers();
      })
      .catch((error) => {
        this.errorHandler("Save Questionnaire Answers", error);
      });
  }

  removeDynamicQuestion(order, questionIdDependentOn) {
    let { answers, answersToBeRemoved } = this.state;

    // get object form json
    let extraFieldObject = questionnaire.categories.find((category) => {
      return category.extraFields?.find(
        (extraField) => extraField.dependentOn === questionIdDependentOn
      );
    });

    let questionIdsToRemove = [];
    extraFieldObject.extraFields.forEach((extraFields) => {
      extraFields.fields.forEach((field) =>
        questionIdsToRemove.push(field.questionId)
      );
    });

    answersToBeRemoved = answersToBeRemoved.concat(
      answers.filter(
        (answer) =>
          answer.order === order &&
          questionIdsToRemove.includes(answer.questionId)
      )
    );
    answers = answers.filter((answer) => !answersToBeRemoved.includes(answer));

    // arrange order dynamic field
    answers.forEach((answer) => {
      if (
        answer.order > order &&
        questionIdsToRemove.includes(answer.questionId)
      ) {
        answer.order -= 1;
      }
    });

    this.setState({
      answers: answers,
      answersToBeRemoved: answersToBeRemoved,
    });
  }

  // Toggles Boolean for showing the error modal dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Handles the errors found in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Questionnaire Answers":
        this.mainError = this.translation(
          `errorMessages.questionnaireAnswersLoadError`
        );
        break;
      case "Save Questionnaire Answers":
        this.mainError = this.translation(
          `errorMessages.questionnaireAnswersSaveError`
        );
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  printQuestionnaire() {
    if (this.state.initialState === true) {
      this.setState({ initialState: false });
      this.saveQuestionnaire();
    }
    axios.advisorService
      .get(
        `export-questionnaire/${this.props.whistleblowerCase.id}/${this.props.i18n.language}`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        FileSaver(
          response.data,
          `${this.props.whistleblowerCase.title} Questionnaire.pdf`
        );
        return response.data;
      });
  }

  render() {
    const saveButton = (location) => (
      <div className="card-actions float-right">
        <Button
          color="primary"
          size="m"
          id="save-bottom"
          style={
            location === "top" ? { marginRight: "5px" } : { marginLeft: "5px" }
          }
          onClick={this.saveQuestionnaire}
        >
          <FontAwesomeIcon icon={faSave} />
        </Button>
        <UncontrolledTooltip placement="top" target="save-bottom">
          {this.translation("commonButtonTexts.save")}
        </UncontrolledTooltip>
      </div>
    );
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <h1 className="float-left">
              {this.translation("commonText.questionnaire")}
            </h1>

            <Button
              color="primary"
              size="m"
              onClick={this.toggleShowQuestion}
              className="float-right"
              id="questionToggle"
            >
              {this.state.showQuestions ? (
                <FontAwesomeIcon icon={faCaretSquareDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretSquareLeft} />
              )}
            </Button>
            <UncontrolledTooltip target="questionToggle" placement="left">
              {!this.state.showQuestions
                ? this.translation(`commonButtonTexts.expand`)
                : this.translation(`commonButtonTexts.collapse`)}
            </UncontrolledTooltip>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {this.state.showQuestions && (
            <>
              {this.state.showQuestions && (
                <div className="card-actions float-right">
                  <Button
                    color="primary"
                    size="m"
                    id="exportAsPdf"
                    onClick={() => this.printQuestionnaire()}
                    className="float-right"
                  >
                    <FontAwesomeIcon icon={faFilePdf} />
                  </Button>
                  <UncontrolledTooltip placement="top" target="exportAsPdf">
                    {this.translation(`statistics.exportAsPdf`)}
                  </UncontrolledTooltip>
                  {this.state.editQuestions && saveButton("top")}
                  <Button
                    color="primary"
                    size="m"
                    id="cancelEdit"
                    onClick={this.editQuestions.bind(
                      this,
                      this.state.editQuestions ? "Cancelling" : "Editing"
                    )}
                    style={{ "margin-right": "5px" }}
                  >
                    {this.state.editQuestions ? (
                      <FontAwesomeIcon icon={faBan} />
                    ) : (
                      <FontAwesomeIcon icon={faEdit} />
                    )}
                  </Button>
                  <UncontrolledTooltip placement="top" target="cancelEdit">
                    {this.state.editQuestions
                      ? this.translation("commonButtonTexts.cancel")
                      : this.translation("commonButtonTexts.edit")}
                  </UncontrolledTooltip>
                </div>
              )}
              <Table>
                <tbody>
                  {!isEmpty(questionnaire.categories) &&
                    questionnaire.categories?.map((category, i) => (
                      <Table
                        className={category.fields.length > 0 ? "mb-5" : "mb-0"}
                      >
                        <thead>
                          <tr>
                            <th colspan="2" style={{ border: "none" }}>
                              <h4 key={`category-${i}`}>
                                {!category.isSubHeader ? this.translation(
                                  `questionnaire.${category.categoryName}`
                                ) :
                                  <>
                                    {this.translation(
                                      `questionnaire.${category.categoryName}`
                                    )}{" "}
                                    {this?.state?.answers.find((answer) => (answer.questionId === category.fields[0].questionId))?.answer}
                                  </>

                                }
                              </h4>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!category.isSubHeader && generateQuestion(
                            category,
                            this.state.answers,
                            this.handleFieldChange,
                            this.state.editQuestions,
                            this.addDynamicField,
                            this.removeDynamicQuestion
                          )}
                        </tbody>
                      </Table>
                    ))}
                </tbody>
              </Table>

              {this.state.editQuestions && saveButton("bottom")}
            </>
          )}
        </CardBody>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Card>
    );
  }
}

CaseQuestionnaireCard.propTypes = {
  whistleblowerCase: PropTypes.object.isRequired,
};

export default withRouter(withTranslation()(CaseQuestionnaireCard));
