import React from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import { IMPORTANT, VERYIMPORTANT } from "../../../helpers/Constants";
import { isEmpty, formatDate } from "../../../helpers/GenericHelper";
import { store } from "../../../redux/store/index";
import ModalError from "../modal/ModalError";
/*
 * Class component for displaying all notification of an advisor
 */
class NotificationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allNotifications: [],
      showModalError: false,
      activeNotificationTab: "standard",
    };

    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";
    this.currentPageRead = 0;
    this.currentPage = 0;

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.loadNotification = this.loadNotification.bind(this);

    this.preparedColumns = [
      {
        type: "data",
        header: this.translation(`caseNotifications.notification`),
        accessor: "message",
        show: "true",
        link: "",
        linkAccessor: "url",
      },
    ];
  }

  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  componentDidMount() {
    this.loadNotification();
  }

  async loadNotification() {
    let state = store.getState();
    let accountDetails = state.account.accountDetails;

    axios.advisorService
      .get(`notifications/${accountDetails.id}`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({
            allNotifications: [
              ...this.state.allNotifications,
              ...response.data,
            ],
          });
        }
      })
      .catch((error) => {
        this.errorHandler("Getting Notifications", error);
      });
  }

  errorHandler(currentOperation, error) {
    if (currentOperation === "Getting Notifications") {
      this.mainError = this.translation(`errorMessages.notificationsListError`);
    } else if (currentOperation === "failedToMarkAsReadNotification") {
      this.mainError = this.translation(
        `errorMessages.failedToMarkAsReadNotification`
      );
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  prepareTableData = (notifications) => {
    if (Array.isArray(notifications) && notifications.length > 0) {
      let newTableData = [];
      let notificationCount = 0;

      notifications.forEach((notification) => {
        let className = "";
        if (notification.urgency && notification.urgency === IMPORTANT) {
          className = "notification-near";
        } else if (
          notification.urgency &&
          notification.urgency === VERYIMPORTANT
        ) {
          className = "notification-urgent";
        }

        let action = null;
        let entry = {
          url: notification.url,

          message: (
            <>
              <span
                id={`messagePopover-${notificationCount}`}
                className={`notification ${className}`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: notification.message,
                  }}
                />
              </span>
              <UncontrolledTooltip
                placement="top"
                target={`messagePopover-${notificationCount}`}
              >
                {formatDate(notification.dateCreated, true)}
              </UncontrolledTooltip>
            </>
          ),
          action: action,
        };

        notificationCount++;
        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  toggleNotificationTab = (tab) => {
    if (this.state.activeNotificationTab !== tab)
      this.setState({ activeNotificationTab: tab });
  };

  render() {
    const { allNotifications } = this.state;
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <h1>{this.translation(`caseNotifications.notificationsHeader`)}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              {Array.isArray(allNotifications) &&
                (allNotifications.length > 0 ? (
                  <DynamicTable
                    data={this.prepareTableData(allNotifications)}
                    columns={this.preparedColumns}
                    fetchData={this.loadNotification}
                    header={false}
                    tableId="unreadNotification"
                    infiniteScroll
                  />
                ) : (
                  <div className="text-center">
                    <Label>
                      {this.translation(`caseNotifications.noNotifications`)}
                    </Label>
                  </div>
                ))}
            </Col>
          </Row>
        </CardBody>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Card>
    );
  }
}

export default withTranslation()(NotificationCard);
