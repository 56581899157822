const CaseGroupColorEnum = {
  CORRUPTION: "#f0002a",
  ANTITRUST: "#833387",
  MONEYLAUNDERING: "#827327",
  THEFT: "#5c4033",
  INFIDELITY: "#2ce954",
  EMBEZZLEMENT: "#cee0c8",
  ACCOUNTING: "#c9c511",
  DATAPROTECTION: "#4ea26d",
  EXPORTCONTROL: "#ba9d67",
  TAX: "#7afbc5",
  LABOR: "#320068",
  ENVIRONMENTAL: "#b9385b",
  TECHNICAL: "#e47110",
  INJURY: "#3cb3ba",
  HARASSMENT: "#c117a6",
  HUMANRIGHTS: "#d1371a",
  INTERNAL: "#2859be",
  FRAUD: "#000000",
  OTHER: "#FFF200",
};

export default CaseGroupColorEnum;
