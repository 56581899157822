import {
  faCommentAlt,
  faEdit,
  faEnvelope,
  faEnvelopeOpen,
  faFileDownload,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import Message from "../../../components/Message";
import flagEnums from "../../../enums/FlagEnums";
import messageStatusEnum from "../../../enums/MessageStatusEnum";
import roleEnum from "../../../enums/RoleEnums";
import {
  LoadingIndicator,
  formatDate,
  getMessageLatestStatus,
  isEmpty,
} from "../../../helpers/GenericHelper";
import { store } from "../../../redux/store";
import CaseNotesAdd from "../../advisor-dashboard/case/CaseNotesAdd";
import ModalDelete from "../modal/DeleteModal";
import ModalError from "../modal/ModalError";
import ModalForm from "../modal/ModalForm";
import ModalInfo from "../modal/ModalInfo";

/*
 * Class for displaying a list of messages
 */

class MessageListCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      users: [],
      showCaseNotesAdd: false,
      showModalDelete: false,
      showModalError: false,
      showModalMessage: false,
      showModalMessageForm: false,

      pagesCount: null,
      currentPage: 0,
    };
    this.translation = this.props.t;
    this.whistleblowerCase = this.props.whistleblowerCase;

    this.itemsPerPage = 20;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.modalBodyText = "";
    this.modalForm = "";
    this.modalTitle = "";
    this.modalDeleteEvent = null;

    this.modalDeleteTitle = "";
    this.modalDeleteText = "";

    this.messageForm = null;

    this.setTableData = this.setTableData.bind(this);
    this.showCaseNotesAdd = this.showCaseNotesAdd.bind(this);
    this.viewMessageNotes = this.viewMessageNotes.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.editDraft = this.editDraft.bind(this);
    this.onModalFormSubmit = this.onModalFormSubmit.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.toggleModalForm = this.toggleModalForm.bind(this);
    this.toggleModalMessage = this.toggleModalMessage.bind(this);
    this.deleteDraft = this.deleteDraft.bind(this);
    this.toggleModalMessageForm = this.toggleModalMessageForm.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
    this.createMessage = this.createMessage.bind(this);
    this.getMessageSender = this.getMessageSender.bind(this);
  }

  componentDidMount() {
    this.setTableData();
  }

  async setTableData() {
    let messagesCount = await axios.advisorService
      .get(
        `messages/count?whistleblowercaseId.equals=${this.whistleblowerCase.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Message Initialization", error);
      });

    let messages = await axios.advisorService
      .get(
        `messages?page=${this.state.currentPage}&sort=id%2CDESC&whistleblowercaseId.equals=${this.whistleblowerCase.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Message Initialization", error);
      });

    let senders = await axios.account
      .get("get-all-users")
      .then((response) => {
        if (!isEmpty(response.data)) {
          let users = response.data.filter((user) =>
            user.authorities.includes(roleEnum.USER)
          );
          return users;
        }
      })
      .catch((error) => {
        this.errorHandler("Generate Advisor List", error);
      });

    this.setState({
      users: senders,
      messages: messages,
      pagesCount: Math.ceil(messagesCount / this.itemsPerPage),
    });
  }

  /**
   * Set a new status for a message
   * @param {*} status new status for message
   * @param {*} message message to obtain new status
   * @returns updated message with new status
   */
  async setNewStatus(status, message) {
    let messageStatus = {
      id: null,
      statusOfMessage: status,
      dateTime: moment().toDate().toISOString(),
      message: { id: message.id },
    };

    messageStatus = await axios.advisorService
      .post(`message-statuses`, messageStatus)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Creating Message Status", error);
      });

    message.messageStatuses.push(messageStatus);

    return message;
  }

  getMessageSender(userId) {
    /* If the userId is system, that means that the message is made by the client and
      the function returns the same userId.*/
    let senders = this.state.users.filter((user) => user.id.includes(userId));
    if (senders.length === 0) {
      return "Whistleblower";
    }
    let senderName = senders.map(
      (name) => name.lastName + ", " + name.firstName
    );
    return senderName;
  }

  // Shows the CaseNotesAdd modal form dialog box
  showCaseNotesAdd(notesData, messageId) {
    this.modalForm = (
      <CaseNotesAdd
        whistleblowerCaseId={null}
        messageId={messageId}
        notesData={notesData}
        refreshAutomaticNotesList={this.props.refreshAutomaticNotesList}
      />
    );
    this.toggleModalForm();
  }

  // Shows a modal that contains a list of notes attached to the message
  async viewMessageNotes(messageId) {
    let noteArray = await axios.advisorService
      .get(`notes?messageId.equals=${messageId}`)
      .then((response) => {
        let latestNotes = [];
        response.data.forEach((noteData) => {
          if (noteData.validUntil === null) {
            latestNotes.push(noteData);
          }
        });
        return latestNotes;
      })
      .catch((error) => {
        this.errorHandler("Viewing Message Notes", error);
      });

    noteArray = noteArray.reverse();

    const preparedColumns = [
      {
        type: "data",
        header: this.translation("commonText.note"),
        accessor: "notes",
        show: "true",
        filterkey: "notes",
        showsearch: "true",
        columnWidth: "40%",
      },
      {
        type: "data",
        header: this.translation("commonText.dateCreated"),
        accessor: "dateTime",
        show: "true",
        filterkey: "dateTime",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation("commonText.creator"),
        accessor: "creator",
        show: "true",
        filterkey: "creator",
        showsearch: "true",
      },
      {
        type: "Dropdown",
        header: this.translation(`commonText.menu`),
        show: "true",
      },
    ];

    this.modalBodyText = (
      <div>
        <h2>{this.translation("commonText.notes")}</h2>
        <div className="card-actions float-right">
          <Button
            color="primary"
            size="m"
            onClick={() => this.showCaseNotesAdd(null, messageId)}
          >
            <FontAwesomeIcon icon={faPlusSquare} />{" "}
            {this.translation(`notes.addNote`)}{" "}
          </Button>
        </div>
        <br />
        <br />
        {Array.isArray(noteArray) &&
          (noteArray.length > 0 ? (
            <DynamicTable
              data={this.prepareTableData(noteArray)}
              columns={preparedColumns}
              updateAction={this.showCaseNotesAdd}
              infiniteScroll
              deleteAction={this.deleteNote}
              removeMenuPortalTarget={true}
              dropdown={{
                actions: [
                  {
                    label: this.translation(`commonButtonTexts.edit`),
                    actionFunc: "messageNotesEdit",
                    actionProps: "note",
                    additionalParam: "messageId",
                  },
                  {
                    label: this.translation(`commonButtonTexts.delete`),
                    actionFunc: "messageNotesDelete",
                    actionProps: "note",
                    additionalParam: "messageId",
                  },
                ],
              }}
            />
          ) : (
            <div className="text-center">
              <Label>{this.translation(`notes.noNotes`)}</Label>
            </div>
          ))}
      </div>
    );
    this.toggleModalMessage();
  }

  prepareTableData = (notes) => {
    if (Array.isArray(notes) && notes.length > 0) {
      let newTableData = [];

      notes.forEach((note) => {
        let entry = {
          id: note.id,
          notes: note.notes,
          dateTime: formatDate(note.dateTime, true),
          creator: note.creator,
          note: note,
          messageId: note?.message?.id,
          popover: true,
          popoverData: (
            <ul className="p-3 m-0">
              <li>
                {this.translation(`commonText.modifier`)}:{" "}
                {note.modifier !== null ? note.modifier : "N/A"}
              </li>
              <li>
                {this.translation(`commonText.dateModified`)}:{" "}
                {note.modifiedDateTime !== null
                  ? formatDate(note.modifiedDateTime, true)
                  : "N/A"}
              </li>
            </ul>
          ),
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  // Method for enabling the download of attachments
  downloadAttachment(id, filename) {
    this.setState({ downloading: true });
    const config = {
      responseType: "blob",
    };

    axios.advisorService
      .get(`documents/` + id + `/` + filename, config)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({ downloading: false });
      })
      .catch((error) => {
        this.errorHandler("Download Attachment", error);
      });
  }

  // Handles the deletion of notes
  deleteNote(noteData) {
    this.modalDeleteTitle = this.translation(`notes.deleteNote`);
    this.modalDeleteText = this.translation(`notes.deleteNoteMessage`);

    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      let storeState = store.getState();
      let accountDetails = storeState.account.accountDetails;

      noteData.modifier = `${accountDetails.lastName}, ${accountDetails.firstName}`;
      noteData.message = { id: noteData.message.id };
      noteData.operationType = "DELETED";

      axios.advisorService
        .deleteNote(`notes/deleteUsingObject`, noteData)
        .then(() => {
          this.toggleModalMessage();
          this.props.refreshAutomaticNotesList();
        })
        .catch((error) => {
          this.errorHandler("Deleting notes", error);
        });
    };
  }

  editDraft(message) {
    this.modalTitle = this.translation(`messageForm.editMessage`);
    this.messageForm = (
      <Message
        whistleblowerCaseId={this.whistleblowerCase.id}
        draftMessage={message}
        updateMessage={this.updateMessage}
        closeModal={this.toggleModalMessageForm}
      />
    );
    this.toggleModalMessageForm();
  }

  deleteDraft(message) {
    this.modalDeleteTitle = this.translation(`messageList.deleteDraft`);
    this.modalDeleteText = this.translation(`messageList.deleteDraftMessage`);
    this.modalDeleteEvent = async () => {
      axios.advisorService
        .delete(`messages/${message.id}`)
        .then(() => {
          this.setTableData();
        })
        .catch((error) => {
          this.errorHandler("Deleting drafts", error);
        });
    };

    this.toggleModalDelete();
  }

  // Methods to run after the CaseNotesAdd modal's operation is done
  onModalFormSubmit(origin) {
    if (origin === "messageNotes") {
      this.props.refreshAutomaticNotesList(origin, "Note Addition/Editing");
      this.toggleModalForm();
      this.toggleModalMessage();
    }
  }

  // Handles the changing of pages
  handlePageChange(e, index) {
    e.preventDefault();
    this.setState({ currentPage: index }, () => {
      this.setTableData();
    });
    window.scrollTo(0, 0);
  }

  // Handles the errors encountered within this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Message Initialization":
        this.mainError = this.translation(`errorMessages.messageListLoadError`);
        break;
      case "Viewing Message Notes":
        this.mainError = this.translation(`errorMessages.noteListLoadError`);
        break;
      case "Downloading attachments":
        this.mainError = this.translation(
          `errorMessages.messageAttachmentDownloadError`
        );
        break;
      case "Creating Message Status":
        this.mainError = this.translation(
          `errorMessages.messageStatusCreationError`
        );
        break;
      case "Deleting notes":
        this.mainError = this.translation(`errorMessages.noteDeleteError`);
        break;
      case "Deleting drafts":
        this.mainError = this.translation(`errorMessages.draftDeletionError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  //
  createMessage() {
    this.modalTitle = this.translation(`messageForm.newMessage`);
    this.messageForm = (
      <Message
        whistleblowerCaseId={this.whistleblowerCase?.id}
        updateMessage={this.updateMessage}
        closeModal={this.toggleModalMessageForm}
      />
    );
    this.toggleModalMessageForm();
  }

  // updateMessage state on submit on the modal
  updateMessage(message, toggleModal = true) {
    let updatedMessages = this.state.messages;
    let index = updatedMessages.findIndex(
      (oldMessage) => oldMessage.id === message.id
    );
    if (index !== -1) {
      updatedMessages[index] = message;
    } else {
      updatedMessages.unshift(message);
    }

    this.setState({ messages: updatedMessages });
    if (toggleModal) {
      this.toggleModalMessageForm();
    }
  }

  // Toggles the Boolean that affects the appearance of the deletion modal.
  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Toggles the Boolean that affects the appearance of CaseNotesAdd modal.
  toggleModalForm() {
    this.setState({
      showCaseNotesAdd: !this.state.showCaseNotesAdd,
    });
  }

  // Toggles the Boolean that affects the appearance of the notes list modal.
  toggleModalMessage() {
    this.setState({
      showModalMessage: !this.state.showModalMessage,
    });
  }

  toggleModalMessageForm() {
    this.setState({
      showModalMessageForm: !this.state.showModalMessageForm,
    });
  }

  /**
   * Render the mark as unread button depending on
   * certain conditions
   * @param {*} parentPage parent page of card
   * @param {*} message message to mark as read or unread
   * @returns rendered HTML button or null
   */
  renderMarkUnreadButton(parentPage, message) {
    let latestStatus = getMessageLatestStatus(message);
    let faIcon =
      latestStatus === messageStatusEnum.READ ? faEnvelope : faEnvelopeOpen;
    let toolTipMessage =
      latestStatus === messageStatusEnum.READ
        ? this.translation(`messageList.markAsUnread`)
        : this.translation(`messageList.markAsRead`);

    if (
      latestStatus === messageStatusEnum.READ &&
      parentPage !== "Message Form" &&
      message.flag === flagEnums.INCOMING
    ) {
      return (
        <>
          <Button
            color="primary"
            id={"markReadUneadButton" + message.id}
            style={{ marginBottom: "5px" }}
            onClick={async () => {
              await this.markAsReadUnread(messageStatusEnum.UNREAD, message);
            }}
          >
            <FontAwesomeIcon icon={faIcon} />
          </Button>
          <UncontrolledTooltip
            placement="right"
            target={"markReadUneadButton" + message.id}
          >
            {toolTipMessage}
          </UncontrolledTooltip>
        </>
      );
    } else {
      return null;
    }
  }

  /**
   * Render the documents row for messages
   * @param {*} message the message whose documents will be shown
   * @param {*} i index
   * @param {*} parentPage parent page of card
   * @returns rendered message documents row
   */
  renderMessageDocuments(message, i, parentPage) {
    return (
      <tr key={`entity-${i}`}>
        {/**
         * Show documents row when:
         * Message is outgoing, or
         * if message is incoming, make sure that its status is READ
         */}
        {parentPage !== "Message Form" &&
        (message.flag === flagEnums.OUTGOING ||
          (message.flag === flagEnums.INCOMING &&
            getMessageLatestStatus(message) === messageStatusEnum.READ)) ? (
          <Table responsive striped className="my-0">
            {!isEmpty(message.documents) ? (
              <tr key={`entity-${i}`}>
                <td
                  style={{
                    borderTop: "none",
                  }}
                >
                  {message.documents.map((docs, i) => (
                    <Button
                      color="primary"
                      onClick={() =>
                        this.downloadAttachment(docs.id, docs.fileName)
                      }
                      style={{ marginLeft: "5px" }}
                    >
                      <FontAwesomeIcon
                        icon={faFileDownload}
                        style={{ marginRight: "15px" }}
                      />
                      {docs.fileName}
                    </Button>
                  ))}
                </td>
              </tr>
            ) : null}
          </Table>
        ) : null}
      </tr>
    );
  }

  /**
   * Renders either the message body or a "Read Message" button
   * depending on conditions
   * @param {*} message message to show
   * @param {*} parentPage parent page of card
   * @returns
   */
  renderMessageBody(message, parentPage) {
    if (
      parentPage !== "Message Form" &&
      (message.flag === flagEnums.OUTGOING ||
        (message.flag === flagEnums.INCOMING &&
          getMessageLatestStatus(message) === messageStatusEnum.READ))
    ) {
      return (
        <td style={{ verticalAlign: "top", width: "70%" }}>
          <div
            align="justify"
            dangerouslySetInnerHTML={{
              __html: draftToHtml(JSON.parse(message.message)),
            }}
          />
        </td>
      );
    } else {
      return (
        <td
          style={{ textAlign: "center", verticalAlign: "middle", width: "70%" }}
        >
          <Button
            color="primary"
            id="readMessageButton"
            onClick={async () => {
              await this.markAsReadUnread(messageStatusEnum.READ, message);
            }}
          >
            {this.translation(`messageList.readMessage`)}
          </Button>
        </td>
      );
    }
  }

  /**
   * Method for marking a message as read or unread
   * @param {*} newStatus new status of message (READ or UNREAD)
   * @param {*} message message to mark as READ or UNREAD
   */
  async markAsReadUnread(newStatus, message) {
    let messageUpdated = await this.setNewStatus(newStatus, message);
    this.updateMessage(messageUpdated, false);
  }

  render() {
    const { parentPage } = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <h1 className="float-left">
              {parentPage === "Message Form"
                ? this.translation(`messageList.previousMessages`)
                : this.translation(`messageList.messageList`)}
            </h1>
          </CardTitle>

          <div className="card-actions float-right">
            {parentPage === "Message Form" ? null : (
              <>
                <Button
                  color="primary"
                  id="newMessageButton"
                  size="m"
                  onClick={this.createMessage}
                >
                  <FontAwesomeIcon icon={faPlusSquare} />
                </Button>
                <UncontrolledTooltip
                  placement="right"
                  target="newMessageButton"
                >
                  {this.translation(`messageList.sendNewMessage`)}
                </UncontrolledTooltip>
              </>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <Row className="justify-content-center">
            {this.state.downloading ? (
              <>
                <LoadingIndicator />
                <h5>{this.translation(`messageList.downloadingAttachment`)}</h5>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </Row>
          <Table style={{ tableLayout: "fixed", width: "100%" }}>
            {!isEmpty(this.state.messages) &&
              this.state.messages?.map((message, i) => (
                <tbody>
                  <tr key={`entity-${i}`}>
                    {this.renderMessageBody(message)}
                    <td
                      style={{
                        width: "30%",
                        verticalAlign: "top",
                        padding: "0",
                        display: "inline",
                      }}
                    >
                      <Table
                        style={{
                          backgroundColor: "#f8f9fa",
                          textAlign: "right",
                        }}
                      >
                        <tr>
                          <td style={{ borderTop: "none" }}>
                            {!isEmpty(message.messageStatuses)
                              ? formatDate(
                                  message.messageStatuses[
                                    message.messageStatuses.length - 1
                                  ].dateTime,
                                  true
                                )
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ borderTop: "none" }}>
                            {!isEmpty(message.messageStatuses)
                              ? this.translation(
                                  `enumTranslation.${message.messageStatuses[
                                    message.messageStatuses.length - 1
                                  ]?.statusOfMessage.toLowerCase()}`
                                )
                              : "-"}
                            {/* 
                              Add who sends the message if there is one on the data
                            */}
                            {!isEmpty(message.messageStatuses) &&
                            message.sender ? (
                              <div>
                                {this.translation(`messageList.by`, {
                                  name: this.getMessageSender(message.sender),
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ borderTop: "none", paddingRight: "0px" }}
                          >
                            <div>
                              {this.renderMarkUnreadButton(parentPage, message)}
                              <span> </span>
                              <Button
                                color="primary"
                                id={"viewNotesButton" + message.id}
                                onClick={() =>
                                  this.viewMessageNotes(message.id)
                                }
                                style={{ marginBottom: "5px" }}
                              >
                                <FontAwesomeIcon icon={faCommentAlt} />
                              </Button>
                            </div>
                            <UncontrolledTooltip
                              placement="right"
                              target={"viewNotesButton" + message.id}
                            >
                              {this.translation(`notes.viewNotes`)}
                            </UncontrolledTooltip>
                            {message.messageStatuses[
                              message.messageStatuses.length - 1
                            ]?.statusOfMessage === messageStatusEnum.DRAFT &&
                              parentPage !== "Message Form" && (
                                <div>
                                  <Button
                                    color="primary"
                                    onClick={this.editDraft.bind(this, message)}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                  <span> </span>
                                  <Button
                                    color="primary"
                                    onClick={this.deleteDraft.bind(
                                      this,
                                      message
                                    )}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </div>
                              )}
                          </td>
                        </tr>
                      </Table>
                    </td>
                  </tr>
                  {this.renderMessageDocuments(message, i, parentPage)}
                </tbody>
              ))}
          </Table>
          <Row>
            <div>
              <br />
            </div>
          </Row>
        </CardBody>
        <ModalDelete
          isOpen={this.state.showModalDelete}
          onClose={this.toggleModalDelete}
          event={this.modalDeleteEvent}
          modalTitle={this.modalDeleteTitle}
          modalBodyText={this.modalDeleteText}
        ></ModalDelete>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalForm
          isOpen={this.state.showCaseNotesAdd}
          eventOnClose={this.toggleModalForm}
          eventOnSubmit={this.onModalFormSubmit}
          ref={this.modalForm}
          modalTitle={this.modalTitle}
        >
          {this.modalForm}
        </ModalForm>
        <ModalForm
          isOpen={this.state.showModalMessageForm}
          eventOnClose={this.toggleModalMessageForm}
          eventOnSubmit={this.toggleModalMessageForm}
          ref={this.messageForm}
          modalTitle={this.modalTitle}
        >
          {this.messageForm}
        </ModalForm>
        <ModalInfo
          isOpen={this.state.showModalMessage}
          toggleModal={this.toggleModalMessage}
          modalTitle={this.modalTitle}
          modalBodyText={this.modalBodyText}
          size="lg"
        ></ModalInfo>
      </Card>
    );
  }
}

export default withRouter(withTranslation()(MessageListCard));
