import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Container,
  Table,
} from "reactstrap";
import axios from "../../../axios/Axios";
import FadeAlert from "../../../components/FadeAlert";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import AuthEnum from "../../../enums/AuthEnum";
import { isEmpty, LoadingIndicator } from "../../../helpers/GenericHelper";
import i18n from "../../../i18n";
import ModalConfirm from "../../components/modal/ModalConfirm";
/**
 * React hook for displaying the page for granting/removing rights
 */
var modalConfirmEvent = () => {};

export default function UserRights() {
  const [users, setUsers] = useState([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [modalBodyText, setModalBodyText] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    axios.account.get("users").then((response) => {
      if (!isEmpty(response)) {
        setUsers(response.data);
      }
    });
  }, []);

  function toggleModalConfirm() {
    setShowModalConfirm(!showModalConfirm);
  }

  function confirmGrantAdminRights(user) {
    modalConfirmEvent = () => grantAdminRights(user);
    setErrorMessage(null);
    setSuccessMessage(null);
    setModalTitle(i18n.t(`auth.grantTitle`));
    setModalBodyText(i18n.t(`auth.grant`));
    toggleModalConfirm();
  }

  function grantAdminRights(user) {
    axios.account
      .put(`users/${user.login}/grant-admin-right`)
      .then(() => {
        user.authorities.push(AuthEnum.ROLE_ADMIN);
        setSuccessMessage(
          i18n.t("auth.successGrantMessage", {
            name: `${user.lastName}, ${user.firstName}`,
          })
        );
      })
      .catch((error) => {
        setErrorMessage(error.message);
        return;
      });
  }

  function confirmEvent() {
    modalConfirmEvent();
  }

  function confirmRemoveAdminRights(user) {
    modalConfirmEvent = () => removeAdminRights(user);
    setErrorMessage(null);
    setSuccessMessage(null);
    setModalTitle(i18n.t(`auth.removeTitle`));
    setModalBodyText(i18n.t(`auth.remove`));
    toggleModalConfirm();
  }

  function removeAdminRights(user) {
    axios.account
      .put(`users/${user.login}/remove-admin-right`)
      .then(() => {
        user.authorities = user.authorities.filter(
          (auth) => auth !== AuthEnum.ROLE_ADMIN
        );
        setSuccessMessage(
          i18n.t("auth.successRemoveMessage", {
            name: `${user.lastName}, ${user.firstName}`,
          })
        );
      })
      .catch((error) => {
        setErrorMessage(error.message);
        return;
      });
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{i18n.t(`routes.assignAdmin`)}</HeaderTitle>
      </Header>

      <Card>
        <LoadingIndicator />
        <CardHeader>
          <CardTitle>
            <h1>{i18n.t(`commonText.users`)}</h1>
          </CardTitle>
        </CardHeader>
        <CardHeader>
          {successMessage && (
            <FadeAlert color="success">{successMessage}</FadeAlert>
          )}

          {errorMessage && <FadeAlert color="error">{errorMessage}</FadeAlert>}
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>{i18n.t(`commonText.name`)}</th>
                <th>{i18n.t(`auth.roles`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                let userHasAdminRights =
                  user.authorities.find(
                    (auth) => auth === AuthEnum.ROLE_ADMIN
                  ) ?? false;
                let userHasUserRights =
                  user.authorities.find(
                    (auth) => auth === AuthEnum.ROLE_USER
                  ) ?? false;

                return (
                  <tr>
                    <td>{user.id}</td>
                    <td>
                      {user.lastName}, {user.firstName}
                    </td>
                    <td>
                      {user.authorities.map((authority, i) => {
                        return i !== user.authorities?.length - 1
                          ? i18n.t(`auth.${authority}`) + ", "
                          : i18n.t(`auth.${authority}`);
                      })}
                    </td>
                    <td>
                      {userHasAdminRights ? (
                        userHasUserRights ? (
                          <>
                            <Button
                              color="primary"
                              onClick={() => confirmRemoveAdminRights(user)}
                              title={i18n.t(`settings.removeRights`)}
                              style={{ width: "38px" }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                alt={i18n.t(`settings.removeRights`)}
                              />
                            </Button>
                          </>
                        ) : null
                      ) : (
                        <>
                          <Button
                            color="primary"
                            onClick={() => confirmGrantAdminRights(user)}
                            title={i18n.t(`settings.addRights`)}
                            style={{ width: "38px" }}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              alt={i18n.t(`settings.removeRights`)}
                            />
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardHeader>
      </Card>

      <ModalConfirm
        isOpen={showModalConfirm}
        onClose={toggleModalConfirm}
        confirmEvent={confirmEvent}
        cancelEvent={toggleModalConfirm}
        modalTitle={modalTitle}
        modalBodyText={modalBodyText}
      ></ModalConfirm>
    </Container>
  );
}
