import {
  faCaretSquareDown,
  faCaretSquareLeft,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import NoteTypeEnum from "../../../enums/NoteTypeEnum";
import {
  formatDate,
  isEmpty,
  sortDateReturnValue,
} from "../../../helpers/GenericHelper";
import { store } from "../../../redux/store/index";
import CaseNotesAdd from "../../advisor-dashboard/case/CaseNotesAdd";
import ModalDelete from "../modal/DeleteModal";
import ModalError from "../modal/ModalError";
import ModalForm from "../modal/ModalForm";

/**
 * Class that contains the card for the notes list in the case details page
 */
class CaseNotesCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      automaticNotes: props.automaticNotes ? props.automaticNotes : [],
      manualNotes: props.manualNotes ? props.manualNotes : [],
      whistleblowerCase: props.whistleblowerCase
        ? props.whistleblowerCase
        : null,
      showCaseNotesAdd: false,
      showModalDelete: false,
      showModalError: false,
      isExpanded: false,
    };
    this.translation = this.props.t;

    this.modalForm = "";
    this.modalDeleteEvent = null;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.toggleModalForm = this.toggleModalForm.bind(this);

    this.showCaseNotesAdd = this.showCaseNotesAdd.bind(this);
    this.onModalFormSubmit = this.onModalFormSubmit.bind(this);
    this.deleteNote = this.deleteNote.bind(this);

    this.errorHandler = this.errorHandler.bind(this);
    this.saveNote = this.saveNote.bind(this);

    this.preparedColumns = [
      {
        type: "data",
        header: this.translation("commonText.note"),
        accessor: "notes",
        show: "true",
        filterkey: "notes",
        showsearch: "true",
      },
      {
        type: "Dropdown",
        header: this.translation(`commonText.action`),
        show: "true",
        columnWidth: "50px",
      },
    ];
    this.systemNotesPreparedColumns = [
      {
        type: "data",
        header: this.translation("commonText.note"),
        accessor: "notes",
        show: "true",
        filterkey: "notes",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation("commonText.dateCreated"),
        accessor: "dateTime",
        show: "true",
        filterkey: "dateTime",
        showsearch: "true",
        alignleft: "true",
        itemSortValueFunc: sortDateReturnValue,
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        manualNotes: this.props.manualNotes,
        automaticNotes: this.props.automaticNotes,
      });
    }
  }

  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  toggleModalForm() {
    this.setState({
      showCaseNotesAdd: !this.state.showCaseNotesAdd,
    });
  }

  showCaseNotesAdd(notesData) {
    this.modalForm = (
      <CaseNotesAdd
        whistleblowerCaseId={this.state.whistleblowerCase?.id}
        messageId={null}
        notesData={notesData}
      />
    );

    this.toggleModalForm();
  }

  onModalFormSubmit(origin) {
    this.props.refreshNotesList(origin, "Note Addition/Editing");
    this.toggleModalForm();
  }

  deleteNote(noteData, origin) {
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      let storeState = store.getState();
      let accountDetails = storeState.account.accountDetails;

      noteData.modifier = `${accountDetails.lastName}, ${accountDetails.firstName}`;
      noteData.whistleblowercase = { id: noteData.whistleblowercase.id };
      noteData.operationType = "DELETED";

      axios.advisorService
        .deleteNote(`notes/deleteUsingObject`, noteData)
        .then(() => {
          this.props.refreshNotesList(origin, "Note Regeneration");
        })
        .catch((error) => {
          this.errorHandler("Note Deletion", error);
        });
    };
  }

  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Note Deletion":
        this.mainError = this.translation(`errorMessages.noteDeleteError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  prepareTableData = (notes) => {
    if (Array.isArray(notes) && notes.length > 0) {
      let newTableData = [];

      notes.forEach((note) => {
        let entry = {
          id: note.id,
          notes: note.notes,
          dateTime: formatDate(note.dateTime, true),
          modifiedDateTime:
            note.modifiedDateTime !== null
              ? formatDate(note.modifiedDateTime, true)
              : "N/A",
          creator: note.creator,
          modifier: note.modifier !== null ? note.modifier : "N/A",
          note: note,
          popover: true,
          popoverData: (
            <ul className="p-3 m-0">
              <li>
                {this.translation(`commonText.createdBy`)}: {note.creator}
              </li>
              <li>
                {this.translation(`commonText.lastModifiedBy`)}:{" "}
                {note.modifier !== null ? note.modifier : "N/A"}
              </li>
              <li>
                {this.translation(`commonText.dateLastModified`)}:{" "}
                {note.modifiedDateTime !== null
                  ? formatDate(note.modifiedDateTime, true)
                  : "N/A"}
              </li>
            </ul>
          ),
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  // Method for saving notes to either a case or a message
  async saveNote(event, values) {
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;

    let newNote = {
      notes: values.noteMessage,
      creator: `${accountDetails.lastName}, ${accountDetails.firstName}`,
      operationType: "CREATED",
      noteType: NoteTypeEnum.MANUAL,
      whistleblowercase: { id: this.props.whistleblowerCase.id },
      message: null,
    };
    axios.advisorService
      .post(`notes`, newNote)
      .then((response) => {
        if (!isEmpty(response.data)) {
          let updatedManualNotes = this.state.manualNotes;
          updatedManualNotes.unshift(response.data);
          this.setState({ manualNotes: updatedManualNotes });
          this.form.reset();
        }
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });
  }

  render() {
    const { automaticNotes, manualNotes } = this.state;

    return (
      <Card>
        <CardHeader>
          <h1>
            {this.translation("commonText.userNotes")}{" "}
            {Array.isArray(manualNotes) ? "(" + manualNotes.length + ")" : null}
          </h1>
          <div className="float-right" style={{ marginTop: "-2.5rem" }}>
            <Button
              color="primary"
              id="notesToggle"
              onClick={() =>
                this.setState({ isExpanded: !this.state.isExpanded })
              }
            >
              {this.state.isExpanded ? (
                <FontAwesomeIcon icon={faCaretSquareDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretSquareLeft} />
              )}
            </Button>
            <UncontrolledTooltip target="notesToggle" placement="left">
              {!this.state.isExpanded ? this.translation(`commonButtonTexts.expand`) : this.translation(`commonButtonTexts.collapse`)}
            </UncontrolledTooltip>
          </div>
        </CardHeader>
        <UncontrolledCollapse toggler="#notesToggle">
          <CardBody>
            <AvForm
              className="row"
              onValidSubmit={this.saveNote}
              ref={(c) => {
                this.form = c;
              }}
              inline
            >
              <Col sm={10}>
                <AvField
                  name="noteMessage"
                  type="textarea"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: this.translation(
                        `fieldWarnings.fieldIsRequired`
                      ),
                    },
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col sm={2} className="align-self-revert">
                <FormGroup className="float-right">
                  <Button color="primary" id="save-notes">
                    <FontAwesomeIcon icon={faSave} />
                  </Button>
                  <UncontrolledTooltip target="save-notes" placement="left">
                    {this.translation(`commonButtonTexts.save`)}
                  </UncontrolledTooltip>
                </FormGroup>
              </Col>
            </AvForm>
            {Array.isArray(manualNotes) &&
              (manualNotes.length > 0 ? (
                <DynamicTable
                  data={this.prepareTableData(manualNotes)}
                  columns={this.preparedColumns}
                  updateAction={this.showCaseNotesAdd}
                  infiniteScroll
                  caseNotesAction={this.deleteNote}
                  dropdown={{
                    actions: [
                      {
                        label: this.translation(`commonButtonTexts.edit`),
                        actionFunc: "update",
                        actionProps: "note",
                      },
                      {
                        label: this.translation(`commonButtonTexts.delete`),
                        actionFunc: "caseNotes",
                        actionProps: "note",
                      },
                    ],
                  }}
                />
              ) : (
                <React.Fragment>
                  <hr />
                  <div className="text-center">
                    <Label>{this.translation(`notes.noNotes`)}</Label>
                  </div>
                </React.Fragment>
              ))}
          </CardBody>
        </UncontrolledCollapse>
        <br />
        {Array.isArray(automaticNotes) &&
          (automaticNotes.length > 0 ? (
            <div>
              <CardHeader>
                <CardTitle>
                  <h1>{this.translation("commonText.systemNotes")}</h1>
                </CardTitle>
                <div className="float-right" style={{ marginTop: "-2rem" }}>
                  <Button
                    color="primary"
                    id="logToggle"
                    style={{ transform: "translateY(-12px)" }}
                    onClick={() =>
                      this.setState({ isExpanded: !this.state.isExpanded })
                    }
                  >
                    {this.state.isExpanded ? (
                      <FontAwesomeIcon icon={faCaretSquareDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretSquareLeft} />
                    )}
                  </Button>
                  <UncontrolledTooltip target="logToggle" placement="left">
                    {!this.state.isExpanded ? this.translation(`commonButtonTexts.expand`) : this.translation(`commonButtonTexts.collapse`)}
                  </UncontrolledTooltip>
                </div>
              </CardHeader>
              <UncontrolledCollapse toggler="#logToggle">
                <CardBody>
                  <DynamicTable
                    data={this.prepareTableData(automaticNotes)}
                    columns={this.systemNotesPreparedColumns}
                    infiniteScroll
                  />
                </CardBody>
              </UncontrolledCollapse>
            </div>
          ) : null)}
        <ModalDelete
          isOpen={this.state.showModalDelete}
          onClose={this.toggleModalDelete}
          event={this.modalDeleteEvent}
          modalTitle={this.translation("notes.deleteNote")}
          modalBodyText={this.translation("notes.deleteNoteMessage")}
        ></ModalDelete>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalForm
          isOpen={this.state.showCaseNotesAdd}
          eventOnClose={this.toggleModalForm}
          eventOnSubmit={this.onModalFormSubmit}
          ref={this.modalForm}
          modalTitle={""}
        >
          {this.modalForm}
        </ModalForm>
      </Card>
    );
  }
}

CaseNotesCard.propTypes = {
  automaticNotes: PropTypes.object.isRequired,
  manualNotes: PropTypes.object.isRequired,
  refreshNotesList: PropTypes.func.isRequired,
  whistleblowerCase: PropTypes.object.isRequired,
};

export default withTranslation()(CaseNotesCard);
