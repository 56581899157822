import React, { useState } from "react";
import { Alert } from "reactstrap";

/*
 * Allows a fading alert banner to appear on top of a card after certain operations.
 */
const FadeAlert = (props) => {
  const { children, fadeOutTime = 60000 } = props;

  const [isOpen, setIsOpen] = useState(true);

  const fadeOut = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, fadeOutTime);
  };

  return (
    <Alert {...props} isOpen={isOpen} onEnter={fadeOut()}>
      {children}
    </Alert>
  );
};

export default FadeAlert;
