import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import RoleEnum from "../enums/RoleEnums";
import { default as Auth, default as AuthLayout } from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import Page404 from "../pages/auth/Page404";
import { store } from "../redux/store/index";
import { dashboard as dashboardRoutes } from "./index";
import Page401 from "../pages/auth/Page401";

const childRoutes = (Layout, routes) =>
  routes.map(
    (
      { children, path, component: Component, isProtected = false, roles = [] },
      index
    ) =>
      children ? (
        // Route item with children
        children.map(({ path, component: Component }, index) =>
          isProtected ||
            roles.some((role) =>
              store.getState().account.accountDetails?.authorities?.includes(role)
            ) ? (
            <ProtectedRoute
              isProtected={isProtected}
              path={path}
              key={index}
              roles={roles}
              exact
              component={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          ) : (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          )
        )
      ) : // Route item without children
        isProtected ||
          store
            .getState()
            .account.accountDetails?.authorities?.includes(roles) ? (
          <ProtectedRoute
            path={path}
            key={index}
            roles={roles}
            exact
            component={(props) => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        ) : (
          <Route
            key={index}
            path={path}
            exact
            render={(props) => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        )
  );

const Routes = (authenticated) => (
  authenticated.authenticated ? (<Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(DashboardLayout, dashboardRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>) : (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            render={() => (
              <AuthLayout>
                <Page401 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  )

);

const ProtectedRoute = ({
  name: Name,
  component: Component,
  roles,
  ...props
}) => (
  <Route
    {...props}
    render={(props) =>
      (roles.length === 0) ||
        (roles.some((role) =>
          store.getState().account.accountDetails?.authorities?.includes(role)
        )) ? (
        <Component {...props} />
      ) : store
        .getState()
        .account.accountDetails?.authorities?.includes(RoleEnum.ADMIN) &&
        window.location.pathname === "/" ? (
        window.location.replace("/admin/clients")
      ) : (
        <Auth children={<Page404 />} />
      )
    }
  />
);

export default Routes;
