import React from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

/**
 * Class for the form that assigns an advisor to a case
 */
class AssignAdvisorToCaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advisorOptions: [],
      currentAdvisors: [],
      whistleblowercase: null,
      showModalError: false,
    };
    this.translation = this.props.t;
  }

  render() {
    return (
      <Select
        closeMenuOnSelect={false}
        options={this.props.options}
        onChange={this.props.onChange}
        placeholder={this.translation("commonText.select")}
        isMulti={true}
        value={this.props.value}
        noOptionsMessage={() => this.translation("commonText.noOptions")}
      />
    );
  }
}

export default withTranslation()(AssignAdvisorToCaseForm);
