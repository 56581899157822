import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import i18n from "../../../i18n";

// Modal used to confirm a choice
// A method to be run upon confirmation and a method to be run upon cancellation
// must be passed to the Modal as props
const ModalConfirm = (props) => {
  const {
    modalTitle,
    modalBodyText,
    isOpen,
    onClose,
    cancelEvent,
    confirmEvent,
  } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    onClose && onClose(e);
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  var confirm = (
    <Button color="dark" onClick={() => continueConfirm()}>
      {i18n.t(`commonButtonTexts.yes`)}
    </Button>
  );

  const continueConfirm = () => {
    confirmEvent();
    toggle();
  };

  var cancel = (
    <Button color="dark" onClick={() => continueCancel()}>
      {i18n.t(`commonButtonTexts.no`)}
    </Button>
  );

  const continueCancel = () => {
    cancelEvent();
    toggle();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <div>
            {
              typeof(modalBodyText) === "string" ? modalBodyText :
              modalBodyText.map(text => {
                return <p>{text}</p>
              })
            }
          </div>
        </ModalBody>
        <ModalFooter style={{ "border-top": "none" }}>
          {confirm}
          {cancel}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalConfirm;

ModalConfirm.prototype = {
  modalTitle: PropTypes.string.isRequired,
  modalBodyText: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
};