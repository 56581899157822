import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Message from "../../../components/Message";
import MessageListCard from "../../components/MessageList/MessageListCard";
import { isEmpty } from "../../../helpers/GenericHelper";

/*
 * Class that displays the component used for sending new messages
 * This also contains the MessageList component, which allows users to view past messages
 */
class MessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whistleblowerCase: this.props.location.state
        ? this.props.location.state.whistleblowerCase
        : null,
      draftMessage: this.props.location.state
      ? this.props.location.state.draftMessage
      : null,
    };
    this.translation = this.props.t;

    this.id = this.props.match.params.id;
  }

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.translation(`messageForm.newMessage`)}
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/client-list">
                {this.translation(`adminClientList.clientList`)}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/client/${this.state.whistleblowerCase?.client?.id}`}>
                {this.state.whistleblowerCase?.client?.name}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/case/${this.state.whistleblowerCase?.id}`}>
                {this.state.whistleblowerCase.title}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{this.translation(`messageForm.newMessage`)}</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Message whistleblowerCaseId={this.state.whistleblowerCase?.id} draftMessage={this.state.draftMessage}/>
        {!isEmpty(this.state.whistleblowerCase) && <MessageListCard
          whistleblowerCase={this.state.whistleblowerCase}
          parentPage={"Message Form"}
        />}
      </Container>
    );
  }
}

export default withTranslation()(MessageForm);
