import React from "react";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CaseGroupColorEnum from "../../../enums/CaseGroupColorEnum";
import _ from "lodash";
import i18n from "../../../i18n";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      dataset: {},
    };
    this.prepareData = this.prepareData.bind(this);
  }

  componentDidMount() {
    this.prepareData();
  }

  prepareData() {
    let dataObject = { ...this.props.dataObject };

    // remove total
    delete dataObject.total;

    let backgroundColor = [];
    for (let key of Object.keys(dataObject)) {
      if (key.toUpperCase() in CaseGroupColorEnum) {
        backgroundColor.push(CaseGroupColorEnum[key.toUpperCase()]);
      } else {
        backgroundColor.push("#808080");
      }
    }

    dataObject = _.transform(dataObject, function (result, val, key) {
      result[i18n.t(`caseGroup.${key.toUpperCase()}`)] = val;
    });

    this.setState({
      labels: Object.keys(dataObject),
      dataset: {
        backgroundColor: backgroundColor,
        data: Object.values(dataObject),
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dataObject &&
      prevProps.dataObject !== this.props.dataObject
    ) {
      this.prepareData();
    }
  }

  render() {
    const { labels, dataset } = this.state;
    return (
      <div
        id={this.props.id}
        className="chart-container"
        style={{ height: "400px", width: "70%" }}
      >
        <Pie
          data={{
            labels: labels,
            datasets: [dataset],
          }}
          options={{
            title: {
              text: this.props.title,
              display: true,
              fontSize: 15,
            },
            plugins: {
              legend: {
                display: true,
                labels: {
                  font: {
                    size: 14,
                    family: "GT-Walsheim-Pro",
                  },
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              animateRotate: false, // Disable rotation animation causing the bug in pdf
              animateScale: false, // Disable scale animation
            },
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(PieChart);

/**
 * data object sample
 * {
 *  MONEYLAUNDERING: 0,
 *  CORRUPTION: 2
 * }
 */
PieChart.propTypes = {
  title: PropTypes.string,
  dataObject: PropTypes.object,
};
