import {
  faCaretSquareDown,
  faCaretSquareLeft,
  faEdit,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  PopoverBody,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import { formatDate, isEmpty } from "../../../helpers/GenericHelper";
import ModalDelete from "../modal/DeleteModal";
import ModalForm from "../modal/ModalForm";
import ClientNotesForm from "./ClientNotesForm";

/**
 * Component for displaying client notes
 */
class ClientNotesCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableDatas: [],
      areNotesExpanded: false,
      showCaseNotesAdd: false,
      showModalDelete: false,
    };

    this.modalForm = null;
    this.modalTitle = "";
    this.modalDeleteEvent = null;

    this.translation = this.props.t;
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalForm = this.toggleModalForm.bind(this);
    this.saveNote = this.saveNote.bind(this);
  }

  toggleModalForm() {
    this.setState({
      showCaseNotesAdd: !this.state.showCaseNotesAdd,
    });
  }

  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  deleteNote(note) {
    this.modalDeleteEvent = () =>
      axios.advisorService
        .delete(`client-notes/${note.id}`)
        .then(() => {
          this.props.removeNoteFromState(note);
        })
        .catch((error) => {
          this.props.errorHandler(null, error);
        });

    this.toggleModalDelete();
  }

  showCaseNotesAdd(notesData) {
    this.modalForm = (
      <ClientNotesForm
        notesData={notesData}
        notesChange={this.props.notesChange}
        clientId={this.props.client.id}
        errorHandler={this.props.errorHandler}
      />
    );
    this.modalTitle =
      notesData !== null
        ? this.translation(`notes.editNote`)
        : this.translation(`notes.addNote`);
    this.toggleModalForm();
  }

  async saveNote(event, values) {
    let newNote = {
      notes: values.noteMessage,
      client: { id: this.props.client.id },
    };

    axios.advisorService
      .post(`client-notes`, newNote)
      .then((response) => {
        this.props.notesChange(response.data);
        this.form.reset();
      })
      .catch((error) => {
        this.props.errorHandler(null, error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardHeader>
            <CardTitle>
              <h1 className="float-left">
                {this.translation("commonText.notes")}{" "}
                {Array.isArray(this.props.notes)
                  ? "(" + this.props.notes.length + ")"
                  : null}
              </h1>
            </CardTitle>
            <div className="float-right">
              <Button
                color="primary"
                id="notesToggle"
                onClick={() =>
                  this.setState({
                    areNotesExpanded: !this.state.areNotesExpanded,
                  })
                }
              >
                {this.state.areNotesExpanded ? (
                  <FontAwesomeIcon icon={faCaretSquareDown} />
                ) : (
                  <FontAwesomeIcon icon={faCaretSquareLeft} />
                )}
              </Button>
              <UncontrolledTooltip target="notesToggle" placement="left">
                {!this.state.areNotesExpanded ? this.translation(`commonButtonTexts.expand`) : this.translation(`commonButtonTexts.collapse`)}
              </UncontrolledTooltip>
            </div>
          </CardHeader>
          <UncontrolledCollapse toggler="#notesToggle">
            <CardBody>
              {!isEmpty(this.props.notes) ? (
                <Table>
                  <tbody>
                    {this.props.notes.map((note) => {
                      return (
                        <React.Fragment>
                          <tr>
                            <td
                              id={"popover-" + note.id}
                              style={{
                                paddingRight: "0px",
                              }}
                            >
                              {note.notes}
                              <div className="float-right">
                                <React.Fragment>
                                  <Button
                                    color="primary"
                                    className="mr-1"
                                    id="editNote"
                                    onClick={() => this.showCaseNotesAdd(note)}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                  <Button
                                    color="primary"
                                    id="deleteNote"
                                    onClick={() => this.deleteNote(note)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>

                                  <UncontrolledTooltip target="editNote">
                                    {this.translation(`commonButtonTexts.edit`)}
                                  </UncontrolledTooltip>
                                  <UncontrolledTooltip target="deleteNote">
                                    {this.translation(
                                      `commonButtonTexts.delete`
                                    )}
                                  </UncontrolledTooltip>
                                </React.Fragment>
                              </div>
                            </td>
                          </tr>
                          <UncontrolledPopover
                            placement="auto-start"
                            trigger="hover"
                            target={"popover-" + note.id}
                          >
                            <PopoverBody>
                              <ul className="p-3 m-0">
                                <li>
                                  {this.translation(`commonText.createdBy`)}:{" "}
                                  {note.createdBy}
                                </li>
                                <li>
                                  {this.translation(
                                    `commonText.lastModifiedBy`
                                  )}
                                  :{" "}
                                  {note.lastModifiedBy !== null
                                    ? note.lastModifiedBy
                                    : "N/A"}
                                </li>
                                <li>
                                  {this.translation(
                                    `commonText.dateLastModified`
                                  )}
                                  :{" "}
                                  {note.dateModified !== null
                                    ? formatDate(note.dateModified, true)
                                    : "N/A"}
                                </li>
                              </ul>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <React.Fragment>
                  <hr />
                  <div className="text-center">
                    <Label>{this.translation(`notes.noNotes`)}</Label>
                  </div>
                </React.Fragment>
              )}

              <Row>
                <Col>
                  <AvForm
                    onValidSubmit={this.saveNote}
                    ref={(c) => {
                      this.form = c;
                    }}
                    inline
                  >
                    <div className="col-sm-10">
                      <AvField
                        name="noteMessage"
                        label={this.translation(`commonText.note`)}
                        type="textarea"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: this.translation(
                              `fieldWarnings.fieldIsRequired`
                            ),
                          },
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div
                      className="col-sm-2 align-self-revert"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="float-right">
                        <Button color="primary">
                          <FontAwesomeIcon icon={faSave} />
                        </Button>
                      </div>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </CardBody>
          </UncontrolledCollapse>
        </Card>
        <ModalForm
          isOpen={this.state.showCaseNotesAdd}
          eventOnClose={this.toggleModalForm}
          eventOnSubmit={this.toggleModalForm}
          ref={this.modalForm}
          modalTitle={this.modalTitle}
        >
          {this.modalForm}
        </ModalForm>
        <ModalDelete
          isOpen={this.state.showModalDelete}
          onClose={this.toggleModalDelete}
          event={this.modalDeleteEvent}
          modalTitle={this.translation("notes.deleteNote")}
          modalBodyText={this.translation("notes.deleteNoteMessage")}
        ></ModalDelete>
      </React.Fragment>
    );
  }
}

ClientNotesCard.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  notesChange: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  removeNoteFromState: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
};

export default withTranslation()(ClientNotesCard);
