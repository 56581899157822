import {
  faChartLine,
  faCog,
  faDesktop,
  faHome,
  faSignInAlt,
  faUser,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import RoleEnum from "../enums/RoleEnums";
import i18n from "../i18n";
// Advisors
import AdminAdvisorList from "../pages/admin-pages/advisor/AdminAdvisorList";
import AdminClientAddAdvisor from "../pages/admin-pages/client/AdminClientAddAdvisor";
import AdminClientDetail from "../pages/admin-pages/client/AdminClientDetail";
// Client
import AdminClientList from "../pages/admin-pages/client/AdminClientList";
import UserRights from "../pages/admin-pages/settings/UserRights";
// Statistics
import StatisticsView from "../pages/admin-pages/statistics/StatisticsView";
// Case
import CaseDetail from "../pages/advisor-dashboard/case/CaseDetail";
import ClientDetail from "../pages/advisor-dashboard/client/ClientDetail";
import CreateCaseDetail from "../pages/advisor-dashboard/client/CreateCaseDetail";
// Client
import ClientList from "../pages/advisor-dashboard/client/ClientList";
// Home
import Home from "../pages/advisor-dashboard/Home";
// Message
import MessageForm from "../pages/advisor-dashboard/message/MessageForm";

import SignOut from "../pages/auth/SignOut";
import AssignAdvisorToCaseForm from "../pages/components/AssignAdvisorToCaseForm/AssignAdvisorToCaseForm";

const AdminStatisticsRoute = {
  path: "/admin/statistics",
  name: i18n.t(`statistics.statisticsView`),
  icon: faChartLine,
  roles: [RoleEnum.ADMIN],
};

const AdminStatisticsPrivateRoutes = {
  path: "/admin/statistics",
  name: "Statistics",
  isProtected: true,
  roles: [RoleEnum.ADMIN],
  children: [
    {
      path: "/admin/statistics/",
      component: StatisticsView,
    },
  ],
};

const AdminClientRoute = {
  path: "/admin/clients",
  name: i18n.t(`routes.clientSetup`),
  header: i18n.t(`routes.configuration`),
  icon: faUserTie,
  isProtected: true,
  roles: [RoleEnum.ADMIN],
  children: [
    {
      path: "/admin/clients",
      component: AdminClientList,
      name: i18n.t(`commonText.clients`),
    },
  ],
};

const AdminSettingsRoute = {
  path: "/admin/settings",
  name: i18n.t(`routes.settings`),
  icon: faCog,
  isProtected: true,
  roles: [RoleEnum.ADMIN],
  children: [
    {
      path: "/admin/settings/rights",
      component: UserRights,
      name: i18n.t(`commonText.rights`),
    },
  ],
};

const AdminAdvisorRoute = {
  path: "/admin/advisors",
  name: i18n.t(`routes.advisorSetup`),
  icon: faUser,
  isProtected: true,
  roles: [RoleEnum.ADMIN],
  children: [
    {
      path: "/admin/advisors",
      component: AdminAdvisorList,
      name: i18n.t(`commonText.advisors`),
    },
    // {
    //   path: "/admin/advisors/add",
    //   component: AdminAdvisorAdd,
    //   name: i18n.t(`adminAdvisorAdd.addAdvisor`),
    // },
  ],
};

const AdminPrivateClientRoute = {
  path: "/admin",
  name: "Client",
  header: "Main",
  icon: faUser,
  isProtected: true,
  roles: [RoleEnum.ADMIN],
  children: [
    {
      path: "/admin/clients/:id",
      component: AdminClientDetail,
      name: "Admin Client Detail",
    },
    {
      path: "/admin/clients/:id/add-advisor",
      component: AdminClientAddAdvisor,
      name: "Client Add Advisor",
    },
  ],
};

// Routes
const dashboardRoutes = {
  path: "/",
  name: i18n.t("routes.home"),
  header: "Main",
  icon: faHome,
  component: Home,
  roles: [RoleEnum.USER],
  children: null,
  isProtected: true,
};

const clientRoutes = {
  path: "/clients",
  name: i18n.t(`commonText.client`),
  icon: faDesktop,
  isProtected: true,
  roles: [RoleEnum.USER],
  children: [
    {
      path: "/client-list",
      component: ClientList,
      name: i18n.t(`adminClientList.clientList`),
    },
  ],
};

const clientPrivateRoute = {
  path: "/client-list",
  name: "Client",
  isProtected: true,
  roles: [RoleEnum.USER],
  children: [
    {
      path: "/client/:id",
      component: ClientDetail,
      name: "Client Detail",
    },
    {
      path: "/client-create-case/:id",
      component: CreateCaseDetail,
      name: "Client New Case",
    },
    {
      path: "/case/:id",
      component: CaseDetail,
      name: "Case Detail",
    },
    {
      path: "/case/:id/assign-advisor",
      component: AssignAdvisorToCaseForm,
      name: "Add Advisor to case",
    },
    {
      path: "/case/:id/new-message",
      component: MessageForm,
      name: "New Message",
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: i18n.t(`routes.account`),
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/sign-out",
      name: i18n.t(`routes.signOut`),
      component: SignOut,
    },
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  clientRoutes,
  clientPrivateRoute,
  AdminClientRoute,
  AdminPrivateClientRoute,
  AdminAdvisorRoute,
  AdminStatisticsPrivateRoutes,
  authRoutes,
  AdminSettingsRoute,
];

// All routes
export const defaultRoutes = [
  authRoutes,
  dashboardRoutes,
  clientRoutes,
  AdminClientRoute,
  AdminAdvisorRoute,
  AdminSettingsRoute,
  AdminStatisticsRoute,
];

export default defaultRoutes;
