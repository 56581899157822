import {
  faCaretSquareDown,
  faCaretSquareLeft,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import FileSaver from "file-saver";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import CaseStatusEnum from "../../../enums/CaseStatusEnum";
import RoleEnums from "../../../enums/RoleEnums";
import { Default, Descending, timeoutValue } from "../../../helpers/Constants";
import {
  deadlineStyleIdentificationByDate,
  formatDate,
  getNextSortType,
  sortData,
  sortDataByDate,
  sortDateReturnValue,
} from "../../../helpers/GenericHelper";
import i18n from "../../../i18n";
import { store } from "../../../redux/store/index";
import ModalError from "../../components/modal/ModalError";
import ModalForm from "../../components/modal/ModalForm";
import ModalInfo from "../../components/modal/ModalInfo";
const defaultFilterWidth = "100px";
const defaultWidth = "120px";

/*
 * Class the generates the list of cases for a client within the client detail page
 */
class CaseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      showModalDelete: false,
      showModalError: false,
      showModalNotes: false,
      key: "",
      casesToDisplay: [],
      isView: false,

      //Filter
      filters: {},
      title: null,
      id: null,
      currentDeadline: null,
      dateCreated: null,
      dateModified: null,
      state: [],
      projectStates: [],

      sortBy: null,
      sortType: null,
    };
    this.timeout = 0;
    this.filterTimeOut = 0;

    this.translation = this.props.t;

    this.client = this.props.client;
    this.itemsPerPage = 20;

    this.modalDeleteEvent = null;

    this.modalBodyText = "";
    this.modalTitle = "";
    this.modalForm = null;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.toggleModalInfo = this.toggleModalInfo.bind(this);
    this.viewNotesHistory = this.viewNotesHistory.bind(this);
    this.deleteCase = this.deleteCase.bind(this);
    this.deleteMessageNotes = this.deleteMessageNotes.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.exportCase = this.exportCase.bind(this);
    this.keyOnChange = this.keyOnChange.bind(this);
  }

  caseListPreparedColumns(cases) {
    return [
      {
        type: "data",
        header: i18n.t(`commonText.id`),
        accessor: "id",
        show: "true",
        filterValue: this.state.id,
        filterFunc: (event) => this.handleIdFilterChange(event, cases),
        filterComponentWidth: defaultFilterWidth,
        width: defaultWidth,
        sortFunc: ((event) => this.sortData(event, cases)) ?? null,
      },
      {
        type: "data",
        header: i18n.t(`caseDetail.caseName`),
        accessor: "title",
        show: "true",
        filterkey: "title",
        showsearch: "true",
        link: this.props.isAdmin ? undefined : `/case/`,
        linkAccessor: "id",
        filterFunc: (event) => this.handleTitleFilterChange(event, cases),
        filterValue: this.state.title,
        filterComponentWidth: defaultFilterWidth,
        sortFunc: (event) => this.sortData(event, cases) ?? null,
      },
      {
        type: "data",
        header: this.translation(`commonText.caseStatus`),
        accessor: "caseStatus",
        show: "true",
        filterkey: "caseStatus",
        showsearch: "true",
      },
      {
        type: "data",
        header: i18n.t(`commonText.dateCreated`),
        accessor: "dateCreated",
        show: "true",
        filterkey: "dateCreated",
        showsearch: "true",
        filterType: "date",
        filterFunc: (event) => this.handleCreatedFilterChange(event, cases),
        filterComponentWidth: "180px",
        width: "200px",
        sortFunc: (event) => this.sortData(event, cases) ?? null,
        itemSortValueFunc: sortDateReturnValue,
        itemDisplayValueFunc: this.displayDate,
      },
      {
        type: "data",
        header: i18n.t(`commonText.dateModified`),
        accessor: "dateModified",
        show: this.props.isAdmin ? "false" : "true",
        filterkey: "dateModified",
        showsearch: "true",
        filterType: "date",
        filterFunc: (event) => this.handleModifiedFilterChange(event, cases),
        filterComponentWidth: "180px",
        width: "200px",
        sortFunc: (event) => this.sortData(event, cases) ?? null,
        itemSortValueFunc: sortDateReturnValue,
        itemDisplayValueFunc: this.displayDate,
      },
      {
        type: "data",
        header: i18n.t(`commonText.deadline`),
        accessor: "currentDeadline",
        show: this.props.isAdmin ? "false" : "true",
        filterkey: "currentDeadline",
        showsearch: "true",
        alignleft: "true",
        filterType: "date",
        filterFunc: (event) => this.handleDeadlineFilterChange(event, cases),
        filterComponentWidth: "180px",
        width: "200px",
        sortFunc: (event) => this.sortData(event, cases) ?? null,
        itemSortValueFunc: sortDateReturnValue,
        itemDisplayValueFunc: this.displayDeadlineDate,
      },
      {
        type: "Dropdown",
        header: i18n.t(`commonText.menu`),
        show: this.props.isAdmin ? "true" : "false",
      },
    ];
  }

  onFilterUpdate = (cases) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      let newFilters = {
        title: this.state.title,
        id: this.state.id,
        currentDeadline: this.state.currentDeadline,
        dateModified: this.state.dateModified,
        dateCreated: this.state.dateCreated,
      };
      this.setState({ filters: newFilters });

      this.filterData(cases);
    }, timeoutValue);
  };

  filterData = (cases) => {
    let filter = this.state.filters;
    let filteredCases = [];
    var startDate, endDate;

    // Filters out by the title
    if (filter?.title) {
      filteredCases = cases.filter((obj) => {
        return obj["title"].includes(filter.title);
      });
      this.setState({ state: filteredCases });
    }

    // Filters out by the id
    if (filter?.id) {
      filteredCases = cases.filter((obj) => {
        var newObj = obj["id"] + "";
        return newObj.includes(filter.id);
      });
      this.setState({ state: filteredCases });
    }

    // Filters out by the current deadline
    if (filter?.currentDeadline) {
      startDate = new Date(
        moment(filter.currentDeadline).startOf("day")
      ).toISOString();
      endDate = new Date(
        moment(filter.currentDeadline).endOf("day")
      ).toISOString();

      filteredCases = cases.filter((obj) => {
        // Filter this dates by startDate and endDate
        return (
          obj["currentDeadline"] >= startDate &&
          obj["currentDeadline"] <= endDate
        );
      });
      this.setState({ state: filteredCases });
    }

    // Filters out by the date created
    if (filter?.dateCreated) {
      startDate = new Date(
        moment(filter.dateCreated).startOf("day")
      ).toISOString();
      endDate = new Date(moment(filter.dateCreated).endOf("day")).toISOString();

      filteredCases = cases.filter((obj) => {
        // Filter this dates by startDate and endDate
        return obj["dateCreated"] >= startDate && obj["dateCreated"] <= endDate;
      });
      this.setState({ state: filteredCases });
    }

    // Filters out by the date modified
    if (filter?.dateModified) {
      startDate = new Date(
        moment(filter.dateModified).startOf("day")
      ).toISOString();
      endDate = new Date(
        moment(filter.dateModified).endOf("day")
      ).toISOString();

      filteredCases = cases.filter((obj) => {
        // Filter this dates by startDate and endDate
        return (
          obj["dateModified"] >= startDate && obj["dateModified"] <= endDate
        );
      });
      this.setState({ state: filteredCases });
    }

    if (this.checkProperties(filter)) {
      this.setState({ state: [] });
    }
  };

  checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  }

  // Handling the id filter
  handleIdFilterChange = (event, clientId) => {
    event.preventDefault();
    this.setState({ id: event?.target?.value ?? null }, () =>
      this.onFilterUpdate(clientId)
    );
  };
  // Handling the title filter
  handleTitleFilterChange = (event, clientId) => {
    event.preventDefault();
    this.setState({ title: event?.target?.value ?? null }, () =>
      this.onFilterUpdate(clientId)
    );
  };

  // Handling the deadline filter
  handleDeadlineFilterChange = (event, clientId) => {
    event.preventDefault();
    this.setState({ currentDeadline: event?.target?.value ?? null }, () =>
      this.onFilterUpdate(clientId)
    );
  };
  // Handling the date modified filter
  handleModifiedFilterChange = (event, clientId) => {
    event.preventDefault();
    this.setState({ dateModified: event?.target?.value ?? null }, () =>
      this.onFilterUpdate(clientId)
    );
  };
  // Handling the date created filter
  handleCreatedFilterChange = (event, clientId) => {
    event.preventDefault();
    this.setState({ dateCreated: event?.target?.value ?? null }, () =>
      this.onFilterUpdate(clientId)
    );
  };

  sortData = (sortBy, cases) => {
    var currentSortBy = this.state.sortBy;
    var sortedCases = [];

    let nextSortType = getNextSortType(
      this.state.sortType,
      sortBy,
      currentSortBy
    );

    this.setState({
      sortType: nextSortType === Default ? Descending : nextSortType,
      sortBy: nextSortType === Default ? "" : sortBy,
    }, () => {
      this.filterTimeOut = setTimeout(() => {
        if (
          this.state.sortBy ===
          ("currentDeadline" || "dateCreated" || "dateModified") ||
          this.state.sortBy === ""
        ) {
          sortedCases = sortDataByDate(
            cases,
            this.state.sortBy === "" ? "dateCreated" : this.state.sortBy,
            this.state.sortType
          );
        } else {
          sortedCases = sortData(cases, this.state.sortBy, this.state.sortType);
        }
  
        this.setState({ state: sortedCases });
      }, timeoutValue);
    });   
  };

  displayDeadlineDate(date) {
    return (
      <span className={deadlineStyleIdentificationByDate(date, true)}>
        {formatDate(date)}
      </span>
    );
  }
  displayDate(date) {
    return formatDate(date, true);
  }
  // Toggles the Boolean that affects appearance of modal delete dialog box
  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Shows the modal containing case/message notes history
  toggleModalInfo() {
    this.setState({
      showModalNotes: !this.state.showModalNotes,
    });
  }

  componentDidMount() {
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;

    if (accountDetails.authorities.includes(RoleEnums.ADMIN)) {
      this.setState({
        isAdmin: true,
      });
    }
  }

  // View notes history
  async viewNotesHistory(whistleblowercase, notesToView) {
    let notesData = [];

    if (notesToView === "caseNotes") {
      notesData = await axios.advisorService
        .get(`notes?whistleblowercaseId.equals=${whistleblowercase.id}`)
        .then((response) => {
          return response.data.reverse();
        })
        .catch((error) => {
          this.errorHandler("Note History Retrieval", error);
        });
    } else {
      let axiosCommands = [];
      let promiseData = [];

      let messagesList = await axios.advisorService
        .get(`messages?whistleblowercaseId.equals=${whistleblowercase.id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorHandler("Note History Retrieval", error);
        });

      messagesList.forEach((messages) => {
        axiosCommands.push(
          axios.advisorService.get(`notes?messageId.equals=${messages.id}`)
        );
      });
      promiseData = await Promise.all(axiosCommands)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.errorHandler("Note History Retrieval", error);
        });

      promiseData.forEach((data) => {
        notesData.push(data.data.reverse());
      });

      notesData = [].concat.apply([], notesData);
    }

    const notesHistoryPreparedColumns = [
      {
        type: "data",
        header: i18n.t("commonText.note"),
        accessor: "notes",
        show: "true",
        filterkey: "notes",
        showsearch: "true",
      },
      {
        type: "data",
        header: i18n.t("commonText.dateCreated"),
        accessor: "dateTime",
        show: "true",
        filterkey: "dateTime",
        showsearch: "true",
        itemSortValueFunc: sortDateReturnValue,
      },
      {
        type: "data",
        header: i18n.t("commonText.dateModified"),
        accessor: "modifiedDateTime",
        show: "true",
        filterkey: "modifiedDateTime",
        showsearch: "true",
        itemSortValueFunc: sortDateReturnValue,
      },
      {
        type: "data",
        header: i18n.t("commonText.wasValidUntil"),
        accessor: "validUntil",
        show: "true",
        filterkey: "validUntil",
        showsearch: "true",
      },
      {
        type: "data",
        header: i18n.t("commonText.creator"),
        accessor: "creator",
        show: "true",
        filterkey: "creator",
        showsearch: "true",
      },
      {
        type: "data",
        header: i18n.t("commonText.modifier"),
        accessor: "modifier",
        show: "true",
        filterkey: "modifier",
        showsearch: "true",
      },
      {
        type: "data",
        header: i18n.t("commonText.operationType"),
        accessor: "operationType",
        show: "true",
        filterkey: "operationType",
        showsearch: "true",
      },
      {
        type: "data",
        header: i18n.t("commonText.fromMessageWithSubject"),
        accessor: "messageSubject",
        show: notesToView === "messageNotes" ? "true" : "false",
        filterkey: "messageSubject",
        showsearch: "true",
        alignleft: "true",
      },
    ];

    this.modalBodyText = (
      <div>
        <h2>
          {notesToView === "caseNotes"
            ? i18n.t(`commonText.caseNotesHistory`)
            : i18n.t(`commonText.messageNotesHistory`)}
        </h2>
        {Array.isArray(notesData) &&
          (notesData.length > 0 ? (
            <DynamicTable
              data={this.prepareNotesHistoryTableData(notesData)}
              columns={notesHistoryPreparedColumns}
              infiniteScroll
              removeMenuPortalTarget={true}
            />
          ) : (
            <div className="text-center">
              <Label>{this.translation(`notes.noNotes`)}</Label>
            </div>
          ))}
      </div>
    );

    this.toggleModalInfo();
  }

  keyOnChange(e) {
    this.setState({ key: e.target.value });
  }

  // Deletes the case
  deleteCase(whistleblowercase) {
    if (
      whistleblowercase.caseStatuses[whistleblowercase.caseStatuses.length - 1]
        ?.statusOfCase === CaseStatusEnum.CLOSED
    ) {
      this.toggleModalDelete();
      this.modalDeleteEvent = async () => {
        axios.advisorService
          .delete(
            `whistleblowercases?clientId=${this.client.id}&whistleblowercaseId=${whistleblowercase?.id}&key=${this.state.key}`
          )
          .then(() => {
            let updatedCases = this.client.whistleblowercases.filter(
              (clientCase) => clientCase.id !== whistleblowercase.id
            );
            this.client.whistleblowercases = updatedCases;
            this.props.updateClientState(this.client);
            this.toggleModalDelete();

            this.modalTitle = "";
            this.modalBodyText = this.translation(`caseDetail.deletedCase`);
            this.toggleModalInfo();
          })
          .catch((error) => {
            this.errorHandler("Delete Case", error);
          });
      };

      this.modalForm = (
        <AvForm onValidSubmit={this.modalDeleteEvent}>
          <AvGroup>
            <AvField
              name="Key"
              type="text"
              label={this.translation(`commonText.key`)}
              validate={{
                required: {
                  value: true,
                  errorMessage: this.translation(
                    `fieldWarnings.fieldIsRequired`
                  ),
                },
              }}
              value={this.state.key}
              onChange={this.keyOnChange}
            />
          </AvGroup>
          <Button className="float-right" color="primary">
            <FontAwesomeIcon
              icon={faFileAlt}
              style={{ marginRight: "0.25rem" }}
            />
            {this.translation(`commonButtonTexts.submit`)}
          </Button>
        </AvForm>
      );
    } else {
      this.errorHandler("Non-Closed Case Deletion");
    }
  }

  // Deletes message notes (since, at the moment, it's hard to implement deletion of this in backend)
  async deleteMessageNotes(whistleblowercase) {
    await whistleblowercase.messages.forEach((message) => {
      axios.advisorService
        .delete(`notes/deleteManyNotes?messageId.equals=${message?.id}`)
        .catch((error) => {
          this.errorHandler("Delete Message Notes", error);
        });
    });
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Note History Retrieval":
        this.mainError = i18n.t(`errorMessages.noteHistoryLoadError`);
        break;
      case "Setting Table Data":
        this.mainError = i18n.t(`errorMessages.caseListLoadError`);
        break;
      case "Delete Case":
        this.mainError = error.response?.data?.detail
          ? error.response.data.detail
          : i18n.t(`errorMessages.caseDeletionError`);
        this.errorReason = i18n.t(`errorMessages.accessForbidden`);
        break;
      case "Link Deletion Error":
        this.mainError = this.translation(`errorMessages.linkDeletionError`);
        break;
      case "Delete Message Notes":
        this.mainError = this.translation(
          `errorMessages.messageNotesDeletionError`
        );
        break;
      case "Non-Closed Case Deletion":
        this.mainError = this.translation(`errorMessages.nonClosedCaseError`);
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
      default:
        this.mainError = i18n.t(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    if (!this.errorReason) {
      this.errorReason = i18n.t(`errorMessages.failedToCommunicate`);
    }
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Prepares the case list table data
  prepareCaseListTableData = (cases) => {
    if (Array.isArray(cases) && cases.length > 0) {
      let newTableData = [];

      cases.forEach((individualCase) => {
        let entry = {
          id: individualCase.id,
          title: individualCase.title,
          caseStatus: individualCase.caseStatuses
            ? this.translation(
              `enumTranslation.${individualCase.caseStatuses[
                individualCase.caseStatuses.length - 1
              ]?.statusOfCase.toLowerCase()}`
            )
            : null,
          caseStatusDeadlineDetection: individualCase.caseStatuses
            ? individualCase.caseStatuses[
              individualCase.caseStatuses.length - 1
            ]?.statusOfCase
            : null,
          dateModified: individualCase.dateModified,
          currentDeadline: individualCase.currentDeadline,
          messages: individualCase.messages,
          whistleblowercase: individualCase,
          dateCreated: individualCase.dateCreated,
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  prepareNotesHistoryTableData = (notes) => {
    if (Array.isArray(notes) && notes.length > 0) {
      let newTableData = [];

      notes.forEach((note) => {
        let entry = {
          notes: note.notes,
          dateTime: formatDate(note.dateTime, true),
          modifiedDateTime:
            note.modifiedDateTime !== null
              ? formatDate(note.modifiedDateTime, true)
              : "N/A",
          validUntil:
            note.validUntil !== null
              ? formatDate(note.validUntil, true)
              : "N/A",
          creator: note.creator,
          modifier: note.modifier !== null ? note.modifier : "N/A",
          operationType: i18n.t(
            `commonText.${note.operationType.toLowerCase()}`
          ),
          messageSubject: note.message?.subject,
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  async exportCase(whistleblowerCase) {
    axios.advisorService
      .get(`export-documents/${whistleblowerCase?.id}/${i18n.language}`, {
        responseType: "blob",
        responseEncoding: null,
      })
      .then((response) => {
        FileSaver(response.data, `Case ${whistleblowerCase.title}.zip`);

        // toggle success message
        this.modalBodyText = i18n.t(`caseDetail.exportedCaseSuccess`);
        this.toggleModalInfo();
      });
  }

  defaultDateObjectAddition(whistleblowerCase, questionId) {
    let answerObject = {
      id: null,
      questionId: questionId,
      answer: null,
      whistleblowercase: { id: whistleblowerCase.id },
      order: null,
    };

    switch (questionId) {
      case 0:
        answerObject.answer = formatDate(
          whistleblowerCase.caseStatuses[0].dateTime,
          true
        );
        break;
      case 87:
        answerObject.answer = formatDate(moment());
        break;
      case 89:
        answerObject.answer = formatDate(moment().add(14, "days"));
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <Card className="client-accordion" style={{ marginBottom: "1rem" }}>
        <CardHeader>
          <CardTitle>
            <h1>
              {i18n.t(`commonText.cases`)}
              <Button
                className="float-right"
                color="primary"
                id={`toggler-${this.client.id}`}
                onClick={() => this.setState({ isView: !this.state.isView })}
              >
                {this.state.isView ? (
                  <FontAwesomeIcon icon={faCaretSquareLeft} />
                ) : (
                  <FontAwesomeIcon icon={faCaretSquareDown} />
                )}
              </Button>
              <UncontrolledTooltip
                target={`toggler-${this.client.id}`}
                placement="top"
              >
                {this.state.isView ? this.translation(`commonButtonTexts.expand`) : this.translation(`commonButtonTexts.collapse`)}
              </UncontrolledTooltip>
            </h1>
          </CardTitle>
        </CardHeader>

        <UncontrolledCollapse
          defaultOpen={true}
          toggler={`#toggler-${this.client.id}`}
        >
          <CardBody>
            {Array.isArray(this.client.whistleblowercases) &&
              (this.client.whistleblowercases.length > 0 ? (
                <DynamicTable
                  data={
                    this.state.state.length > 0
                      ? this.prepareCaseListTableData(this.state.state)
                      : sortDataByDate(
                        this.prepareCaseListTableData(
                          this.client.whistleblowercases
                        ),
                        "dateCreated",
                        "desc"
                      )
                  }
                  columns={this.caseListPreparedColumns(
                    this.client.whistleblowercases
                  )}
                  deleteAction={this.deleteCase}
                  caseNotesAction={this.viewNotesHistory}
                  messageNotesAction={this.viewNotesHistory}
                  exportCaseAction={this.exportCase}
                  isAdmin={this.props.isAdmin}
                  sortBy={this.state.sortBy}
                  sortType={this.state.sortType}
                  infiniteScroll
                  showDeadline={true}
                  dropdown={
                    this.props.isAdmin
                      ? {
                        actions: [
                          {
                            label: i18n.t(`commonText.caseNotesHistory`),
                            actionFunc: "caseNotes",
                            actionProps: "whistleblowercase",
                          },
                          {
                            label: i18n.t(`commonText.messageNotesHistory`),
                            actionFunc: "messageNotes",
                            actionProps: "whistleblowercase",
                          },
                          {
                            label: i18n.t(`commonText.export`),
                            actionFunc: "exportCase",
                            actionProps: "whistleblowercase",
                          },
                          {
                            label: i18n.t(`commonButtonTexts.delete`),
                            actionFunc: "delete",
                            actionProps: "whistleblowercase",
                          },
                        ],
                      }
                      : undefined
                  }
                />
              ) : (
                <div className="text-center">
                  <Label>{this.translation(`caseDetail.noCases`)}</Label>
                </div>
              ))}
          </CardBody>{" "}
        </UncontrolledCollapse>
        <ModalForm
          isOpen={this.state.showModalDelete}
          eventOnClose={this.toggleModalDelete}
          eventOnSubmit={this.toggleModalDelete}
          ref={this.modalForm}
          modalTitle={this.translation(`adminClientDetail.deleteCaseTitle`)}
        >
          {this.modalForm}
        </ModalForm>

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalInfo
          isOpen={this.state.showModalNotes}
          toggleModal={this.toggleModalInfo}
          modalTitle={this.modalTitle}
          modalBodyText={this.modalBodyText}
        ></ModalInfo>
      </Card>
    );
  }
}

export default withTranslation()(CaseCard);

CaseCard.defaultProps = {
  isAdmin: false,
};

CaseCard.propTypes = {
  client: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  updateClientState: PropTypes.func,
};
