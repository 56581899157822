import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import axios from "../../../axios/Axios";
import { LoadingIndicator } from "../../../helpers/GenericHelper";

/*
 * Class for allowing notes to be added to a client
 */
class ClientNotesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: this.props.notesData !== null ? this.props.notesData.notes : "",
    };
    this.translation = this.props.t;

    this.toggleModalError = this.toggleModalError.bind(this);
    this.saveNote = this.saveNote.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Method for saving notes to either a case or a message
  async saveNote(event) {
    event.preventDefault();

    // Create new note if there is no notes data passed as props
    if (this.props.notesData === null) {
      let newNote = {
        notes: this.state.notes,
        client: { id: this.props.clientId },
      };

      axios.advisorService
        .post(`client-notes`, newNote)
        .then((response) => {
          this.props.notesChange(response.data);
          this.props.externalOnSubmitEvent();
        })
        .catch((error) => {
          this.props.errorHandler(null, error);
        });
    } else {
      // Updating the note passed as props
      let existingNote = this.props.notesData;
      existingNote.notes = this.state.notes;

      axios.advisorService
        .put(`client-notes/${existingNote.id}`, existingNote)
        .then(() => {
          this.props.notesChange(existingNote);
          this.props.externalOnSubmitEvent();
        })
        .catch((error) => {
          this.props.errorHandler(null, error);
        });
    }
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    if (currentOperation === "Saving Notes") {
      this.mainError = this.translation(`errorMessages.noteSaveError`);
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Handles the changing of the notes text area
  handleNoteChange(e) {
    this.setState({ notes: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <LoadingIndicator />
        <AvForm onValidSubmit={this.saveNote}>
          <AvGroup>
            <AvField
              name="noteName"
              label={this.translation(`commonText.note`)}
              type="textarea"
              autoFocus={true}
              validate={{
                required: {
                  value: true,
                  errorMessage: this.translation(
                    `fieldWarnings.fieldIsRequired`
                  ),
                },
              }}
              value={
                this.props.notesData !== null
                  ? this.props.notesData.notes
                  : null
              }
              onChange={this.handleNoteChange}
            />
            <div className="float-right mt-3">
              <Button color="primary">
                <FontAwesomeIcon icon={faEdit} />
                {this.props.notesData !== null
                  ? this.translation(`notes.editNote`)
                  : this.translation(`notes.addNote`)}
              </Button>
            </div>
          </AvGroup>
        </AvForm>
      </React.Fragment>
    );
  }
}

ClientNotesForm.propTypes = {
  notesData: PropTypes.object,
  notesChange: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  errorHandler: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(ClientNotesForm));
