import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

/*
 * A Modal that will show a children component(React.Fragment) in a modal dialog
 */
const ModalForm = (props) => {
  const { modalTitle, eventOnSubmit, eventOnClose, isOpen, children } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    eventOnClose && eventOnClose(e);
    setModal(!modal);
  };

  // this function runs on props change
  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  return (
    <div>
      <Modal size={props.size} isOpen={modal} toggle={toggle} autoFocus={false}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          {React.cloneElement(children ?? <React.Fragment></React.Fragment>, {
            externalOnSubmitEvent: eventOnSubmit,
          })}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalForm;
