import { trackPromise } from "react-promise-tracker";
import axios from "./Axios-interceptor";

const BaseUrl = "/";
const advisorService = "api/"

const axiosMethods = {
    account: {
        get(url) {
            return trackPromise(
                axios.get(BaseUrl + "api/" + url)
            );
        },
        post(url, data, config) {
            return trackPromise(
                axios.post(BaseUrl + "api/" + url, data, config)
            );
        },
        put(url, data) {
            return trackPromise(
                axios.put(BaseUrl + "api/" + url, data)
            );
        },
        delete(url) {
            return trackPromise(
                axios.delete(BaseUrl + "api/" + url)
            );
        }
    },
    advisorService: {
        get(url, config) {
            return trackPromise(
                axios.get(BaseUrl + advisorService + url, config)
            );
        },
        post(url, data, config) {
            return trackPromise(
                axios.post(BaseUrl + advisorService + url, data, config)
            );
        },
        put(url, data) {
            return trackPromise(
                axios.put(BaseUrl + advisorService + url, data)
            );
        },
        delete(url) {
            return trackPromise(
                axios.delete(BaseUrl + advisorService + url)
            );
        },
        deleteNote(url, data) {
            return trackPromise(
                axios.put(BaseUrl + advisorService + url, data)
            );
        }
    },
    keepAlive: {
        get(url) {
            return trackPromise(
                axios.get(BaseUrl + advisorService + url)
            );
        },
    },
    all(...requests) {
        return axios.all(...requests);
    }
}

export default axiosMethods;