import * as types from "../constants";
import { REHYDRATE } from 'redux-persist';

const initialState = {
  accountDetails: {
    authorities:[],
    advisor:{
      id: null
    },
    companyDetails: null
  },
};

//reducer for account
//handles all the changes based on the type of action
export default function reducer(state = initialState, actions) {
  let updatedState = Object.assign({}, state);
  switch (actions.type) {
    case REHYDRATE:
      updatedState.accountDetails = actions.payload?.account?.accountDetails
      return updatedState;
    case types.SET_ACCOUNT_DETAILS:
      updatedState.accountDetails = actions.accountDetails;
      return updatedState;
    case types.RESET_ACCOUNT_DETAILS:
      updatedState.accountDetails = initialState;
      return updatedState;
    default:
      return state;
  }
}
