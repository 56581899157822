import React, { useEffect } from "react";
import { Container } from "reactstrap";
import AuthLanguage from "../components/AuthLanguage";
import Footer from "../components/Footer";
import Main from "../components/Main";

const OVERFLOW_HIDDEN = "overflow-hidden";

function Auth(props){

  useEffect(() => {
    document.body.classList.add(OVERFLOW_HIDDEN);

    return () => { document.body.classList.remove(OVERFLOW_HIDDEN); }
  });

  return(
    <React.Fragment>
      <div className="diagonal-line"></div>
      <AuthLanguage />
      <Main>
        <Container
          className="h-100"
          style={{ width: "100%", maxWidth: "100%", padding: "15px" }}
        >
          {props.children}
        </Container>
      </Main>
      <div className="footer-auth">
        <Footer />
      </div>
    </React.Fragment>
  );
  }

export default Auth;
