// SessionStorage.js
const ClientSessionStorageService = (function(){ var _service; function _getService() {
    if(!_service) {
        _service = this;
        return _service
    }   return _service
  } 
  function _setToken(tokenObj) {
    sessionStorage.setItem('client-authenticationtoken', tokenObj);
  } 
  function _getAccessToken() {
    if(sessionStorage.getItem('client-authenticationtoken')){
      return "Bearer " + sessionStorage.getItem('client-authenticationtoken');
    }
    return null
  }
    function _clearToken() {
      sessionStorage.removeItem('client-authenticationtoken');
  }return {
    getService : _getService,
    setToken : _setToken,
    getAccessToken : _getAccessToken,
    clearToken : _clearToken
  }})();
  
  export default ClientSessionStorageService;