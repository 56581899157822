// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_RIGHT_ENABLE = "SIDEBAR_RIGHT_ENABLE";
export const SIDEBAR_RIGHT_DISABLE = "SIDEBAR_RIGHT_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Account
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS"
export const RESET_ACCOUNT_DETAILS = "RESET_ACCOUNT_DETAILS"

export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";

export const SET_LOGO = "SET_LOGO";