import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import theme from "./themeReducer";
import account from "./accountReducers";
import logo from "./logoReducers";
import company from "./companyReducer";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  theme,
  account,
  logo,
  toastr,
  company,
});
