import * as types from "../constants";

// Contains the actions for react-redux
export function setAccountDetails(accountDetails) {
  return {
    type: types.SET_ACCOUNT_DETAILS,
    accountDetails: accountDetails,
  };
}

export function resetAccountDetails() {
  return {
    type: types.RESET_ACCOUNT_DETAILS
  };
}

export function SetCompanyDetails(details) {
  return {
    type: types.SET_COMPANY_DETAILS,
    companyDetails: details,
  };
}