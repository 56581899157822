import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Label } from "reactstrap";
import axios from "../../../axios/Axios";
import { LoadingIndicator } from "../../../helpers/GenericHelper";
import ModalError from "../../../pages/components/modal/ModalError";
/**
 * Class component for sending messages
 */
class UpdateCaseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      uploadedImages: [],
      attachedFiles: [],
      draftDocumentObjects: [],
      case: {},
      errorMessage: "",
      latestMetaInformation: this.props.latestMetaInformation,
      latestMetaInformationNew: { ...this.props.latestMetaInformation },
      showModalDelete: false,
      showModalError: false,
    };
    this.translation = this.props.t;
    this.keepAlive = 0;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.modalDeleteEvent = null;

    this.saveMetaInformation = this.saveMetaInformation.bind(this);
  }


  componentDidMount() {
    //Ensures the session won't timeout when modal is open
    this.keepAlive = setInterval(() => {
      axios.keepAlive.get('keep-alive')
        .then(() => { })
        .catch(() => { })
    }, 300000);
  }

  componentWillUnmount() {
    // Disable keep alive upon component unmount
    clearInterval(this.keepAlive);
    this.keepAlive = 0;
  }

  saveMetaInformation() {
    // It will only save if the Item is different from the original
    let updatedCase = this.props.whistleblowerCase;
    if (
      JSON.stringify(this.state.latestMetaInformation) !==
      JSON.stringify(this.state.latestMetaInformationNew) &&
      updatedCase?.whistleblowerId === 0
    ) {
      let updatedMetaInformations = this.state.latestMetaInformationNew;
      delete updatedMetaInformations.id;
      updatedMetaInformations.dateTime = new moment().toISOString();
      updatedCase.metaInformations.push(updatedMetaInformations);
      axios.advisorService
        .post(
          `whistleblowercases/metadata/` + updatedCase.id,
          updatedMetaInformations
        )
        .then(() => {
          this.props.updateMetaInformation(
            updatedCase.metaInformations,
            this.state.latestMetaInformationNew
          );
        });
    } else {
      this.props.toggleModalForm();
    }
  }

  handleDataChange(category, event) {
    let editedMetaData = this.state.latestMetaInformationNew;
    editedMetaData[category] = event.target.value;
    this.setState({ latestMetaInformationNew: editedMetaData });
  }

  // If nothing changed, just close the modal.

  render() {
    const latestMetaInformation = this.state.latestMetaInformation;
    return (
      <Card>
        <LoadingIndicator />
        <CardBody>
          <AvForm onValidSubmit={this.saveMetaInformation}>
            <AvGroup>
              <Label>{this.translation(`metaInformation.contactData`)}</Label>
              <AvField
                rows={3}
                name="contactData"
                type="textarea"
                value={latestMetaInformation.contactData}
                onChange={(event) =>
                  this.handleDataChange("contactData", event)
                }
              />
            </AvGroup>

            <AvGroup>
              <Label>{this.translation(`metaInformation.violation`)}</Label>
              <AvField
                rows={3}
                name="violation"
                type="textarea"
                value={latestMetaInformation.violation}
                onChange={(event) => this.handleDataChange("violation", event)}
              />
            </AvGroup>

            <AvGroup>
              <Label>
                {this.translation(`metaInformation.occurrenceTime`)}
              </Label>
              <AvField
                rows={3}
                name="occurrenceTime"
                type="textarea"
                value={latestMetaInformation.occurenceTime}
                onChange={(event) =>
                  this.handleDataChange("occurenceTime", event)
                }
              />
            </AvGroup>

            <AvGroup>
              <Label>{this.translation(`metaInformation.location`)}</Label>
              <AvField
                rows={3}
                name="location"
                type="textarea"
                value={latestMetaInformation.location}
                onChange={(event) => this.handleDataChange("location", event)}
              />
            </AvGroup>

            <AvGroup>
              <Label>
                {this.translation(`metaInformation.involvedPeople`)}
              </Label>
              <AvField
                rows={3}
                name="involvedPeople"
                type="textarea"
                value={latestMetaInformation.involvedPeople}
                onChange={(event) =>
                  this.handleDataChange("involvedPeople", event)
                }
              />
            </AvGroup>

            <AvGroup>
              <Label>{this.translation(`metaInformation.witness`)}</Label>
              <AvField
                rows={3}
                name="witness"
                type="textarea"
                value={latestMetaInformation.witness}
                onChange={(event) => this.handleDataChange("witness", event)}
              />
            </AvGroup>

            <AvGroup>
              <Label>{this.translation(`metaInformation.expectation-3`)}</Label>
              <AvField
                rows={3}
                name="expectations"
                type="textarea"
                value={latestMetaInformation.expectations}
                onChange={(event) =>
                  this.handleDataChange("expectations", event)
                }
              />
            </AvGroup>

            <div className="float-right mt-3">
              <Button color="primary" size="lg" id="submit">
                {<FontAwesomeIcon icon={faSave} />}
                {" " + this.translation(`commonButtonTexts.save`)}
              </Button>{" "}
            </div>
          </AvForm>
          <ModalError
            isOpen={this.state.showModalError}
            onClose={this.toggleModalError}
            mainError={this.mainError}
            errorReason={this.errorReason}
            errorResponse={this.errorResponse}
            modalTitle="Error"
          />
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(withTranslation()(UpdateCaseDetail));
