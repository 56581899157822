import {
  faPaperPlane,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import moment from "moment";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "../axios/Axios";
import flagEnums from "../enums/FlagEnums";
import messageStatusEnum from "../enums/MessageStatusEnum";
import {
  LoadingIndicator,
  isEmpty,
  removeByAttr,
} from "../helpers/GenericHelper";
import DeleteModal from "../pages/components/modal/DeleteModal";
import ModalError from "../pages/components/modal/ModalError";
import DynamicTable from "./DynamicTable";
import FadeAlert from "./FadeAlert";
/**
 * Class component for sending messages
 */
class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      uploadedImages: [],
      attachedFiles: [],
      draftDocumentObjects: [],
      editorState: EditorState.createEmpty(),
      case: {},
      errorMessage: "",

      showModalDelete: false,
      showModalError: false,
    };
    this.translation = this.props.t;
    this.whistleblowerCaseId = this.props.whistleblowerCaseId;
    this.keepAlive = 0;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.modalDeleteEvent = null;

    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    this.saveMessage = this.saveMessage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.attachFiles = this.attachFiles.bind(this);
    this.setupDraft = this.setupDraft.bind(this);
    this.deleteDraftDocument = this.deleteDraftDocument.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal delete dialog box
  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Handles the errors located on this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Message Component Setup":
        this.mainError = this.translation(
          `errorMessages.messageComponentSetupError`
        );
        break;
      case "Saving/Sending Message":
        this.mainError = this.translation(`errorMessages.messageSaveSendError`);
        break;
      case "File Upload":
        this.mainError = this.translation(
          `errorMessages.messageAttachmentUploadError`
        );
        break;
      case "Creating Message Status":
        this.mainError = this.translation(
          `errorMessages.messageStatusCreationError`
        );
        break;
      case "Remove Document from Draft":
        this.mainError = this.translation(`errorMessages.removeDocumentError`);
        break;
      default:
        break;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error?.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  componentDidMount() {
    axios.advisorService
      .get(`whistleblowercases/${this.props.match.params.id}`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({
            case: response.data,
            subject: response.data.title,
          });
        }
      })
      .catch((error) => {
        this.errorHandler("Message Component Setup", error);
      });

    if (this.props.draftMessage !== undefined) {
      this.setupDraft();
    }

    //Ensures the session won't timeout when modal is open
    this.keepAlive = setInterval(() => {
      axios.keepAlive.get('keep-alive')
        .then(() => { })
        .catch(() => { })
    }, 300000);
  }

  componentWillUnmount() {
    // Disable keep alive upon component unmount
    clearInterval(this.keepAlive);
    this.keepAlive = 0;
  }

  setupDraft() {
    const DBEditorState = convertFromRaw(
      JSON.parse(this.props.draftMessage?.message)
    );

    this.setState({
      subject: this.props.draftMessage?.subject,
      editorState: EditorState.createWithContent(DBEditorState),
      draftDocumentObjects: this.props.draftMessage?.documents,
    });
  }

  uploadImageCallBack(file) {
    let uploadedImages = this.state.uploadedImages;
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };
    uploadedImages.push(imageObject);
    this.setState({ uploadedImages: uploadedImages });
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  }

  async saveMessage(isDraft = false) {
    var status = !isDraft ? "Not Draft" : "Draft";

    const blocks = convertToRaw(this.state.editorState.getCurrentContent());

    // convert blocks to JSON
    let messageJson = JSON.stringify(blocks);

    // api call to save jsonData
    let message = {
      id: this.props.draftMessage ? this.props.draftMessage.id : null,
      message: messageJson,
      subject: this.state.subject,
      flag: flagEnums.OUTGOING,
      whistleblowercase: {
        id: this.state.case.id,
        clientWhistleblowerCaseId:
          this.state.case.clientWhistleblowerCaseId ?? null,
        client: { id: this.state.case.client.id },
      },
    };

    if (message.id === null) {
      message = await axios.advisorService
        .post(`messages?status=${status}`, message)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorHandler("Saving/Sending Message", error);
        });
    } else {
      message = await axios.advisorService
        .put(`messages?status=${status}`, message)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorHandler("Saving/Sending Message", error);
        });
    }

    // save all attached file
    let documents = [];
    for (const file of this.state.attachedFiles) {
      let fileName = file.name;
      let blob = await this.toBase64(file);
      let document = {
        fileName: fileName,
        file: blob.split(",")[1],
        fileContentType: file.type,
        message: {
          id: message.id,
        },
      };

      await axios.advisorService
        .post("documents", document)
        .then((response) => {
          documents.push(response.data);
        })
        .catch((error) => {
          this.errorHandler("File Upload", error);
        });
    }

    // Loads the files into the front end, this is cosmetic only as it already exists in the back end.
    for (const document of this.state.draftDocumentObjects) {
      document.message = { id: message.id };
      documents.push(document);
    }

    // create status for message either draft or sent depending on parameter;
    let messageStatus = {
      statusOfMessage: isDraft
        ? messageStatusEnum.DRAFT
        : messageStatusEnum.SENT,
      dateTime: moment().toDate().toISOString(),
      message: { id: message.id },
    };

    messageStatus = await axios.advisorService
      .post(`message-statuses`, messageStatus)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Creating Message Status", error);
      });

    if (this.props.updateMessage) {
      message.messageStatuses = [messageStatus];
      message.documents = documents;
      this.props.updateMessage(message);
    }
  }

  onSubmit(event) {
    event.preventDefault();
    this.saveMessage(false);
  }

  cancelMessage() {
    if (this.props.closeModal) {
      this.props.closeModal();
    } else {
      this.props.history.push(`/case/${this.whistleblowerCaseId}`);
    }
  }

  attachFiles(e) {
    let attachedFiles = [];
    let maxFileSize = 104857600; // 100 MB max limit
    let filesOverMaxFileSize = false;

    e.target.files.forEach((file) => {
      // check file size of each file
      if (file.size > maxFileSize) {
        filesOverMaxFileSize = true;
        this.setState({
          errorMessage: this.translation(`errorMessages.maximumFileSizeLimit`, {
            fileName: file.name,
          }),
        });
        return;
      }
      attachedFiles.push(file);
    });

    // clear uploaded files
    if (filesOverMaxFileSize) {
      e.target.value = "";
      e.target.files = null;
      return;
    }

    this.setState({ attachedFiles: attachedFiles });
  }

  saveAsDraft() {
    this.saveMessage(true);
  }

  deleteDraftDocument(document) {
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      axios.advisorService
        .delete(`documents/${document.id}`)
        .then(() => {
          let draftDocumentObjects = this.state.draftDocumentObjects;

          draftDocumentObjects = removeByAttr(
            draftDocumentObjects,
            "id",
            document.id
          );

          this.setState({
            draftDocumentObjects: draftDocumentObjects,
          });
        })
        .catch((error) => {
          this.errorHandler("Remove Document from Draft", error);
        });
    };
  }

  prepareTableData = (docs) => {
    if (Array.isArray(docs) && docs.length > 0) {
      let newTableData = [];

      docs.forEach((doc) => {
        let entry = {
          fileName: doc.fileName,
          doc: doc,
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  removeFile(file) {
    let { attachedFiles } = this.state;
    attachedFiles = attachedFiles.filter(
      (attachedFile) => attachedFile !== file
    );

    this.setState({ attachedFiles: attachedFiles });
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  render() {
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`messageComponent.draftDocument`),
        accessor: "fileName",
        show: "true",
        filterkey: "fileName",
        showsearch: "true",
      },
      {
        type: "Dropdown",
        header: this.translation(`commonText.menu`),
        show: "true",
      },
    ];
    return (
      <Card>
        {this.state.errorMessage && (
          <FadeAlert color="danger">{this.state.errorMessage}</FadeAlert>
        )}
        <LoadingIndicator />
        <CardBody>
          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <Label>{this.translation(`commonText.message`)}</Label>
              <Editor
                editorClassName="form-control"
                onEditorStateChange={this.onEditorStateChange}
                editorState={this.state.editorState}
                editorStyle={{
                  fontSize: "10pt",
                  fontFamily: "GT-Walsheim-Pro, Arial,Helvetica,sans-serif",
                  minHeight: 100,
                }} // bug on ordered list font size
                toolbar={{
                  options: [
                    "inline",
                    "list",
                    "textAlign",
                    "fontSize",
                    "fontFamily",
                    // "image",
                  ],
                  inline: {
                    options: [
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "superscript",
                      "subscript",
                    ],
                  },
                  fontSize: {
                    options: [
                      "8pt",
                      "9pt",
                      "10pt",
                      "11pt",
                      "12pt",
                      "14pt",
                      "16pt",
                      "18pt",
                      "24pt",
                      "30pt",
                      "36pt",
                      "48pt",
                      "60pt",
                      "72pt",
                      "96pt",
                    ],
                  },
                  fontFamily: {
                    options: [
                      "GT-Walsheim-Pro",
                      "Arial",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                    ],
                  },
                  // disabled for the meantime as accessing the location of image doesnt work
                  // image: {
                  //   urlEnabled: false,
                  //   uploadEnabled: true,
                  //   alignmentEnabled: true,
                  //   previewImage: true,
                  //   inputAccept: "image/gif,image/jpeg,image/jpg,image/png",
                  //   alt: { present: false, mandatory: false },
                  //   uploadCallback: this.uploadImageCallBack,
                  //   defaultSize: {
                  //     height: "auto",
                  //     width: "auto",
                  //   },
                  // },
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                color="primary"
                className="mr-1"
                id="attachFile"
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={this.attachFiles}
              />
              <Row>
                <Col>
                  <label
                    htmlFor="attachFile"
                    className="btn btn-primary cursor-pointer float-right"
                  >
                    {this.translation(`messageComponent.attachFiles`)}
                  </label>
                </Col>
              </Row>

              {this.state.attachedFiles &&
                this.state.attachedFiles.map((file) => {
                  return (
                    <Row className="mb-1">
                      <Col sm="6" xs="6">
                        <Label>{file.name}</Label>
                      </Col>
                      <Col>
                        <Button
                          outline
                          color="primary"
                          className="mr-1"
                          onClick={() => {
                            this.removeFile(file);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />{" "}
                          {this.translation(`commonButtonTexts.remove`)}
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
            </FormGroup>

            {Array.isArray(this.state.draftDocumentObjects) &&
              !isEmpty(this.state.draftDocumentObjects) && (
                <div>
                  <br />
                  <DynamicTable
                    data={this.prepareTableData(
                      this.state.draftDocumentObjects
                    )}
                    columns={preparedColumns}
                    deleteAction={this.deleteDraftDocument}
                    infiniteScroll
                    dropdown={{
                      actions: [
                        {
                          label: this.translation(`commonButtonTexts.delete`),
                          actionFunc: "delete",
                          actionProps: "doc",
                        },
                      ],
                    }}
                  />
                </div>
              )}
            <FormGroup className="float-right">
              <Button type="submit" color="primary" className="mr-1">
                <FontAwesomeIcon icon={faPaperPlane} />{" "}
                {this.translation(`messageComponent.send`)}
              </Button>
              <Button
                color="primary"
                className="mr-1"
                onClick={() => {
                  this.saveAsDraft();
                }}
              >
                <FontAwesomeIcon icon={faSave} />{" "}
                {this.translation(`messageComponent.saveAsDraft`)}
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => {
                  this.cancelMessage();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />{" "}
                {this.translation(`commonButtonTexts.cancel`)}
              </Button>
            </FormGroup>
          </Form>
          <DeleteModal
            isOpen={this.state.showModalDelete}
            onClose={this.toggleModalDelete}
            event={this.modalDeleteEvent}
            modalTitle={this.translation(
              `messageComponent.removeDraftDocument`
            )}
            modalBodyText={this.translation(
              `messageComponent.removeDraftDocumentMessage`
            )}
          ></DeleteModal>
          <ModalError
            isOpen={this.state.showModalError}
            onClose={this.toggleModalError}
            mainError={this.mainError}
            errorReason={this.errorReason}
            errorResponse={this.errorResponse}
            modalTitle="Error"
          ></ModalError>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(withTranslation()(Message));
