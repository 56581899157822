import i18n from "../i18n";

const CaseGroupEnum = {
    ACCOUNTING: i18n.t(`caseGroup.ACCOUNTING`),
    ANTITRUST: i18n.t(`caseGroup.ANTITRUST`),
    CORRUPTION: i18n.t(`caseGroup.CORRUPTION`),
    DATAPROTECTION: i18n.t(`caseGroup.DATAPROTECTION`),
    EMBEZZLEMENT: i18n.t(`caseGroup.EMBEZZLEMENT`),
    ENVIRONMENTAL: i18n.t(`caseGroup.ENVIRONMENTAL`),
    EXPORTCONTROL: i18n.t(`caseGroup.EXPORTCONTROL`),
    FRAUD: i18n.t(`caseGroup.FRAUD`),
    HARASSMENT: i18n.t(`caseGroup.HARASSMENT`),
    HUMANRIGHTS: i18n.t(`caseGroup.HUMANRIGHTS`),
    INFIDELITY: i18n.t(`caseGroup.INFIDELITY`),
    INJURY: i18n.t(`caseGroup.INJURY`),
    INTERNAL: i18n.t(`caseGroup.INTERNAL`),
    LABOR: i18n.t(`caseGroup.LABOR`),
    MONEYLAUNDERING: i18n.t(`caseGroup.MONEYLAUNDERING`),
    OTHER: i18n.t(`caseGroup.OTHER`),
    TAX: i18n.t(`caseGroup.TAX`),
    TECHNICAL: i18n.t(`caseGroup.TECHNICAL`),
    THEFT: i18n.t(`caseGroup.THEFT`),
  };
  
  export default CaseGroupEnum;
  