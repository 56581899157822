import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import axios from "../../../axios/Axios";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import NoteTypeEnum from "../../../enums/NoteTypeEnum";
import { isEmpty } from "../../../helpers/GenericHelper";
import CaseDetailCard from "../../components/CaseDetailCard/CaseDetailCard";
import CaseNotesCard from "../../components/CaseNotesCard/CaseNotesCard";
import CaseQuestionnaireCard from "../../components/CaseQuestionnaireCard/CaseQuestionnaireCard";
import LinkedCasesCard from "../../components/LinkedCasesCard/LinkedCasesCard";
import MessageListCard from "../../components/MessageList/MessageListCard";
import ModalError from "../../components/modal/ModalError";
import MetaInformationCard from "../MetaInformationCard";
/*
 * Class for generating the details for a particular case
 */
class CaseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whistleblowerCase: {},
      caseStatuses: [],
      showModalError: false,
      manualNotes: [],
      automaticNotes: [],
      assignedAdvisors: [],
    };
    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.getWhistleblowerCaseData = this.getWhistleblowerCaseData.bind(this);
    this.getNotesData = this.getNotesData.bind(this);
    this.pushToNotesArray = this.pushToNotesArray.bind(this);
    this.getAssignedAdvisor = this.getAssignedAdvisor.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.updateNoteOnStatusDeadlineChange =
      this.updateNoteOnStatusDeadlineChange.bind(this);
    this.refreshManualNotesList = this.refreshManualNotesList.bind(this);
    this.refreshAutomaticNotesList = this.refreshAutomaticNotesList.bind(this);
    this.updateWhistleblowercaseState =
      this.updateWhistleblowercaseState.bind(this);
  }

  async componentDidMount() {
    let whistleblowerCase = await this.getWhistleblowerCaseData();

    await this.getNotesData(whistleblowerCase);
    await this.getAssignedAdvisor(whistleblowerCase);
  }

  async getWhistleblowerCaseData() {
    let whistleblowerCase = await axios.advisorService
      .get(`whistleblowercases/${this.props.match.params.id}`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({
            whistleblowerCase: response.data,
            caseStatuses: response.data.caseStatuses,
          });
          return response.data;
        }
      })
      .catch((error) => {
        this.errorHandler("Details Initialization", error);
      });

    return whistleblowerCase;
  }

  async getNotesData(whistleblowerCase) {
    let manualNotes = await axios.advisorService
      .get(
        `notes?noteType.equals=${NoteTypeEnum.MANUAL}&whistleblowercaseId.equals=${whistleblowerCase?.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Details Initialization", error);
      });

    let automaticNotes = await axios.advisorService
      .get(
        `notes?noteType.equals=${NoteTypeEnum.AUTOMATIC}&whistleblowercaseId.equals=${whistleblowerCase?.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Details Initialization", error);
      });

    this.pushToNotesArray(automaticNotes, NoteTypeEnum.AUTOMATIC);
    this.pushToNotesArray(manualNotes, NoteTypeEnum.MANUAL);
  }

  pushToNotesArray(noteArray, noteType) {
    if (!isEmpty(noteArray)) {
      let latestNotes = [];
      noteArray.forEach((noteData) => {
        if (noteData.validUntil === null) {
          latestNotes.push(noteData);
        }
      });

      if (noteType === NoteTypeEnum.AUTOMATIC) {
        this.setState({
          automaticNotes: latestNotes.reverse(),
        });
      } else {
        this.setState({
          manualNotes: latestNotes.reverse(),
        });
      }
    }
  }

  async getAssignedAdvisor(whistleblowerCase) {
    // let assignedAdvisors = [];
    // for (let advisor of whistleblowerCase?.users) {
    //   await axios.account
    //     .get(`users/${advisor.userId}`)
    //     .then((response) => {
    //       if (!isEmpty(response.data)) {
    //         assignedAdvisors.push(response.data);
    //       }
    //     })
    //     .catch((error) => {
    //       this.errorHandler("Details Initialization", error);
    //     });

    //   this.setState({ assignedAdvisors: assignedAdvisors });
    // }
    this.setState({ assignedAdvisors: whistleblowerCase?.users });
  }

  // Refreshes the manual notes list after adding a note or deleting a note
  refreshManualNotesList(origin, recentOperation) {
    if (origin === "caseNotes") {
      axios.advisorService
        .get(
          `notes?noteType.equals=${NoteTypeEnum.MANUAL}&whistleblowercaseId.equals=${this.state.whistleblowerCase?.id}`
        )
        .then((response) => {
          if (!isEmpty(response.data)) {
            let latestNotes = [];
            response.data.forEach((refreshedNoteData) => {
              if (refreshedNoteData.validUntil === null) {
                latestNotes.push(refreshedNoteData);
              }
            });
            this.setState({ manualNotes: latestNotes.reverse() });
          }
        })
        .catch((error) => {
          this.errorHandler(recentOperation, error);
        });
    }
  }

  refreshAutomaticNotesList() {
    if (this.state.whistleblowerCase?.id) {
      axios.advisorService
        .get(
          `notes?noteType.equals=${NoteTypeEnum.AUTOMATIC}&whistleblowercaseId.equals=${this.state.whistleblowerCase?.id}`
        )
        .then((response) => {
          if (!isEmpty(response.data)) {
            let latestNotes = [];
            response.data.forEach((refreshedNoteData) => {
              if (refreshedNoteData.validUntil === null) {
                latestNotes.push(refreshedNoteData);
              }
            });
            this.setState({ automaticNotes: latestNotes.reverse() });
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorHandler("Note Regeneration", error);
        });
    }
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Details Initialization":
        this.mainError = this.translation(
          `errorMessages.caseDetailsRetrievalError`
        );
        break;
      case "Note Addition/Editing":
        this.mainError = this.translation(`errorMessages.noteAddEditError`);
        break;
      case "Note Regeneration":
        this.mainError = this.translation(`errorMessages.noteListReloadError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    if (error?.response?.data?.errorKey === "userunauthorized") {
      this.errorReason = this.translation(`errorMessages.accessForbidden`);
    } else {
      this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    }
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  updateNoteOnStatusDeadlineChange(note, refreshWhistleblowerCase) {
    let newNotes = [note];
    newNotes = newNotes.concat(this.state.automaticNotes);
    this.setState({ automaticNotes: newNotes });

    if (refreshWhistleblowerCase) {
      this.getWhistleblowerCaseData();
    }
  }

  updateWhistleblowercaseState(whistleblowerCase) {
    this.setState({ whistleblowerCase: whistleblowerCase });
  }

  render() {
    const { whistleblowerCase, automaticNotes, manualNotes, assignedAdvisors } =
      this.state;
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.translation(`caseDetail.caseDetail`)}</HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/client-list">
                {this.translation(`adminClientList.clientList`)}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/client/${whistleblowerCase?.client?.id}`}>
                {whistleblowerCase?.client?.name}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{whistleblowerCase.title}</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Row>
          <Col>
            {!isEmpty(whistleblowerCase) && (
              <CaseDetailCard
                whistleblowerCase={whistleblowerCase}
                assignedAdvisor={assignedAdvisors}
                updateNotes={this.updateNoteOnStatusDeadlineChange}
                updateWhistleblowercaseState={this.updateWhistleblowercaseState}
              />
            )}
          </Col>
          <Col>
            {!isEmpty(whistleblowerCase) &&
              Array.isArray(manualNotes) &&
              Array.isArray(automaticNotes) && (
                <CaseNotesCard
                  whistleblowerCase={whistleblowerCase}
                  automaticNotes={automaticNotes}
                  manualNotes={manualNotes}
                  refreshNotesList={this.refreshManualNotesList}
                />
              )}
            {!isEmpty(whistleblowerCase) && (
              <LinkedCasesCard whistleblowerCase={whistleblowerCase} />
            )}
          </Col>
        </Row>

        {!isEmpty(whistleblowerCase) && (
          <MetaInformationCard
            whistleblowerCase={whistleblowerCase}
            metaInformations={whistleblowerCase.metaInformations}
          />
        )}

        {!isEmpty(whistleblowerCase) && (
          <CaseQuestionnaireCard whistleblowerCase={whistleblowerCase} />
        )}

        {!isEmpty(this.state.whistleblowerCase) && (
          <MessageListCard
            whistleblowerCase={this.state.whistleblowerCase}
            parentPage={"Case Details"}
            refreshAutomaticNotesList={this.refreshAutomaticNotesList}
          />
        )}

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withTranslation()(CaseDetail);
