import React from "react";
import { connect } from "react-redux";
import axiosMethods from "../../axios/Axios";
import sessionStorage from "../../axios/SessionStorage";
import { resetAccountDetails } from "../../redux/actions/accountActions";
import { persistor } from "../../redux/store/index";

const mapDispatchToProps = (dispatch) => ({
  resetAccountDetails: (userData) => {
    dispatch(resetAccountDetails(userData));
  },
  dispatch,
});

class SignOut extends React.Component {
  async componentDidMount() {
    sessionStorage.clearToken();
    persistor.purge();
    this.props.dispatch(resetAccountDetails());
    await axiosMethods.account.post("logout").then((response) => {
      window.location.href = response.data.logoutUrl.includes("/protocol")
        ? response.data.logoutUrl + "?redirect_uri=" + window.location.origin
        : response.data.logoutUrl +
          "?id_token_hint=" +
          response.data.idToken +
          "&post_logout_redirect_uri=" +
          window.location.origin;
    });
  }

  render() {
    return (
      <div class="splash active">
        <div class="splash-icon"></div>
      </div>
    )
  }
}

export default connect(
  (store) => ({
    account: store.account,
  }),
  mapDispatchToProps
)(SignOut);
