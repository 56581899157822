import {
  faCaretSquareDown,
  faCaretSquareLeft,
  faExclamation,
  faPlusSquare,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../axios/Axios";
import DynamicTable from "../../components/DynamicTable";
import FadeAlert from "../../components/FadeAlert";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import CaseStatusEnum from "../../enums/CaseStatusEnum";
import {
  Ascending,
  Default,
  DEFAULTPAGELENGTH,
  language,
  timeoutValue,
} from "../../helpers/Constants";
import {
  LoadingIndicator,
  deadlineStyleIdentificationByDate,
  formatDate,
  getNextSortType,
  sortDateReturnValue,
} from "../../helpers/GenericHelper";
import { store } from "../../redux/store/index";
import ModalError from "../components/modal/ModalError";
import NotificationCard from "../components/notificationCard/NotificationCard";
const defaultFilterWidth = "100px";
const defaultWidth = "120px";
/**
 * Home page for advisors containing all of cases of clients he/she is assigned to
 */
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      clients: [],
      casesData: {},
      showModalError: false,
      toggleUrgentCases: false,
      message: this.props.location?.state?.message ?? "",
      isView: false,

      //Filter
      filters: {},
      title: {},
      id: {},
      currentDeadline: {},
      state: [],
      projectStates: [],

      sortBy: {},
      sortType: {},
    };
    this.timeout = 0;
    this.filterTimeOut = 0;
    this.translation = this.props.t;
    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.allCases = {};
    this.advisors = [];
    this.hasMore = {};
    this.currentPage = {};

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.toggleFilterUrgent = this.toggleFilterUrgent.bind(this);
    this.getData = this.getData.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  preparedColumns = (clientId) => [
    {
      type: "data",
      header: this.translation(`commonText.id`),
      accessor: "id",
      filterKey: "id",
      filterValue: this.state.id[clientId],
      filterFunc: (event) => this.handleIdFilterChange(event, clientId),
      filterComponentWidth: defaultFilterWidth,
      width: defaultWidth,
      show: "true",
      sortFunc: (event) => this.sortData(event, clientId) ?? null,
    },
    {
      type: "data",
      header: this.translation(`caseDetail.caseName`),
      accessor: "title",
      show: "true",
      filterkey: "title",
      filterFunc: (event) => this.handleTitleFilterChange(event, clientId),
      filterValue: this.state.title[clientId],
      filterComponentWidth: defaultFilterWidth,
      showsearch: "true",
      link: `/case/`,
      linkAccessor: "id",
      sortFunc: (event) => this.sortData(event, clientId) ?? null,
    },
    {
      type: "data",
      header: this.translation(`caseDetail.caseStatus`),
      accessor: "caseStatus",
      filterFunc: this.handleStateFilterChange,
      filterType: "dropdown",
      filterOptions: this.state.projectStates,
      filterValue: this.state.state,
      filterComponentWidth: "180px",
      show: "true",
      filterkey: "caseStatus",
    },
    {
      type: "data",
      header: this.translation(`caseDetail.assignedAdvisor`),
      accessor: "assignedAdvisors",
      show: "true",
    },
    {
      type: "data",
      header: this.translation(`commonText.deadline`),
      accessor: "currentDeadline",
      show: "true",
      filterkey: "currentDeadline",
      filterType: "date",
      filterFunc: (event) => this.handleDeadlineFilterChange(event, clientId),
      filterComponentWidth: "180px",
      width: "200px",
      alignleft: "true",
      itemSortValueFunc: sortDateReturnValue,
      sortFunc: (event) => this.sortData(event, clientId) ?? null,
    },
  ];

  onFilterUpdate = (clientId) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      let newFilters = {
        title: this.state.title,
        id: this.state.id,
        currentDeadline: this.state.currentDeadline,
      };
      this.setState({ filters: newFilters });

      this.filterData(clientId);
    }, timeoutValue);
  };

  filterData = (clientId) => {
    if (this.filterTimeOut) {
      clearTimeout(this.filterTimeOut);
    }
    let allCases = this.state.casesData;
    this.filterTimeOut = setTimeout(async () => {
      this.currentPage[clientId] = 0;
      this.hasMore[clientId] = true;

      allCases[clientId] = await this.getData(clientId);

      this.allCases = { ...allCases };
      this.setState({ casesData: allCases });
    }, timeoutValue);
  };

  displayDeadlineDate(date) {
    return (
      <span className={deadlineStyleIdentificationByDate(date, true)}>
        {formatDate(date)}
      </span>
    );
  }
  async componentDidMount() {
    let state = store.getState();
    let accountDetails = state.account.accountDetails;
    let clients = [];
    moment.locale(Cookies.get(language));

    clients = await axios.advisorService
      .get(`clients/get-by-userId-clean/${accountDetails.id}`)
      .then((response) => {
        this.setState({ clients: response.data });
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Get Client List", error);
      });

    await this.getClientCases(clients);
  }

  async getClientCases(clients) {
    let allCases = {};

    for (const client of clients) {
      this.currentPage[client.id] = 0;
      this.hasMore[client.id] = true;
      allCases[client.id] = await this.getData(client.id);
    }

    this.allCases = { ...allCases };
    this.setState({ casesData: allCases });
  }

  createAxiosString = (client) => {
    let axiosString = `whistleblowercases?page=${
      this.currentPage[client]
    }&clientId.equals=${client}&caseStatus.in=${[
      CaseStatusEnum.NEW,
      CaseStatusEnum.INPROGRESS,
      CaseStatusEnum.ACCEPTED,
    ]}&urgent.equals=${this.state.toggleUrgentCases}`;
    let filter = this.state.filters;
    // Filters out by the title
    if (filter?.title?.[client]) {
      axiosString += `&title.contains=${filter?.title[client]}`;
    }

    // Filters out by the id
    if (filter?.id?.[client]) {
      axiosString += `&id.equals=${filter.id[client]}`;
    }

    // Filters out by the current deadline
    if (filter?.currentDeadline?.[client]) {
      let startDate = new Date(
        moment(filter.currentDeadline[client]).startOf("day")
      ).toISOString();
      let endDate = new Date(
        moment(filter.currentDeadline[client]).endOf("day")
      ).toISOString();
      axiosString += `&currentDeadline.greaterThanOrEqual=${startDate}&currentDeadline.lessThanOrEqual=${endDate}`;
    }

    // Sort by items
    if (this.state.sortBy?.[client] && this.state.sortType?.[client]) {
      axiosString += `&sort=${this.state.sortBy[client]}%2C${this.state.sortType[client]}`;
    } else {
      axiosString += `&sort=id%2Casc`;
    }
    return axiosString;
  };

  async getData(clientId) {
    if (this.fetchTimeOut) {
      clearTimeout(this.fetchTimeOut);
    }

    let axiosString = this.createAxiosString(clientId);

    return await axios.advisorService.get(axiosString).then((response) => {
      if (Array.isArray(response?.data) && response.data.length > 0) {
        this.hasMore[clientId] = !(response.data.length < DEFAULTPAGELENGTH);
        let currentPage = this.currentPage;
        currentPage[clientId]++;
        this.currentPage = currentPage;
      } else {
        this.hasMore[clientId] = false;
      }
      return this.prepareTableData(response.data);
    });
  }

  async fetchData(clientId) {
    let currentCases = this.allCases;
    let newCases = await this.getData(clientId);
    currentCases[clientId] = currentCases[clientId].concat(newCases);
    this.allCases = currentCases;
    this.setState({ casesData: currentCases });
  }

  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  //handling the id filter
  handleIdFilterChange = (event, clientId) => {
    event.preventDefault();
    let idFilter = this.state.id;
    idFilter[clientId] = event?.target?.value ?? null;
    this.setState({ id: idFilter }, () =>
      this.onFilterUpdate(clientId)
    );
  };
  //handling the title filter
  handleTitleFilterChange = (event, clientId) => {
    event.preventDefault();
    let titleFilter = this.state.title;
    titleFilter[clientId] = event?.target?.value ?? null;
    this.setState({ title: titleFilter }, () =>
      this.onFilterUpdate(clientId)
    );
  };

  //handling the deadline filter
  handleDeadlineFilterChange = (event, clientId) => {
    event.preventDefault();
    let deadlineFilter = this.state.currentDeadline;
    deadlineFilter[clientId] = event?.target?.value ?? null;
    this.setState({ currentDeadline: deadlineFilter }, () =>
      this.onFilterUpdate(clientId)
    );
  };

  sortData = (sortBy, clientId) => {
    var currentSortBy = this.state.sortBy;
    var currentSortType = this.state.sortType;

    let nextSortType = getNextSortType(
      currentSortType[clientId],
      sortBy,
      currentSortBy[clientId]
    );

    let allCases = this.state.casesData;
    currentSortType[clientId] = nextSortType === Default ? Ascending : nextSortType;
    currentSortBy[clientId] = nextSortType === Default ? "" : sortBy
    
    this.setState({
      sortType: currentSortType,
      sortBy: currentSortBy,
    });

    this.filterTimeOut = setTimeout(async () => {
      this.currentPage[clientId] = 0;
      this.hasMore[clientId] = true;
      allCases[clientId] = await this.getData(clientId);
      this.allCases = { ...allCases };
      this.setState({ casesData: allCases });
    }, timeoutValue);
  };

  errorHandler(currentOperation, error) {
    if (currentOperation === "Details Initialization") {
      this.mainError = this.translation(
        `errorMessages.caseDetailsRetrievalError`
      );
    } else {
      this.mainError = this.translation(`errorMessages.internalServerError`);
      this.errorResponse = error.message;
    }

    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  async prepareTableData(cases) {
    let tableData = [];
    for (let whistleblowerCase of cases) {
      let assignedAdvisors = await this.getAssignedAdvisor(whistleblowerCase);
      // format name
      let assignedAdvisorsName =
        assignedAdvisors?.length > 0
          ? assignedAdvisors.map((advisor, index) => {
              let name = advisor.lastName + ", " + advisor.firstName;
              if (index !== assignedAdvisors.length - 1) {
                name += "\n";
              }
              return name;
            })
          : "-";
      let data = {
        id: whistleblowerCase.id,
        clientId: whistleblowerCase.client.id,
        title: <div className="word-wrap">{whistleblowerCase.title}</div>,
        caseStatus: whistleblowerCase.caseStatuses
          ? this.translation(
              `enumTranslation.${whistleblowerCase.caseStatuses[
                whistleblowerCase.caseStatuses.length - 1
              ]?.statusOfCase.toLowerCase()}`
            )
          : "",
        dateModified: formatDate(whistleblowerCase.dateModified, true),
        client: (
          <Link to={`client/${whistleblowerCase.client.id}`}>
            {whistleblowerCase.client.name}
          </Link>
        ),
        assignedAdvisors: assignedAdvisorsName,
        deadline: formatDate(whistleblowerCase.currentDeadline),
        currentDeadline: (
          <span
            className={deadlineStyleIdentificationByDate(
              formatDate(whistleblowerCase.currentDeadline),
              true
            )}
          >
            {formatDate(whistleblowerCase.currentDeadline)}
          </span>
        ),
      };
      tableData.push(data);
    }

    return tableData;
  }

  async getAssignedAdvisor(whistleblowerCase) {
    let advisors = whistleblowerCase.users;
    return advisors;
  }

  toggleFilterUrgent() {
    this.setState(
      {
        toggleUrgentCases: !this.state.toggleUrgentCases,
      },
      async () => {
        await this.getClientCases(this.state.clients);
      }
    );
  }

  render() {
    let allData = this.state.casesData;
    let allhasMore = this.hasMore;
    return (
      <Container fluid>
        <LoadingIndicator />
        <Header>
          <HeaderTitle>{this.translation(`routes.home`)}</HeaderTitle>
        </Header>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <CardTitle>
                  <h1 className="float-left">
                    {this.translation(`commonText.cases`)}
                  </h1>
                  <span className="float-right">
                    <Button onClick={this.toggleFilterUrgent} color="primary">
                      {this.state.toggleUrgentCases ? (
                        <div>
                          <FontAwesomeIcon icon={faTh} />
                          {"  "}
                          {this.translation(`commonButtonTexts.showAll`)}
                        </div>
                      ) : (
                        <div>
                          <FontAwesomeIcon icon={faExclamation} />
                          {"  "}
                          {this.translation(`commonButtonTexts.showUrgent`)}
                        </div>
                      )}
                    </Button>
                  </span>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {this.state.message && (
                  <FadeAlert color="primary">{this.state.message}</FadeAlert>
                )}
                {this.state.clients.map((client) => {
                  let data = allData[client.id] ?? [];
                  let hasMore = allhasMore[client.id];
                  return (
                    <Card
                      className="client-accordion"
                      style={{ marginBottom: "1rem" }}
                      key={`client-${client.id}`}
                    >
                      <CardHeader>
                        {client.name}{" "}
                        <Button
                          className="float-right"
                          color="primary"
                          id={`toggler-${client.id}`}
                          onClick={() =>
                            this.setState({ isView: !this.state.isView })
                          }
                        >
                          {this.state.isView ? (
                            <FontAwesomeIcon icon={faCaretSquareLeft} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretSquareDown} />
                          )}
                        </Button>
                        <UncontrolledTooltip
                          target={`toggler-${client.id}`}
                          placement="top"
                        >
                          {this.state.isView
                            ? this.translation(`commonButtonTexts.expand`)
                            : this.translation(`commonButtonTexts.collapse`)}
                        </UncontrolledTooltip>
                      </CardHeader>
                      <CardHeader>
                        <Link to={`client-create-case/${client.id}`}>
                          <Button color="primary">
                            <FontAwesomeIcon icon={faPlusSquare} />
                            {"  "}
                            {this.translation(`caseDetail.createCase`)}
                          </Button>
                        </Link>
                      </CardHeader>
                      <UncontrolledCollapse
                        defaultOpen={true}
                        toggler={`#toggler-${client.id}`}
                      >
                        <CardBody>
                          <DynamicTable
                            tableId={`${client.id}`}
                            data={data}
                            showDeadline={true}
                            columns={this.preparedColumns(client.id)}
                            hasMoreData={hasMore}
                            fetchData={() => this.fetchData(client.id)}
                            infiniteScroll
                            sortType={this.state.sortType[client.id]}
                            sortBy={this.state.sortBy[client.id]}
                          />
                        </CardBody>
                      </UncontrolledCollapse>
                    </Card>
                  );
                })}
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <NotificationCard />
          </Col>
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withTranslation()(Home);
