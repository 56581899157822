import React from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Label,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { isEmpty } from "../../../helpers/GenericHelper";
import ModalError from "../../components/modal/ModalError";

/**
 * Component for displaying the clients for admin
 */
class AdminClientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      showModalError: false,
    };
    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  componentDidMount() {
    axios.advisorService
      .get("clients")
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ clients: response.data });
        }
      })
      .catch((error) => {
        this.errorHandler("Get Client List", error);
      });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Client List":
        this.mainError = this.translation(`errorMessages.clientListLoadError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  prepareTableData = (clients) => {
    if (Array.isArray(clients) && clients.length > 0) {
      let newTableData = [];

      clients.forEach((client) => {
        let entry = {
          id: client.id,
          name: client.name,
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`commonText.client`),
        accessor: "name",
        show: "true",
        filterkey: "name",
        showsearch: "true",
        link: `/admin/clients/`,
        linkAccessor: "id",
        alignleft: "true",
      },
    ];
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.translation(`commonText.clients`)}</HeaderTitle>
        </Header>
        <Card>
          <CardHeader>
            <CardTitle>
              <h1>{this.translation(`commonText.clients`)}</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            {Array.isArray(this.state.clients) &&
              (this.state.clients.length > 0 ? (
                <DynamicTable
                  data={this.prepareTableData(this.state.clients)}
                  infiniteScroll
                  columns={preparedColumns}
                />
              ) : (
                <div className="text-center">
                  <Label>{this.translation(`adminClientList.noClient`)}</Label>
                </div>
              ))}
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withTranslation()(AdminClientList);
