import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import React, { useState, useEffect } from "react";
import { Button, Card, Container, Label } from "reactstrap";
import axios from "../../../axios/Axios";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { LoadingIndicator, isEmpty } from "../../../helpers/GenericHelper";
import CaseGroupEnum from "../../../enums/CaseGroupEnum";
import i18n from "../../../i18n";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { store } from "../../../redux/store/index";

/**
 * React hook for displaying the page for changing of password
 */
export default function CreateCaseDetail() {
  const [clientName, setClientName] = useState("");
  const [clientData, setClientData] = useState("");

  const [caseTitle, setCaseTitle] = useState("");
  const [contactData, setContactData] = useState("");
  const [violation, setViolation] = useState("");
  const [occurrenceTime, setOccurrenceTime] = useState("");
  const [location, setLocation] = useState("");
  const [involvedPeople, setInvolvedPeople] = useState("");
  const [witness, setWitness] = useState("");
  const [expectations, setExpectations] = useState("");

  const [caseGroup, setCaseGroup] = useState("");

  const [caseGroupChoices, setCaseGroupChoices] = useState([]);

  const history = useHistory();
  const { id } = useParams();

  const nonapplicable = "N/A";

  useEffect(() => {
    // Set intial values
    setCaseGroup(nonapplicable);

    // get client details
    axios.advisorService.get(`clients/${id}`).then((response) => {
      if (!isEmpty(response.data)) {
        setClientName(response.data.name);
        setClientData(response.data);
      }
    });

    let caseGroupChoices = [
      {
        value: nonapplicable,
        label: nonapplicable,
      },
    ];
    for (let caseGroup in CaseGroupEnum) {
      let option = {
        value: caseGroup.toUpperCase(),
        label: CaseGroupEnum[caseGroup.toUpperCase()],
      };
      caseGroupChoices.push(option);
    }
    setCaseGroupChoices(caseGroupChoices);
  }, [id]);

  function handleCaseTitleChange(event) {
    setCaseTitle(event.target.value);
  }

  function handleContactDataChange(event) {
    setContactData(event.target.value);
  }

  function handleViolationChange(event) {
    setViolation(event.target.value);
  }

  function handleOccurrenceTimeChange(event) {
    setOccurrenceTime(event.target.value);
  }

  function handleLocationChange(event) {
    setLocation(event.target.value);
  }

  function handleInvolvedPeopleChange(event) {
    setInvolvedPeople(event.target.value);
  }

  function handleWitnessChange(event) {
    setWitness(event.target.value);
  }

  function handleExpectationsChange(event) {
    setExpectations(event.target.value);
  }

  function handleCaseGroupChange(event) {
    setCaseGroup(event.target.value);
  }

  function saveMetaInformation() {
    let client = clientData;

    // Insert metadata first, then insert in to the WB case
    let metaData = [
      {
        contactData: contactData,
        violation: violation,
        occurenceTime: occurrenceTime,
        location: location,
        involvedPeople: involvedPeople,
        witness: witness,
        expectations: expectations,
      },
    ];

    // Insert WB case data along side the client info and meta data (deadline is automatic), get ID
    let storeState = store.getState();
    let accountDetails = [storeState.account.accountDetails];
    let caseData = {
      whistleblowerId: 0,
      title: caseTitle,
      caseGroup: caseGroup !== nonapplicable ? caseGroup : null,
      client: client,
      notes: null,
      users: accountDetails,
      metaInformations: metaData,
    };

    axios.advisorService.post(`whistleblowercases`, caseData).then(() => {
      history.push({
        pathname: "/",
        state: {
          message: i18n.t(
            `metaInformation.caseCreatedSuccessfully` /*, { clientName: `${user.lastName}, ${user.firstName}`, }*/
          ),
        },
      });
    });
  }
  return (
    <Container liquid style={{ alignItems: "flex-start" }}>
      <Header>
        <HeaderTitle>{i18n.t(`metaInformation.title`)}</HeaderTitle>
      </Header>

      <Card style={{ padding: "20px", width: "75%" }}>
        <LoadingIndicator />
        <AvForm onValidSubmit={saveMetaInformation}>
          <AvGroup>
            <h1>
              {i18n.t(`metaInformation.clientName`)}
              {clientName}
            </h1>
          </AvGroup>
          <AvGroup>
            <h4>
              <Label>{i18n.t(`metaInformation.caseTitle`)}</Label>
            </h4>
            <AvField
              name="caseTitle"
              type="text"
              value={caseTitle}
              onChange={handleCaseTitleChange}
              validate={{
                required: {
                  value: true,
                  errorMessage: i18n.t(`fieldWarnings.fieldIsRequired`),
                },
              }}
            />
          </AvGroup>
          <AvGroup>
            <h6>{i18n.t(`metaInformation.header`)}</h6>
          </AvGroup>
          <AvGroup>
            <Label>{i18n.t(`metaInformation.contactData`)}</Label>
            <AvField
              rows={3}
              name="contactData"
              type="textarea"
              value={contactData}
              onChange={handleContactDataChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{i18n.t(`metaInformation.violation`)}</Label>
            <AvField
              rows={3}
              name="violation"
              type="textarea"
              value={violation}
              onChange={handleViolationChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{i18n.t(`metaInformation.occurrenceTime`)}</Label>
            <AvField
              rows={3}
              name="occurrenceTime"
              type="textarea"
              value={occurrenceTime}
              onChange={handleOccurrenceTimeChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{i18n.t(`metaInformation.location`)}</Label>
            <AvField
              rows={3}
              name="location"
              type="textarea"
              value={location}
              onChange={handleLocationChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{i18n.t(`metaInformation.involvedPeople`)}</Label>
            <AvField
              rows={3}
              name="involvedPeople"
              type="textarea"
              value={involvedPeople}
              onChange={handleInvolvedPeopleChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{i18n.t(`metaInformation.witness`)}</Label>
            <AvField
              rows={3}
              name="witness"
              type="textarea"
              value={witness}
              onChange={handleWitnessChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{i18n.t(`metaInformation.expectation-3`)}</Label>
            <AvField
              rows={3}
              name="expectations"
              type="textarea"
              value={expectations}
              onChange={handleExpectationsChange}
            />
          </AvGroup>

          <AvGroup>
            <Label>{i18n.t(`metaInformation.caseGroup`)}</Label>
            <AvField
              type="select"
              name="caseGroup"
              helpMessage="This determines what type of case it is"
              onChange={handleCaseGroupChange}
            >
              {caseGroupChoices.map((answer, i) => {
                // Return the element. Also pass key
                return <option value={answer.value}>{answer.label}</option>;
              })}
            </AvField>
          </AvGroup>

          <div className="text-center mt-3">
            <Button color="primary" size="lg" id="submit">
              {<FontAwesomeIcon icon={faSave} />}
              {" " + i18n.t(`commonButtonTexts.save`)}
            </Button>{" "}
          </div>
        </AvForm>
      </Card>
    </Container>
  );
}
