import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "../../../axios/Axios";
import NoteTypeEnum from "../../../enums/NoteTypeEnum";
import { LoadingIndicator } from "../../../helpers/GenericHelper";
import { store } from "../../../redux/store/index";
import ModalError from "../../components/modal/ModalError";
/*
 * Class for allowing notes to be added to either a case or a message
 */
class CaseNotesAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: this.props.notesData !== null ? this.props.notesData.notes : "",
      showModalError: false,
    };
    this.translation = this.props.t;
    this.keepAlive = 0;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.whistleblowerCaseId = this.props.whistleblowerCaseId;
    this.messageId = this.props.messageId;
    this.notesData = this.props.notesData;

    this.toggleModalError = this.toggleModalError.bind(this);
    this.saveNote = this.saveNote.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
  }

  componentDidMount() {
    //Ensures the session won't timeout when modal is open
    this.keepAlive = setInterval(() => {
      axios.keepAlive
        .get("keep-alive")
        .then(() => {})
        .catch(() => {});
    }, 300000);
  }

  componentWillUnmount() {
    // Disable keep alive upon component unmount
    clearInterval(this.keepAlive);
    this.keepAlive = 0;
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Method for saving notes to either a case or a message
  async saveNote(event) {
    event.preventDefault();
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;

    // New Note
    if (this.notesData === null) {
      let newNote = {
        notes: this.state.notes,
        creator: `${accountDetails.lastName}, ${accountDetails.firstName}`,
        operationType: "CREATED",
        noteType: NoteTypeEnum.MANUAL,
        whistleblowercase:
          this.props.whistleblowerCaseId !== null
            ? { id: this.props.whistleblowerCaseId }
            : null,
        message:
          this.props.messageId !== null ? { id: this.props.messageId } : null,
      };
      axios.advisorService
        .post(`notes`, newNote)
        .then(() => {
          if (this.props.externalOnSubmitEvent) {
            this.props.externalOnSubmitEvent(
              this.props.whistleblowerCaseId !== null
                ? "caseNotes"
                : "messageNotes",
              this.props.messageId !== null ? this.props.messageId : null
            );
          }
        })
        .catch((error) => {
          this.errorHandler("Saving Notes", error);
        });
    } else {
      // Updating a Note
      let existingNote = {
        id: this.notesData.id,
        notes: this.state.notes,
        creator: this.notesData.creator,
        operationType: "EDITED",
        modifier: `${accountDetails.lastName}, ${accountDetails.firstName}`,
        noteType: NoteTypeEnum.MANUAL,
        whistleblowercase:
          this.props.whistleblowerCaseId !== null
            ? { id: this.props.whistleblowerCaseId }
            : null,
        message:
          this.props.messageId !== null ? { id: this.props.messageId } : null,
      };
      axios.advisorService
        .put("notes", existingNote)
        .then(() => {
          if (this.props.externalOnSubmitEvent) {
            this.props.externalOnSubmitEvent(
              this.props.whistleblowerCaseId !== null
                ? "caseNotes"
                : "messageNotes",
              this.props.messageId !== null ? this.props.messageId : null
            );
          }
        })
        .catch((error) => {
          this.errorHandler("Saving Notes", error);
        });
    }

    if (this.props.refreshAutomaticNotesList) {
      this.props.refreshAutomaticNotesList();
    }
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    if (currentOperation === "Saving Notes") {
      this.mainError = this.translation(`errorMessages.noteSaveError`);
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Handles the changing of the notes text area
  handleNoteChange(e) {
    this.setState({ notes: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <LoadingIndicator />
        <AvForm onValidSubmit={this.saveNote}>
          <AvGroup>
            <AvField
              name="noteName"
              label={this.translation(`commonText.note`)}
              type="textarea"
              autoFocus={true}
              validate={{
                required: {
                  value: true,
                  errorMessage: this.translation(
                    `fieldWarnings.fieldIsRequired`
                  ),
                },
              }}
              value={this.notesData !== null ? this.notesData.notes : null}
              onChange={this.handleNoteChange}
            />
            <div className="float-right mt-3">
              <Button color="primary">
                <FontAwesomeIcon icon={faEdit} />
                {this.notesData !== null
                  ? this.translation(`notes.editNote`)
                  : this.translation(`notes.addNote`)}
              </Button>
            </div>
          </AvGroup>
        </AvForm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(CaseNotesAdd));
