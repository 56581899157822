import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import i18n from "../../../i18n";

/**
 * ModalError contains commands that would enable a modal dialog for errors
 * to be opened up in a webpage
 */
const ModalError = (props) => {
  const {
    modalTitle,
    mainError,
    errorReason,
    errorResponse,
    isOpen,
    onClose,
  } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    onClose && onClose(e);
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  var AcceptButton = (
    <Button color="primary" onClick={toggle}>
      {i18n.t(`commonButtonTexts.okay`)}
    </Button>
  );

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <table>
            <tr>{mainError}</tr>
            {errorReason === "" ? null : (
              <tr>
                <td>
                  {i18n.t(`commonText.reason`)}: {errorReason}
                </td>
              </tr>
            )}
            {errorResponse === "" ? null : (
              <tr>
                <td>
                  {i18n.t(`commonText.requestResponse`)}: {errorResponse}
                </td>
              </tr>
            )}
          </table>
        </ModalBody>
        <ModalFooter>{AcceptButton}</ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalError;
