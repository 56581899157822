import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import AdvisorModalClientList from "../../components/AdvisorModalClientList/AdvisorModalClientList";
import ModalDelete from "../modal/DeleteModal";
import ModalError from "../modal/ModalError";
import ModalInfo from "../modal/ModalInfo";

/*
 * Class for showing the list of advisors for a client
 */
class AdvisorCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advisors: [],
      modalDeleteIsOpen: false,
      showModalError: false,
      showModalInfo: false,
    };
    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.modalTitle = "";
    this.modalBodyText = "";

    this.modalDeleteEvent = null;
    this.errorHandler = this.errorHandler.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleModalInfo = this.toggleModalInfo.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.removeAdvisorFromClient = this.removeAdvisorFromClient.bind(this);
    this.viewAdvisorClients = this.viewAdvisorClients.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.advisors !== prevProps.advisors) {
      this.setState({ advisors: this.props.advisors });
    }
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Client Advisors":
        this.mainError = this.translation(
          `errorMessages.clientAdvisorRetrievalError`
        );
        break;
      case "Delete Selected Advisor":
        this.mainError = this.translation(
          `errorMessages.clientAdvisorDeletionError`
        );
        break;
      case "Get Client List":
        this.mainError = this.translation(`errorMessages.clientListLoadError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Toggles the Boolean that affects appearance of modal delete dialog box
  toggleDeleteModal() {
    this.setState({ modalDeleteIsOpen: !this.state.modalDeleteIsOpen });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  toggleModalInfo() {
    this.setState({
      showModalInfo: !this.state.showModalInfo,
    });
  }

  // Removes the selected advisor from the client
  removeAdvisorFromClient(selectedAdvisor) {
    this.toggleDeleteModal();
    this.modalDeleteEvent = () =>
      axios.advisorService
        .get(`clients/${this.props.clientId}`)
        .then((response) => {
          // Remove the advisor from cases they were assigned to and the client.
          axios.advisorService
            .put(
              `client/unassignAdvisorFromClient?clientId=${this.props.clientId}&userLogin=${selectedAdvisor.login}`
            )
            .catch((error) => {
              this.errorHandler(
                this.translation(`advisors.removeSelectedAdvisor`),
                error
              );
            });

          // Update the advisors state, which contain user objects, in order to update the advisors list
          let newUsers = this.state.advisors.filter(
            (advisor) => advisor.id !== selectedAdvisor.id
          );
          this.setState({
            advisors: newUsers,
          });
        })
        .catch((error) => {
          this.errorHandler(
            this.translation(`advisors.removeSelectedAdvisor`),
            error
          );
        });
  }

  prepareTableData = (advisors) => {
    if (Array.isArray(advisors) && advisors.length > 0) {
      let newTableData = [];
      advisors.forEach((advisor) => {
        let entry = {
          name: advisor.firstName + " " + advisor.lastName,
          email: advisor.email,
          advisor: advisor,
        };
        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  viewAdvisorClients(advisor) {
    // Get the advisor ID of the user object to be able to view their assigned cases
    this.modalBodyText = (
      <AdvisorModalClientList parentPage="clientDetails" advisor={advisor} />
    );
    this.modalTitle = `${advisor.lastName}, ${
      advisor.firstName
    }: ${this.translation(`adminClientList.assignedClients`)}`;
    this.toggleModalInfo();
  }

  render() {
    const { isAdmin, clientId } = this.props;
    const { advisors } = this.state;
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`fieldLabels.name`),
        accessor: "name",
        show: "true",
        filterkey: "name",
        showsearch: "true",
        linkFunc: this.viewAdvisorClients,
        linkFuncParameter: "advisor",
      },
      {
        type: "data",
        header: this.translation(`fieldLabels.email`),
        accessor: "email",
        show: "true",
        filterkey: "email",
        showsearch: "true",
      },
      {
        type: "Dropdown",
        header: this.translation(`commonText.menu`),
        show: "true",
      },
    ];
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <h1>
              {this.translation(`commonText.advisor`)}
              {isAdmin ? (
                <Link to={`/admin/clients/${clientId}/add-advisor`}>
                  <Button
                    color="primary"
                    size="s"
                    className="float-right"
                    id="add-advisor-button"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </Button>
                  <UncontrolledTooltip target="add-advisor-button">
                    {this.translation(`adminAdvisorAdd.addAdvisor`)}
                  </UncontrolledTooltip>
                </Link>
              ) : (
                ""
              )}
            </h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {Array.isArray(advisors) &&
            (advisors.length > 0 ? (
              <DynamicTable
                data={this.prepareTableData(advisors)}
                columns={preparedColumns}
                deleteAction={this.removeAdvisorFromClient}
                removeMenuPortalTarget={true}
                infiniteScroll
                dropdown={{
                  actions: [
                    {
                      label: this.translation(`commonButtonTexts.remove`),
                      actionFunc: "delete",
                      actionProps: "advisor",
                    },
                  ],
                }}
              />
            ) : (
              <div className="text-center">
                <Label>{this.translation(`adminAdvisorList.noAdvisor`)}</Label>
              </div>
            ))}
        </CardBody>
        <ModalDelete
          isOpen={this.state.modalDeleteIsOpen}
          event={this.modalDeleteEvent}
          toggleModal={this.toggleDeleteModal}
          modalTitle={this.translation(`advisorCard.removeFromClient`)}
          modalBodyText={this.translation(
            `advisorCard.removeFromClientMessage`
          )}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalInfo
          isOpen={this.state.showModalInfo}
          toggleModal={this.toggleModalInfo}
          modalBodyText={this.modalBodyText}
          modalTitle={this.modalTitle}
          footerStyle={{ "padding-right": "1.5rem" }}
          size="m"
        ></ModalInfo>
      </Card>
    );
  }
}

export default withTranslation()(AdvisorCard);

AdvisorCard.defaultProps = {
  isAdmin: false,
};

AdvisorCard.propTypes = {
  advisors: PropTypes.arrayOf(PropTypes.object),
  isAdmin: PropTypes.bool.isRequired,
  clientId: PropTypes.number,
};
