import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
    Breadcrumb,
    BreadcrumbItem, Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,


    Form,
    FormGroup
} from "reactstrap";
import axios from "../../../axios/Axios";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { isEmpty, LoadingIndicator } from "../../../helpers/GenericHelper";
import ModalError from "../../components/modal/ModalError";


/**
 * Component for Adding advisor to client
 */
class AdminClientAddAdvisor extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            advisorOptions: [],
            client: null,
            selectedAdvisors: [],
            showModalError: false,
        }
        this.translation = this.props.t;

        this.mainError = "";
        this.errorReason = "";
        this.errorResponse = "";

        this.toggleModalError = this.toggleModalError.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
        this.handleAdvisorOnChange = this.handleAdvisorOnChange.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    async componentDidMount(){
        await axios.advisorService.get(`clients/${this.props.match.params.id}`)
        .then(response => {
            if(!isEmpty(response.data)){
                this.setState({ client: response.data });
            }
        })
        .catch(error => {
            this.errorHandler("Get Client Details", error)
        })

        let options = []
        axios.account.get('get-all-users')
        .then(response => {
            if(!isEmpty(response.data)){
                options = response.data.filter(advisor => !this.state.client.users?.some(existingAdvisor => advisor.login === existingAdvisor.login))
                options = options.map(option => {
                    return {
                        value:option,
                        label:option.lastName + " " + option.firstName
                    }
                })
                this.setState({ advisorOptions: options})
            }
        })
        .catch(error => {
            this.errorHandler("Get Advisor List", error)
        })
    }

    // Toggles the Boolean that affects appearance of modal error dialog box
    toggleModalError() {
        this.setState({
            showModalError: !this.state.showModalError,
        });
    }

    // Handles errors encountered in this component
    errorHandler(currentOperation, error){
        switch(currentOperation){
            case "Get Client Details":
                this.mainError = this.translation(`errorMessages.clientDetailsRetrievalError`)
                break;
            case "Get Advisor List":
                this.mainError = this.translation(`errorMessages.advisorListLoadError`);
                break;
            case "Add Advisor to Client":
                this.mainError = this.translation(`errorMessages.advisorAddToClientError`);
                break;
            default:
                this.mainError = this.translation(`errorMessages.internalServerError`)
                this.errorResponse = error.message;
                if (!this.state.showModalError) {
                    this.toggleModalError();
                }
                return;
        }
        this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
            this.toggleModalError();
        }
    }

    // Handles changes to the advisor dropdown
    handleAdvisorOnChange(event){
        let selectedAdvisors = event?.map(advisor => {
            return advisor.value
        })
        this.setState({ selectedAdvisors : selectedAdvisors})
    }

    // Commands to run when submitting valid values
    async handleValidSubmit(e){
        e.preventDefault();

        let client = {
            id: this.state.client.id,
            name: this.state.client.name,
            users: this.state.client.users ? this.state.client.users.concat(this.state.selectedAdvisors) : this.state.selectedAdvisors
        };
        axios.advisorService.put('clients', client)
        .then(() => {
            this.props.history.push(`/admin/clients/${this.state.client.id}`)
        })
        .catch(error => {
            this.errorHandler("Add Advisor to Client", error)
        })
    }

    render(){
        const { client } = this.state
        return(
            <Container fluid>
                <Header>
                    <HeaderTitle>{this.translation(`adminClientAddAdvisor.addAdvisorTo`)} {client?.name} </HeaderTitle>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/admin/clients">{this.translation(`commonText.clients`)}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to={`/admin/clients/${client?.id}`}>{client?.name}</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {this.translation(`adminClientAddAdvisor.addAdvisorTo`)} {client?.name}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Header>
                <Card>
                    <LoadingIndicator />
                    <CardHeader>
                        <CardTitle>
                            <h1>{this.translation(`adminClientAddAdvisor.addAdvisorTo`)} {client?.name}</h1>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={this.handleValidSubmit}>
                            <FormGroup>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={this.state.advisorOptions}
                                onChange={this.handleAdvisorOnChange}
                                placeholder={this.translation("commonText.select")}
                                noOptionsMessage={() =>
                                    this.translation("commonText.noOptions")
                                }
                            />
                            </FormGroup>
                            <Button color="primary" size="lg">
                                {this.translation(`commonButtonTexts.add`)}
                            </Button>
                        </Form>
                    </CardBody>
                </Card>           
                <ModalError
                isOpen={this.state.showModalError}
                onClose={this.toggleModalError}
                mainError={this.mainError}
                errorReason={this.errorReason}
                errorResponse={this.errorResponse}
                modalTitle="Error"
                ></ModalError>
            </Container>
        )
    }
}

export default withTranslation()(AdminClientAddAdvisor)