import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import axios from "../../../axios/Axios";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { isEmpty } from "../../../helpers/GenericHelper";
import CaseCard from "../../components/caseCard/CaseCard";
import ClientDetailCard from "../../components/clientDetailCard/ClientDetailCard";
import ClientNotesCard from "../../components/ClientNotesCard/ClientNotesCard";
import ModalError from "../../components/modal/ModalError";

/*
 * Class for displaying a client's details within the advisor dashboard
 */
class ClientDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client: {},
      notes: [],
      showModalError: false,
    };
    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.syncCaseInterval = null;

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.notesChange = this.notesChange.bind(this);
    this.removeNoteFromState = this.removeNoteFromState.bind(this);
  }

  async componentDidMount() {
    // get client details
    await axios.advisorService
      .get(`clients/${this.props.match.params.id}`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ client: response.data });
          return response.data;
        }
      })
      .catch((error) => {
        this.errorHandler("Get Client Details", error);
      });

    axios.advisorService
      .get(`client-notes/${this.props.match.params.id}`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ notes: response.data });
        }
      });
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // function for adding/editing notes to state
  notesChange(note) {
    let notes = this.state.notes;

    let noteUpdated = notes.find((existingNote) => existingNote.id === note.id);
    if (noteUpdated) {
      notes[notes.indexOf(noteUpdated)] = note;
    } else {
      notes.unshift(note);
    }

    this.setState({ notes: notes });
  }

  removeNoteFromState(noteToBeRemoved) {
    let notes = this.state.notes;

    let filteredNotes = notes.filter((note) => note.id !== noteToBeRemoved.id);
    this.setState({ notes: filteredNotes });
  }

  // Handles errors encountered in this page
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Client Details":
        this.mainError = this.translation(
          `errorMessages.clientDetailsRetrievalError`
        );
        break;
      case "Update Cases List":
        this.mainError = this.translation(`errorMessages.caseListUpdateError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.translation(`adminClientDetail.clientDetail`)}
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/client-list">
                {this.translation(`adminClientList.clientList`)}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{this.state.client.name}</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <ClientDetailCard client={this.state.client} />

        <ClientNotesCard
          client={this.state.client}
          notes={this.state.notes}
          notesChange={this.notesChange}
          removeNoteFromState={this.removeNoteFromState}
          errorHandler={this.errorHandler}
        />

        {!isEmpty(this.state.client) && <CaseCard client={this.state.client} />}
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withTranslation()(ClientDetail);
