import axios from "axios";
import SessionStorage from "./SessionStorage";

const sessionStorageService = SessionStorage.getService();

// Use for adding the authorization header located in session storage
// on axios request.
axios.interceptors.request.use(
  (config) => {
    const token = sessionStorageService.getAccessToken();
    config.headers.common["Accept"] = "application/json";

    if (token) {
      config.headers["Authorization"] = token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Interceptor for response, if error 401 - not authorized return to login page
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && window.location.href.indexOf("/error") === -1) {
      window.location.href = "/oauth2/authorization/oidc";
      return Promise.reject(error);
    }
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
    return Promise.reject(error);
  }
);

export default axios;
