import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import languageDE from "./locate/de/translate.json";
import languageEN from "./locate/en/translate.json";
import Cookies from "js-cookie";
import { getBrowserLanguage } from "./helpers/GenericHelper";
import { language } from "./helpers/Constants";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      de: languageDE,
    },
    /* default language when load the website in browser */
    /* if navigator.language is in a lang-Country format, only the language part will be taken*/
    lng:
      Cookies.get(language) === undefined
        ? getBrowserLanguage(navigator.language.split("-")[0])
        : Cookies.get(language),
    load: "languageOnly",
    /* When react i18next not finding any language to as default in browser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default i18n;
