import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import SessionStorage from "../../axios/SessionStorage";
import i18n from "../../i18n";

const Page404 = () => (
  <div className="text-center">
    <h1 className="display-1 font-weight-bold">404</h1>
    <p className="h1">{i18n.t(`page404Or500.pageNotFound`)}</p>
    <p className="h2 font-weight-normal mt-3 mb-4">
      {i18n.t(`page404Or500.pageNotFoundMessage`)}
    </p>
    {SessionStorage.getAccessToken() !== null ? (
      <div>
        <Link to="/">
          <Button color="primary" size="lg">
            {i18n.t(`page404Or500.returnToWebsite`)}
          </Button>
        </Link>
        <span> </span>
        <Link to="/auth/sign-out">
          <Button color="primary" size="lg">
            {i18n.t(`routes.signOut`)}
          </Button>
        </Link>
      </div>
    ) : (
      <Link to="/auth/sign-out">
        <Button color="primary" size="lg">
          {i18n.t(`routes.signOut`)}
        </Button>
      </Link>
    )}
  </div>
);

export default Page404;
