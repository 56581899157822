import Cookies from "js-cookie";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from "redux-persist/integration/react";
import axios from "./axios/Axios";
import SessionStorageService from "./axios/SessionStorage";
import CookieBanner from "./components/CookieBanner";
import i18n from "./i18n";
import { setAccountDetails } from "./redux/actions/accountActions";
import { persistor, store } from "./redux/store/index";
import Routes from "./routes/Routes";
import { SetCompanyDetails } from "./redux/actions/accountActions";
import { setLogo } from "./redux/actions/logoActions";
import InvensityLogo from "./assets/img/Invensity_Logo.png";
import { isEmpty } from "./helpers/GenericHelper";
import { language } from "./helpers/Constants";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }
  async componentDidMount() {
    i18n.changeLanguage(Cookies.get(language));
    moment.locale(Cookies.get(language));
    let token = await axios.account.get("authenticate");
    if (token) {
      SessionStorageService.setToken(token.headers.authorization);
      let account = await axios.account.get("account")
      if (account.data) {
        this.props.dispatch(setAccountDetails(account.data));

        // Before sign in, the user's language key is checked first
        // If it exists, the cookie is set to make use of that.
        // If it doesn't, the dashboard will retain the browser's default language and will
        // store the value that is selected later.
        if (account.data !== undefined) {
          if (
            account.data.langKey !== null &&
            i18n.language !== account.data.langKey
          ) {
            Cookies.set(language, account.data?.langKey?.toLowerCase());
            window.location.reload();
          }
        }
        // Admins
        else {
          if (
            account.data.langKey !== null &&
            i18n.language !== account.data.langKey
          ) {
            Cookies.set(language, account.data?.langKey?.toLowerCase());
            window.location.reload();
          }
        }
      }
      // Remove `active` className from `.splash` element in `public/index.html`
      !document.querySelector(".splash") ||
        document.querySelector(".splash").classList.remove("active");


      this.setState({ authenticated: true })
      await axios.advisorService
        .get("information/company-information")
        .then((response) => {
          if (!isEmpty(response)) {
            store.dispatch(SetCompanyDetails(response.data));
          }
        });

      // set logo
      await axios.advisorService
        .get(`information/logo`, { responseType: "blob" })
        .then((response) => {
          if (!isEmpty(response.data)) {
            store.dispatch(setLogo(window.URL.createObjectURL(response.data)));
          } else {
            store.dispatch(setLogo(InvensityLogo));
          }
        });

    }

  }

  render() {
    return (
      <PersistGate loading={null} persistor={persistor}>
        <Routes authenticated={this.state.authenticated} />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <CookieBanner />
      </PersistGate>
    );
  }
}

export default connect((store) => ({
  account: store.account,
}))(App);
