import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import i18n from "../../../i18n";

/*
 * Modal used for delete requests
 */
const ModalDelete = (props) => {
  const {
    modalTitle,
    modalBodyText,
    event,
    isOpen,
    toggleModal,
    onClose,
    buttonText
  } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    setModal(!modal);
    toggleModal && toggleModal(e);
    onClose && onClose(e);
  };
  const deleteEvent = () => {
    event().then(() => {
      toggle();
    });
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBodyText}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={deleteEvent}>
            {buttonText}
          </Button>{" "}
          <Button color="dark" onClick={toggle}>
            {i18n.t(`commonButtonTexts.cancel`)}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalDelete;

ModalDelete.prototype = {
  modalTitle: PropTypes.string.isRequired,
  modalBodyText: PropTypes.string.isRequired,
  event: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  buttonText: PropTypes.string
};

ModalDelete.defaultProps = {
  buttonText: i18n.t(`commonButtonTexts.delete`)
}