import React from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import axios from "../../../../axios/Axios";
import { isEmpty } from "../../../../helpers/GenericHelper";
import ModalError from "../../../components/modal/ModalError";
/**
 * Class for generating the dropdown of clients for the statistics view
 */
class ClientFilterDropdownInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientData: [],
      showModalError: false,
    };

    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  async componentDidMount() {
    let clientData = await axios.advisorService
      .get(`clients`)
      .then((response) => {
        if (!isEmpty(response)) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        this.errorHandler("Get Client For Dropdown", error);
      });

    var options = clientData?.map((client) => ({
      value: client.id,
      label: client.name,
    }));

    this.setState({
      clientData: options,
    });
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Client For Dropdown":
        this.mainError = this.translation(
          `errorMessages.clientDropdownOptionError`
        );
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    return (
      <div>
        <Select
          value={this.state.clientData?.find(
            (dataEntry) => dataEntry.value === this.props.client
          )}
          options={this.state.clientData}
          isMulti={false}
          {...this.props}
          placeholder={this.translation("commonText.select")}
          noOptionsMessage={() =>
              this.translation("commonText.noOptions")
          }
        ></Select>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </div>
    );
  }
}

export default withTranslation()(ClientFilterDropdownInput);
