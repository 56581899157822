import React from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import axios from "../../../../axios/Axios";
import { isEmpty } from "../../../../helpers/GenericHelper";
import ModalError from "../../../components/modal/ModalError";
/**
 * Class for generating the dropdown of advisorss for the statistics view
 */
class AdvisorFilterDropdownInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advisorData: [],
      showModalError: false,
    };

    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  async componentDidMount() {
    let advisorData = await axios.advisorService
      .get(`get-all-users`)
      .then((response) => {
        if (!isEmpty(response)) {
          return response.data;
        }
        return [];
      })
      .catch((error) => {
        this.errorHandler("Get Advisor For Dropdown", error);
      });

    var options = advisorData?.map((advisor) => ({
      value: advisor,
      label:
        advisor == null
          ? this.translation(`statistics.notAssigned`)
          : `${advisor.lastName}, ${advisor.firstName}`,
    }));

    this.setState({
      advisorData: options,
    });
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Advisor For Dropdown":
        this.mainError = this.translation(
          `errorMessages.advisorDropdownOptionError`
        );
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  render() {
    return (
      <div>
        <Select
          value={this.state.advisorData?.find(
            (dataEntry) => dataEntry.value.id === this.props.advisor.id
          )}
          options={this.state.advisorData}
          isMulti={false}
          {...this.props}
          placeholder={this.translation("commonText.select")}
          noOptionsMessage={() => this.translation("commonText.noOptions")}
        ></Select>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </div>
    );
  }
}

export default withTranslation()(AdvisorFilterDropdownInput);
