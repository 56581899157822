import React from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import axios from "../../../axios/Axios";
import clientSessionStorage from "../../../axios/ClientSessionStorage";
import DynamicTable from "../../../components/DynamicTable";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import RoleEnums from "../../../enums/RoleEnums";
import { formatDate } from "../../../helpers/GenericHelper";
import { store } from "../../../redux/store/index";
import ClientStatistic from "../../admin-pages/statistics/ClientStatistic";
import ModalError from "../../components/modal/ModalError";
import ModalInfo from "../../components/modal/ModalInfo";

/**
 * Class Component for displaying clients
 */
class ClientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      showModalError: false,
      showModalDetails: false,
    };
    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";
    this.statisticsDetails = "";

    this.toggleModalDetails = this.toggleModalDetails.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.viewStatistics = this.viewStatistics.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  componentDidMount() {
    // clear client session storage
    clientSessionStorage.clearToken();

    let state = store.getState();
    let accountDetails = state.account.accountDetails;

    if (accountDetails.authorities?.includes(RoleEnums.ADMIN)) {
      axios.advisorService
        .get("clients")
        .then((response) => {
          this.setState({ clients: response.data });
        })
        .catch((error) => {
          this.errorHandler("Get Client List", error);
        });
    } else {
      axios.advisorService
        .get(`clients?userId.equals=${accountDetails.id}`)
        .then((response) => {
          this.setState({ clients: response.data });
        })
        .catch((error) => {
          this.errorHandler("Get Client List", error);
        });
    }
  }

  // Handles errors encountered on this page
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Client List":
        this.mainError = this.translation(`errorMessages.clientListLoadError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  prepareTableData = (clients) => {
    if (Array.isArray(clients) && clients.length > 0) {
      let newTableData = [];

      clients.forEach((client) => {
        let entry = {
          id: client.id,
          name: client.name,
          action: (
            <button
              className="btn btn-link p-0"
              onClick={() => this.viewStatistics(client)}
            >
              {this.translation(`statistics.viewStatistics`)}
            </button>
          ),
          sync: client.syncTimestamp ? formatDate(client.syncTimestamp, true) : this.translation(`commonText.notSync`)
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  toggleModalDetails() {
    this.setState({
      showModalDetails: !this.state.showModalDetails,
    });
  }

  viewStatistics(client) {
    this.statisticsDetails = <ClientStatistic client={client} />;
    this.toggleModalDetails();
  }

  render() {
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`commonText.client`),
        accessor: "name",
        show: "true",
        filterkey: "name",
        showsearch: "true",
        link: `/client/`,
        linkAccessor: "id",
      },
      {
        type: "data",
        header: this.translation(`commonText.action`),
        accessor: "action",
        show: "true",
        filterkey: "action",
        showsearch: "false",
        alignleft: "true",
      },
      {
        type: "data",
        header: this.translation(`commonText.syncDate`),
        accessor: "sync",
        show: "true",
        filterkey: "sync",
        showsearch: "false",
        alignleft: "true",
      },

    ];
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.translation(`adminClientList.clientList`)}
          </HeaderTitle>
        </Header>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  <h1>{this.translation(`commonText.clients`)}</h1>
                </CardTitle>
              </CardHeader>
              <CardBody>
                {Array.isArray(this.state.clients) &&
                  (this.state.clients.length > 0 ? (
                    <DynamicTable
                      data={this.prepareTableData(this.state.clients)}
                      infiniteScroll
                      columns={preparedColumns}
                    />
                  ) : (
                    <div className="text-center">
                      <Label>
                        {this.translation(
                          `adminClientList.notAssignedToAnyClient`
                        )}
                      </Label>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalInfo
          isOpen={this.state.showModalDetails}
          toggleModal={this.toggleModalDetails}
          modalTitle={this.translation(`statistics.title`)}
          modalBodyText={this.statisticsDetails}
        ></ModalInfo>
      </Container>
    );
  }
}

export default withTranslation()(ClientList);
