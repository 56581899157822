import React from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Table,
  Row,
  Col,
} from "reactstrap";
import axios from "../../../axios/Axios";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { isEmpty, LoadingIndicator } from "../../../helpers/GenericHelper";
import ModalError from "../../components/modal/ModalError";
import AdvisorFilterDropdownInput from "./dropdowns/AdvisorFilterDropdownInput";
import ClientFilterDropdownInput from "./dropdowns/ClientFilterDropdownInput";
import ClientStatistic from "./ClientStatistic";
/**
 * Class that displays the statistics calculated from the
 * advisor and client dashboards
 */
class StatisticsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientData: {},
      advisorData: {},
      caseId: "",

      clientsCount: 0,

      casesCount: 0,
      numberOfWhistleblowers: 0,
      numberOfWhistleblowersWithCases: 0,
      newCases: 0,
      acceptedCases: 0,
      inProgressCases: 0,
      closedCases: 0,
      clientCaseAverageTime: 0,

      advisorAverageResponseTime: 0,
      closedCasesPerAdvisor: 0,
      advisorTotalCases: 0,
      closingRatePerAdvisor: 0,

      individualCaseClosingDuration: 0,

      showModalError: false,
    };
    this.translation = this.props.t;
    this.itemsPerPage = 20;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalError = this.toggleModalError.bind(this);
    this.initialClientSetup = this.initialClientSetup.bind(this);
    this.initialAdvisorSetup = this.initialAdvisorSetup.bind(this);
    this.initialCaseSetup = this.initialCaseSetup.bind(this);

    this.numberOfClients = this.numberOfClients.bind(this);
    this.advisorStatistics = this.advisorStatistics.bind(this);
    this.individualCaseStatistics = this.individualCaseStatistics.bind(this);

    this.errorHandler = this.errorHandler.bind(this);
    this.handleClientChange = this.handleClientChange.bind(this);
    this.handleAdvisorChange = this.handleAdvisorChange.bind(this);
    this.handleCaseChange = this.handleCaseChange.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal error dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  async componentDidMount() {
    //General statistics
    this.numberOfClients();

    // Specific statistics
    this.initialClientSetup();
    this.initialAdvisorSetup();
    this.initialCaseSetup();
  }

  // Retrieve number of clients available
  async numberOfClients() {
    let clientsCount = await axios.advisorService
      .get(`clients/count`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    this.setState({
      clientsCount: clientsCount,
    });
  }

  // Initial setup of client data when opening the statistics view for the first time
  async initialClientSetup() {
    let clientData = await axios.advisorService
      .get(`clients`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          return response.data[0];
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    if (!isEmpty(clientData)) {
      this.setState({ clientData: clientData });
    }
  }

  // Calculate client-specific statistics

  // Initial setup of advisor data when opening the statistics view for the first time
  async initialAdvisorSetup() {
    let advisorData = await axios.advisorService
      .get(`get-all-users`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          return response.data[0];
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    if (!isEmpty(advisorData)) {
      this.setState({ advisorData: advisorData }, () => {
        this.advisorStatistics();
      });
    }
  }

  // Get advisor-specific statistics
  async advisorStatistics() {
    let advisorAverageResponseTime = await axios.advisorService
      .get(
        `whistleblowercases/responseTimePerAdvisor?userId.equals=${this.state.advisorData?.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    let closedCasesPerAdvisor = await axios.advisorService
      .get(
        `whistleblowercases/closedCases?userId.equals=${this.state.advisorData?.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    let advisorTotalCases = await axios.advisorService
      .get(
        `whistleblowercases/count?userId.equals=${this.state.advisorData?.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    let closingRatePerAdvisor = await axios.advisorService
      .get(
        `whistleblowercases/closingRatePerAdvisor?userId.equals=${this.state.advisorData?.id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    this.setState({
      advisorAverageResponseTime: advisorAverageResponseTime.toFixed(0),
      closedCasesPerAdvisor: closedCasesPerAdvisor,
      advisorTotalCases: advisorTotalCases,
      closingRatePerAdvisor: closingRatePerAdvisor.toFixed(2),
    });
  }

  // Initial setup of case data when opening the statistics view for the first time
  async initialCaseSetup() {
    let caseId = await axios.advisorService
      .get(`whistleblowercases`)
      .then((response) => {
        if (!isEmpty(response.data)) {
          return response.data[0]?.id;
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    if (caseId !== undefined) {
      this.setState(
        {
          caseId: caseId,
        },
        () => {
          this.individualCaseStatistics(caseId);
        }
      );
    }
  }

  // Calculate individual case statistics
  async individualCaseStatistics(caseId) {
    let individualCaseClosingDuration = await axios.advisorService
      .get(`whistleblowercases/caseClosingDuration/${caseId}`)
      .then((response) => {
        return response.data;
      });

    this.setState({
      individualCaseClosingDuration: individualCaseClosingDuration.toFixed(3),
    });
  }

  // Catches the errors encountered within this page
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Statistics":
        this.mainError = this.translation(
          `errorMessages.statisticsRetrievalError`
        );
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Handles changes to the client dropdown
  async handleClientChange(e) {
    let clientData = await axios.advisorService
      .get(`clientsWithDeletedCases/${e.value}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });
    this.setState({
      clientData: clientData,
    });
  }

  // Handles changes to the advisor dropdown
  async handleAdvisorChange(e) {
    this.setState(
      {
        advisorData: e.value,
      },
      () => {
        this.advisorStatistics();
      }
    );
  }

  // Handles changes to the case dropdown
  async handleCaseChange(e) {
    this.setState(
      {
        caseId: e.value,
      },
      () => {
        this.individualCaseStatistics(e.value);
      }
    );
  }

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.translation(`statistics.statisticsView`)}
          </HeaderTitle>
        </Header>
        <Card>
          <LoadingIndicator />
          <CardHeader>
            <CardTitle>
              <h1>{this.translation(`statistics.dashboardStatistics`)}</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <Table>
                  <tbody>
                    <tr>
                      <td style={{ paddingLeft: "0" }}>
                        <h2>
                          {this.translation(`statistics.generalStatistics`)}
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "0" }}>
                        {this.translation(`statistics.totalNumberOfClients`)}:{" "}
                        {this.state.clientsCount}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <div>
              <span>{this.translation(`statistics.filterByClient`)}:</span>
              <ClientFilterDropdownInput
                onChange={this.handleClientChange}
                client={this.state.clientData?.id}
                style={{ padding: "0px 0px" }}
              />
            </div>
            {this.state.clientData ? (
              <ClientStatistic client={this.state.clientData} />
            ) : (
              ""
            )}

            <Table>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "0" }}>
                    <h2>
                      {this.translation(`statistics.advisorStatistics`)}{" "}
                      {(this.state.advisorData?.lastName ||
                        this.state.advisorData?.firstName) == null
                        ? this.translation(`statistics.notAssigned`)
                        : `${this.state.advisorData?.lastName}, ${this.state.advisorData?.firstName}`}
                    </h2>
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ verticalAlign: "middle", paddingLeft: " 0rem " }}
                  >
                    {this.translation(`statistics.filterByAdvisor`)}:
                  </th>
                  <td>
                    <AdvisorFilterDropdownInput
                      onChange={this.handleAdvisorChange}
                      advisor={this.state.advisorData}
                    />
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(`statistics.averageResponseTime`)}:
                  </th>
                  <td>
                    {this.state.advisorAverageResponseTime !== 1
                      ? `${
                          this.state.advisorAverageResponseTime
                        } ${this.translation(`statistics.days`)}`
                      : `${
                          this.state.advisorAverageResponseTime
                        } ${this.translation(`statistics.day`)}`}
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(`statistics.numberOfClosedCases`)}:
                  </th>
                  <td>
                    {this.state.closedCasesPerAdvisor.total}{" "}
                    {this.translation(`statistics.outOf`)}{" "}
                    {this.state.advisorTotalCases}
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(`statistics.closingRate`)}:
                  </th>
                  <td>{Math.round(this.state.closingRatePerAdvisor)}%</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withTranslation()(StatisticsView);
