import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import i18n from "../../../i18n";

/*
 * Basic modal dialog box for displaying information
 */
const ModalInfo = (props) => {
  const { modalTitle, modalBodyText, isOpen, toggleModal, footerStyle } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    setModal(!modal);
    toggleModal && toggleModal(e);
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal size={props.size} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBodyText}</ModalBody>
        <ModalFooter style={footerStyle ?? null}>
          <Button color="primary" onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />{" "}
            {i18n.t(`commonButtonTexts.close`)}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalInfo;

ModalInfo.prototype = {
  modalTitle: PropTypes.string.isRequired,
  modalBodyText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
