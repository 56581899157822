import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap";
import {
  faEye,
  faEyeSlash,
  faFilePdf,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../../axios/Axios";
import { isEmpty } from "../../../helpers/GenericHelper";
import ModalError from "../../components/modal/ModalError";
import FilterStatisticByDateDropdown from "../statistics/dropdowns/FilterStatisticByDateDropdown";
import moment from "moment";
import PieChart from "./PieChart";
import {
  exportStatisticsAsPDF,
  exportStatisticsAsExcel,
} from "../../../helpers/exportStatistics";
import { trackPromise } from "react-promise-tracker";

/**
 * Class that displays the statistic by client
 */
class ClientStatistic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      casesCount: 0,
      numberOfWhistleblowers: 0,
      numberOfWhistleblowersWithCases: 0,
      newCases: 0,
      acceptedCases: 0,
      inProgressCases: 0,
      closedCases: 0,
      clientCaseAverageTime: 0,

      startDate: "",
      endDate: "",
      days: "",
      month: "",
      year: "",
      monthYear: "",

      isStatisticTotalCases: true,
      isStatisticTotalCasesNew: true,
      isStatisticTotalCasesAccepted: true,
      isStatisticTotalCasesInProgress: true,
      isStatisticTotalCasesClosed: true,

      showModalError: false,
      pdfDisable: false,
      excelDisable: false
    };

    this.translation = this.props.t;

    this.setupClientStatistics = this.setupClientStatistics.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);

    this.errorHandler = this.errorHandler.bind(this);

    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.setDays = this.setDays.bind(this);
    this.setMonthYear = this.setMonthYear.bind(this);
    this.setYear = this.setYear.bind(this);

    this.setStatisticTotalCases = this.setStatisticTotalCases.bind(this);
    this.setStatisticTotalCasesNew = this.setStatisticTotalCasesNew.bind(this);
    this.setStatisticTotalCasesAccepted =
      this.setStatisticTotalCasesAccepted.bind(this);
    this.setStatisticTotalCasesInProgress =
      this.setStatisticTotalCasesInProgress.bind(this);
    this.setStatisticTotalCasesClosed =
      this.setStatisticTotalCasesClosed.bind(this);
    this.exportAsPDF = this.exportAsPDF.bind(this);
    this.exportAsExcel = this.exportAsExcel.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.client)) {
      this.setupClientStatistics();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.client && prevProps.client.id !== this.props.client.id) {
      this.setupClientStatistics();
    }
  }

  // Calculate client-specific statistics
  async setupClientStatistics() {
    const { startDate, endDate, month, monthYear, days, year } = this.state;
    axios.advisorService
      .get(
        `whistleblowercases/totalCases?clientId.equals=${this.props.client?.id}&startDate=${startDate}&endDate=${endDate}&lastDays=${days}&month=${month}&year=${year}&monthYear=${monthYear}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ casesCount: response.data });
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    axios.advisorService
      .get(
        `whistleblowercases/clientTotalWhistleblowers/${this.props.client?.id}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ numberOfWhistleblowers: response.data });
        }
      });

    axios.advisorService
      .get(
        `whistleblowercases/clientWhistleblowersWithCases/${this.props.client?.id}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ numberOfWhistleblowersWithCases: response.data });
        }
      });

    axios.advisorService
      .get(
        `whistleblowercases/newCases?clientId.equals=${this.props.client?.id}&startDate=${startDate}&endDate=${endDate}&lastDays=${days}&month=${month}&year=${year}&monthYear=${monthYear}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ newCases: response.data });
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    axios.advisorService
      .get(
        `whistleblowercases/acceptedCases?clientId.equals=${this.props.client?.id}&startDate=${startDate}&endDate=${endDate}&lastDays=${days}&month=${month}&year=${year}&monthYear=${monthYear}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ acceptedCases: response.data });
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    axios.advisorService
      .get(
        `whistleblowercases/inProgressCases?clientId.equals=${this.props.client?.id}&startDate=${startDate}&endDate=${endDate}&lastDays=${days}&month=${month}&year=${year}&monthYear=${monthYear}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ inProgressCases: response.data });
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    axios.advisorService
      .get(
        `whistleblowercases/closedCases?clientId.equals=${this.props.client?.id}&startDate=${startDate}&endDate=${endDate}&lastDays=${days}&month=${month}&year=${year}&monthYear=${monthYear}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ closedCases: response.data });
        }
      })
      .catch((error) => {
        this.errorHandler("Get Statistics", error);
      });

    axios.advisorService
      .get(
        `whistleblowercases/clientCasesAverageClosingDuration/${this.props.client?.id}`
      )
      .then((response) => {
        if (!isEmpty(response.data)) {
          this.setState({ clientCaseAverageTime: response.data.toFixed(3) });
        }
      });
  }

  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Get Statistics":
        this.mainError = this.translation(
          `errorMessages.statisticsRetrievalError`
        );
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  dateConverter(date) {
    // Dates at the back end seems to require it to be ISO formated, but using toISOString moves the date back, adding true only adds the timezone it
    // Making it useless for the back end, this function is to remove the timezone offset
    // NOTE: timezone is useless here due to the date being used to compare and not saved.
    return (
      moment(date)
        .toISOString(true)
        .substring(0, moment(date).toISOString(true).indexOf("00.000") + 6) +
      "Z"
    );
  }

  setMonth(selectedMonth) {
    // "getMonth" retuns a number from 0 to 11 representing the month, the back end represents it as 1 to 12, adding 1 is nessesary for the back end to work properly
    let setMonthConverted = selectedMonth.getMonth() + 1;
    this.setState(
      {
        month: setMonthConverted,
        days: "",
        monthYear: "",
        startDate: "",
        endDate: "",
        year: "",
      },
      () => this.setupClientStatistics()
    );
  }

  setDays(days) {
    this.setState(
      {
        days: days,
        monthYear: "",
        startDate: "",
        endDate: "",
        year: "",
        month: "",
      },
      () => this.setupClientStatistics()
    );
  }

  setStartDate(date) {
    this.setState(
      {
        startDate: this.dateConverter(date),
        year: "",
        days: "",
        month: "",
        monthYear: "",
      },
      date !== "" && this.state.endDate !== ""
        ? () => this.setupClientStatistics()
        : null
    );
  }

  setEndDate(date) {
    this.setState(
      {
        endDate: this.dateConverter(date),
        year: "",
        days: "",
        month: "",
        monthYear: "",
      },
      this.state.startDate !== null && date !== ""
        ? () => this.setupClientStatistics()
        : null
    );
  }

  setMonthYear(date) {
    this.setState(
      {
        monthYear: this.dateConverter(date),
        startDate: "",
        endDate: "",
        year: "",
        days: "",
        month: "",
      },
      () => this.setupClientStatistics()
    );
  }

  setYear(date) {
    this.setState(
      {
        year: this.dateConverter(date),
        startDate: "",
        endDate: "",
        days: "",
        month: "",
        monthYear: "",
      },
      () => this.setupClientStatistics()
    );
  }

  setStatisticTotalCases() {
    this.setState({ isStatisticTotalCases: !this.state.isStatisticTotalCases });
  }

  setStatisticTotalCasesNew() {
    this.setState({
      isStatisticTotalCasesNew: !this.state.isStatisticTotalCasesNew,
    });
  }

  setStatisticTotalCasesAccepted() {
    this.setState({
      isStatisticTotalCasesAccepted: !this.state.isStatisticTotalCasesAccepted,
    });
  }

  setStatisticTotalCasesInProgress() {
    this.setState({
      isStatisticTotalCasesInProgress:
        !this.state.isStatisticTotalCasesInProgress,
    });
  }

  setStatisticTotalCasesClosed() {
    this.setState({
      isStatisticTotalCasesClosed: !this.state.isStatisticTotalCasesClosed,
    });
  }

  async exportAsPDF() {
    let {
      casesCount,
      numberOfWhistleblowers,
      numberOfWhistleblowersWithCases,
      newCases,
      acceptedCases,
      inProgressCases,
      closedCases,
      clientCaseAverageTime,
    } = this.state;

    // set all to true as to make all charts visible for dom-to-image
    this.setState(
      {
        isStatisticTotalCases: true,
        isStatisticTotalCasesNew: true,
        isStatisticTotalCasesAccepted: true,
        isStatisticTotalCasesInProgress: true,
        isStatisticTotalCasesClosed: true,
      },
      () => { }
    );

    let title = this.translation(`statistics.clientStatistics`) + ` ${this.props.client.name}`;
    await trackPromise(exportStatisticsAsPDF(
      title,
      casesCount,
      numberOfWhistleblowers,
      numberOfWhistleblowersWithCases,
      newCases,
      acceptedCases,
      inProgressCases,
      closedCases,
      clientCaseAverageTime
    ));

    this.setState({
      pdfDisable: false
    })
  }

  async exportAsExcel() {
    let {
      casesCount,
      numberOfWhistleblowers,
      numberOfWhistleblowersWithCases,
      newCases,
      acceptedCases,
      inProgressCases,
      closedCases,
      clientCaseAverageTime,
    } = this.state;

    let title = this.props.client.name;
    await trackPromise(exportStatisticsAsExcel(
      title,
      casesCount,
      numberOfWhistleblowers,
      numberOfWhistleblowersWithCases,
      newCases,
      acceptedCases,
      inProgressCases,
      closedCases,
      clientCaseAverageTime
    ));
    this.setState({
      excelDisable: false
    })
  }

  render() {
    let { client } = this.props;
    const roundedAverageCaseTime = Math.round(this.state.clientCaseAverageTime);
    return (
      <React.Fragment>
        <Row>
          <Col>
            <FilterStatisticByDateDropdown
              setMonth={this.setMonth}
              setDays={this.setDays}
              setStartDate={this.setStartDate}
              setEndDate={this.setEndDate}
              setMonthYear={this.setMonthYear}
              setYear={this.setYear}
            />
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Table>
              <tbody>
                <tr>
                  <td style={{ padding: "0" }}>
                    <h2>
                      {this.translation(`statistics.clientStatistics`)}{" "}
                      {client.name}
                    </h2>
                  </td>
                  <td></td>
                  <td className="text-right" style={{ minWidth: "110px" }}>
                    <Button
                      disabled={this.state.pdfDisable}
                      onClick={() => {
                        this.exportAsPDF()
                        this.setState({ pdfDisable: true })
                      }}
                      color="primary"
                      id="exportAsPdf"
                      style={{ marginRight: ".5rem" }}
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Button>
                    <UncontrolledTooltip placement="right" target="exportAsPdf">
                      {this.translation(`statistics.exportAsPdf`)}
                    </UncontrolledTooltip>
                    <Button
                      disabled={this.state.excelDisable}
                      onClick={() => {
                        this.exportAsExcel()
                        this.setState({ excelDisable: true })
                      }}
                      color="primary"
                      id="exportAsExcel"
                    >
                      <FontAwesomeIcon icon={faFileExcel} />
                    </Button>
                    <UncontrolledTooltip
                      placement="right"
                      target="exportAsExcel"
                    >
                      {this.translation(`statistics.exportAsExcel`)}
                    </UncontrolledTooltip>
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(`statistics.totalNumberOfCases`)}:{" "}
                    {this.state.casesCount.total}
                  </th>
                  <td></td>
                  <td>
                    {this.state.casesCount.total > 0 && (
                      <>
                        <Button
                          className="float-right"
                          color="primary"
                          size="m"
                          id="viewStatisticTotalCases"
                          onClick={this.setStatisticTotalCases}
                        >
                          <FontAwesomeIcon
                            icon={
                              this.state.isStatisticTotalCases
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </Button>
                        <UncontrolledTooltip
                          placement="right"
                          target="viewStatisticTotalCases"
                        >
                          {this.translation(`statistics.viewStatistics`)}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                </tr>
                {/* Pie chart for All Cases */}
                {this.state.casesCount.total > 0 && (
                  <tr>
                    <td colSpan="3">
                      <Collapse isOpen={this.state.isStatisticTotalCases}>
                        <PieChart
                          id="totalCasePieChart"
                          dataObject={this.state.casesCount}
                          title={this.translation(`statistics.allCases`)}
                        />
                      </Collapse>
                    </td>
                  </tr>
                )}
                <tr>
                  <th
                    style={{ paddingLeft: " 0rem ", verticalAlign: "middle" }}
                  >
                    {this.translation(`statistics.totalNumberOfWhistleblowers`)}
                    : {this.state.numberOfWhistleblowers}
                  </th>
                  <th rowSpan={3} />
                  <th rowSpan={3}>
                    {" "}
                    {this.state.newCases.total > 0 && (
                      <>
                        <Button
                          className="float-right"
                          color="primary"
                          size="m"
                          id="viewStatisticTotalNumberOfNewCase"
                          onClick={this.setStatisticTotalCasesNew}
                        >
                          <FontAwesomeIcon
                            icon={
                              this.state.isStatisticTotalCasesNew
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </Button>
                        <UncontrolledTooltip
                          placement="right"
                          target="viewStatisticTotalNumberOfNewCase"
                        >
                          {this.translation(`statistics.viewStatistics`)}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </th>
                </tr>
                <tr>
                  <th
                    style={{ paddingLeft: " 0rem ", verticalAlign: "middle" }}
                  >
                    {this.translation(
                      `statistics.totalNumberOfWhistleblowersWithCases`
                    )}
                    : {this.state.numberOfWhistleblowersWithCases}
                  </th>
                </tr>
                <tr>
                  <th
                    style={{ paddingLeft: " 0rem ", verticalAlign: "middle" }}
                  >
                    {this.translation(`statistics.totalNumberOfNewCases`)}:{" "}
                    {this.state.newCases.total}
                  </th>
                </tr>

                {/* Pie chart for New Cases */}
                {this.state.newCases.total > 0 && (
                  <tr>
                    <td colSpan="3">
                      <Collapse isOpen={this.state.isStatisticTotalCasesNew}>
                        <PieChart
                          id="newCasePieChart"
                          dataObject={this.state.newCases}
                          title={this.translation(`statistics.newCases`)}
                        />
                      </Collapse>
                    </td>
                  </tr>
                )}
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(`statistics.totalNumberOfAcceptedCases`)}:{" "}
                    {this.state.acceptedCases.total}
                  </th>
                  <td></td>
                  <td>
                    {this.state.acceptedCases.total > 0 && (
                      <>
                        <Button
                          className="float-right"
                          color="primary"
                          size="m"
                          id="viewStatisticTotalNumberOfAcceptedCase"
                          onClick={this.setStatisticTotalCasesAccepted}
                        >
                          <FontAwesomeIcon
                            icon={
                              this.state.isStatisticTotalCasesAccepted
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </Button>
                        <UncontrolledTooltip
                          placement="right"
                          target="viewStatisticTotalNumberOfAcceptedCase"
                        >
                          {this.translation(`statistics.viewStatistics`)}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                </tr>
                {/* Pie chart for Accepted Cases */}
                {this.state.acceptedCases.total > 0 && (
                  <tr>
                    <td colSpan="3">
                      <Collapse
                        isOpen={this.state.isStatisticTotalCasesAccepted}
                      >
                        <PieChart
                          id="acceptedCasePieChart"
                          dataObject={this.state.acceptedCases}
                          title={this.translation(`statistics.acceptedCases`)}
                        />
                      </Collapse>
                    </td>
                  </tr>
                )}
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(
                      `statistics.totalNumberOfInProgressCases`
                    )}
                    : {this.state.inProgressCases.total}
                  </th>
                  <td></td>
                  <td>
                    {this.state.inProgressCases.total > 0 && (
                      <>
                        <Button
                          className="float-right"
                          color="primary"
                          size="m"
                          id="viewStatisticTotalNumberOfInProgressCase"
                          onClick={this.setStatisticTotalCasesInProgress}
                        >
                          <FontAwesomeIcon
                            icon={
                              this.state.isStatisticTotalCasesInProgress
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </Button>
                        <UncontrolledTooltip
                          placement="right"
                          target="viewStatisticTotalNumberOfInProgressCase"
                        >
                          {this.translation(`statistics.viewStatistics`)}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                </tr>
                {/* Pie chart for In Progress Cases */}
                {this.state.inProgressCases.total > 0 && (
                  <tr>
                    <td colSpan="3">
                      <Collapse
                        isOpen={this.state.isStatisticTotalCasesInProgress}
                      >
                        <PieChart
                          id="inProgressCasePieChart"
                          dataObject={this.state.inProgressCases}
                          title={this.translation(`statistics.inProgressCases`)}
                        />
                      </Collapse>
                    </td>
                  </tr>
                )}
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(`statistics.totalNumberOfClosedCases`)}:{" "}
                    {`${this.state.closedCases.total}`}
                  </th>
                  <td></td>
                  <td>
                    {this.state.closedCases.total > 0 && (
                      <>
                        <Button
                          className="float-right"
                          color="primary"
                          size="m"
                          id="viewStatisticTotalNumberOfClosedCase"
                          onClick={this.setStatisticTotalCasesClosed}
                        >
                          <FontAwesomeIcon
                            icon={
                              this.state.isStatisticTotalCasesClosed
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </Button>
                        <UncontrolledTooltip
                          placement="right"
                          target="viewStatisticTotalNumberOfClosedCase"
                        >
                          {this.translation(`statistics.viewStatistics`)}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </td>
                </tr>
                {/* Pie chart for Closed Cases */}
                {this.state.closedCases.total > 0 && (
                  <tr>
                    <td colSpan="3">
                      <Collapse isOpen={this.state.isStatisticTotalCasesClosed}>
                        <PieChart
                          id="closedCasePieChart"
                          dataObject={this.state.closedCases}
                          title={this.translation(`statistics.closedCases`)}
                        />
                      </Collapse>
                    </td>
                  </tr>
                )}
                <tr>
                  <th style={{ paddingLeft: " 0rem " }}>
                    {this.translation(`statistics.averageDurationUntil`)}:{" "}
                    {`${roundedAverageCaseTime}`}
                    {roundedAverageCaseTime !== 1
                      ? ` ${this.translation(`statistics.days`)}`
                      : ` ${this.translation(`statistics.day`)}`}
                  </th>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </React.Fragment>
    );
  }
}

ClientStatistic.propTypes = {
  client: PropTypes.object.isRequired,
};

export default withTranslation()(ClientStatistic);
