import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, CardTitle, Col, Row, UncontrolledTooltip } from "reactstrap";
import axios from "../../../../axios/Axios";
import NoteTypeEnum from "../../../../enums/NoteTypeEnum";
import i18n from "../../../../i18n";
import { store } from "../../../../redux/store/index";

export default function CaseTitle(props) {
  CaseTitle.propTypes = {
    whistleblowerCase: PropTypes.object.isRequired,
    updateWhistleblowercaseState: PropTypes.func.isRequired,
    updateNotes: PropTypes.func.isRequired,
  };

  const [showChangeTitle, setShowChangeTitle] = useState(false);
  const [tempTitle, setTempTitle] = useState("");

  function handleShowChangeTitle() {
    setTempTitle(props.whistleblowerCase.title);
    setShowChangeTitle(!showChangeTitle);
  }

  function titleOnChange(e) {
    setTempTitle(e.target.value);
  }

  function submit() {
    let whistleblower = props.whistleblowerCase;
    let oldCasetitle = whistleblower.title;
    whistleblower.title = tempTitle;
    axios.advisorService
      .put(`whistleblowercases/change-title`, whistleblower)
      .then((result) => {
        props.updateWhistleblowercaseState(result.data);
        createNote(result.data, oldCasetitle);
        handleShowChangeTitle();
      });
  }

  async function createNote(whistleblowerCase, oldCaseTitle) {
    let storeState = store.getState();
    let accountDetails = storeState.account.accountDetails;

    let newNote = {
      notes: i18n.t("caseDetail.changedTitle", {
        oldCaseTitle: oldCaseTitle,
        newTitle: whistleblowerCase.title,
      }),
      creator: `${accountDetails.lastName}, ${accountDetails.firstName}`,
      operationType: "CREATED",
      noteType: NoteTypeEnum.AUTOMATIC,
      whistleblowercase: { id: props.whistleblowerCase.id },
    };

    axios.advisorService.post(`notes`, newNote).then((response) => {
      props.updateNotes(response.data, true);
    });
  }
  return (
    <CardTitle>
      {showChangeTitle ? (
        <AvForm onValidSubmit={submit}>
          <Row>
            <Col xs="6">
              <AvField
                className="float-left"
                name="title"
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: i18n.t(`fieldWarnings.fieldIsRequired`),
                  },
                }}
                value={tempTitle}
                onChange={titleOnChange}
              />
            </Col>

            <Col xs="6" className="text-right">
              <Button
                color="primary"
                onClick={handleShowChangeTitle}
                style={{ marginLeft: "5px" }}
              >
                {i18n.t(`commonButtonTexts.cancel`)}
              </Button>
              <Button
                color="primary"
                style={{ marginRight: "12px", marginLeft: "5px" }}
              >
                <FontAwesomeIcon icon={faSave} />{" "}
                {i18n.t(`commonButtonTexts.save`)}
              </Button>
            </Col>
          </Row>
        </AvForm>
      ) : (
        <React.Fragment>
          <h1 className="float-left">{props.whistleblowerCase.title}</h1>

          <Button
            className="float-right"
            color="primary"
            id="editCaseTitleButton"
            size="m"
            onClick={handleShowChangeTitle}
            style={{ marginRight: "12px" }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <UncontrolledTooltip placement="right" target="editCaseTitleButton">
            {i18n.t(`commonButtonTexts.edit`)}
          </UncontrolledTooltip>
        </React.Fragment>
      )}
    </CardTitle>
  );
}
