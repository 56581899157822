import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { store } from "../redux/store/index";

class Footer extends React.Component {
  render() {
    const { t } = this.props;
    const links = store.getState().company.companyDetails;
    return (
      <footer className="footer">
        <Container fluid>
          <Row className="text-muted">
            <Col xs={8} className="text-left">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link
                    to={{ pathname: links?.privacyLink }}
                    target="_blank"
                    className="text-muted mr-1"
                  >
                    {t(`footer.privacy`)}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={{ pathname: links?.disclaimerLink }}
                    target="_blank"
                    className="text-muted mr-1"
                  >
                    {t(`footer.legal`)}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={{ pathname: links?.contactLink }}
                    target="_blank"
                    className="text-muted mr-1"
                  >
                    {t(`footer.support`)}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={{ pathname: links?.imprintLink }}
                    target="_blank"
                    className="text-muted mr-1"
                  >
                    {t(`footer.imprint`)}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={4} className="text-right">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} -{" "}
                <Link to="/" className="text-muted">
                  INVENSITY
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
export default withTranslation()(Footer);
