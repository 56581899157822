import React from "react";
import { CustomInput, FormGroup, Input, Label } from "reactstrap";
import i18n from "../../../../i18n";

export default function inputGenerator(
  field,
  answer,
  handleFieldChange,
  isEdit,
  order = null
) {
  let input = null;
  let isChecked;
  switch (field.fieldType) {
    case "boolean":
      isChecked =
        answer !== undefined
          ? answer === "true" || answer === true
          : field.defaultAnswer;
      input = (
        <div style={{ display: "flex" }}>
          <Label style={{ marginRight: "5px" }}>
            {i18n.t("questionnaire.no")}
          </Label>
          <CustomInput
            type="switch"
            id={field.question + field.questionId}
            checked={isChecked}
            onChange={(e) => handleFieldChange(e, field, order)}
            disabled={!isEdit}
          />
          <Label>{i18n.t("questionnaire.yes")}</Label>
        </div>
      );
      break;
    case "enum":
      input = (
        <>
          {field.enumValues?.map((value) => (
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={field.questionId}
                  value={value}
                  defaultChecked={
                    answer !== undefined
                      ? answer === value
                      : field.defaultAnswer === value
                  }
                  disabled={!isEdit}
                  onChange={(e) => handleFieldChange(e, field, order)}
                />{" "}
                {i18n.t(`questionnaire.${value}`)}
              </Label>
            </FormGroup>
          ))}
        </>
      );
      break;
    case "checkbox":
      isChecked =
        answer !== undefined
          ? answer === "true" || answer === true
          : field.defaultAnswer;
      input = (
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={isChecked}
              disabled={!isEdit}
              onChange={(e) => handleFieldChange(e, field, order)}
            />{" "}
            {i18n.t(`questionnaire.${field.fieldLabel}`)}
          </Label>
        </FormGroup>
      );
      break;
    case "label":
      input = <Label>{answer}</Label>;
      break;
    case null:
      input = null;
      break;
    default:
      let defaultValue =
        answer ??
        (field.defaultAnswer !== ""
          ? i18n.t(`questionnaire.${field.defaultAnswer}`)
          : field.defaultAnswer);
      input = (
        <React.Fragment>
          <Input
            type={field.fieldType}
            name={"id" + field.questionId}
            value={defaultValue}
            disabled={!isEdit}
            onChange={(e) => handleFieldChange(e, field, order)}
            required={field.required !== undefined ? true : false}
            style={field.fieldType !== "dateField" ? { height: "150px" } : null}
          />
          {field?.fieldSubtitle ? (
            <Label>{i18n.t(`questionnaire.${field.fieldSubtitle}`)}</Label>
          ) : null}
        </React.Fragment>
      );
      break;
  }
  return input;
}
