import React from "react";
import { withTranslation } from "react-i18next";
import { Label, Button, Row, Col } from "reactstrap";
import Select from "react-select";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import { isEmpty } from "../../../helpers/GenericHelper";
import ModalError from "../modal/ModalError";
import ModalDelete from "../modal/DeleteModal";

class AdvisorModalClientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
      addToClientOptions: [],
      selectedClients: [],
      advisor: props.advisor ? props.advisor : null,
      parentPage: props.parentPage,
      showModalError: false,
      showModalDelete: false,
    };
    this.translation = this.props.t;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";
    this.modalDeleteEvent = null;

    this.toggleModalError = this.toggleModalError.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.handleAddToClientChange = this.handleAddToClientChange.bind(this);
    this.submitAddToClient = this.submitAddToClient.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }

  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  async componentDidMount() {
    let clientList = [];

    // "Advisor" received from the advisor list is actually from the User database of the advisor gateway.
    // It's not the actual advisor data stored in the advisor database of the advisor service.
    // So, the User's equivalent advisor value is taken, and the equivalent advisor's id is used to find the
    // clients it is assigned to.
    let clients = await axios.advisorService
      .get(`clients?userId.equals=${this.state.advisor?.id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Load Advisor Clients", error);
      });

    clientList = clients;

    this.setState({
      clientList: clientList,
    });

    // get all clients then check difference for the options
    axios.advisorService.get(`clients`).then((response) => {
      if (!isEmpty(response)) {
        let options = response.data.filter(
          (client) =>
            !clientList?.some(
              (advisorsClient) => client.id === advisorsClient.id
            )
        );
        options = options.map((option) => {
          return {
            value: option.id,
            label: option.name,
          };
        });
        this.setState({ addToClientOptions: options });
      }
    });
  }

  prepareTableData = (clients) => {
    if (Array.isArray(clients) && clients.length > 0) {
      let newTableData = [];

      clients.forEach((client) => {
        let entry = {
          name: client.name,
          action: (
            <Button
              className="btn-danger"
              onClick={() => this.removeAdvisorFromClient(client)}
            >
              {this.translation("commonButtonTexts.remove")}
            </Button>
          ),
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Load Advisor Clients":
        this.mainError = this.translation(`errorMessages.clientListLoadError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Handles changes to the advisor dropdown
  handleAddToClientChange(event) {
    this.setState({ selectedClients: event });
  }

  submitAddToClient() {
    for (let client of this.state.selectedClients) {
      axios.advisorService
        .put(
          `clients/addAdvisor?clientId=${client.value}&advisorId=${this.state.advisor.id}`
        )
        .then((response) => {
          // remove from options
          let updatedOptions = this.state.addToClientOptions.filter(
            (option) => option.value !== client.value
          );

          // add to list of assigned client
          let updatedClientList = this.state.clientList;
          updatedClientList.push(response.data);

          this.setState({
            clientList: updatedClientList,
            addToClientOptions: updatedOptions,
            selectedClients: [], // reset selected clients
          });
        })
        .catch((error) => {
          this.errorHandler(null, error);
        });
    }
  }

  toggleDeleteModal() {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  }

  removeAdvisorFromClient(client) {
    this.modalDeleteEvent = () =>
      axios.advisorService
        .put(
          `client/unassignAdvisorFromClient?clientId=${client.id}&userLogin=${this.state.advisor.login}`
        )
        .then((response) => {
          // add from options
          let updatedOptions = this.state.addToClientOptions;
          updatedOptions.push({
            value: response.data.id,
            label: response.data.name,
          });

          //remove from client list
          let updatedClientList = this.state.clientList.filter(
            (clientFromList) => clientFromList.id !== response.data.id
          );
          this.setState({
            clientList: updatedClientList,
            addToClientOptions: updatedOptions,
          });
        })
        .catch((error) => {
          this.errorHandler(null, error);
        });
    this.toggleDeleteModal();
  }

  render() {
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`commonText.client`),
        accessor: "name",
        show: "true",
        filterkey: "name",
        showsearch: "true",
        columnWidth: "100%",
      },
      {
        type: "data",
        header: this.translation(`commonText.action`),
        headerAlign: "right",
        accessor: "action",
        show: "true",
        showsearch: "true",
      },
    ];
    return (
      <React.Fragment>
        <Row>
          <Col sm={10}>
            <Select
              closeMenuOnSelect={false}
              options={this.state.addToClientOptions}
              onChange={this.handleAddToClientChange}
              placeholder={this.translation("commonText.select")}
              isMulti={true}
              value={this.state.selectedClients}
              noOptionsMessage={() => this.translation("commonText.noOptions")}
            />
          </Col>
          <Col sm={2} className="align-self-center">
            <Button
              color="primary"
              onClick={this.submitAddToClient}
              style={{ transform: " translateX(1rem)" }}
            >
              {this.translation("commonButtonTexts.add")}
            </Button>
          </Col>
        </Row>
        {Array.isArray(this.state.clientList) &&
          (this.state.clientList.length > 0 ? (
            <DynamicTable
              data={this.prepareTableData(this.state.clientList)}
              columns={preparedColumns}
              infiniteScroll
              removeMenuPortalTarget={true}
            />
          ) : (
            <div className="text-center">
              <Label>{this.translation(`adminClientList.noClient`)}</Label>
            </div>
          ))}
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalDelete
          isOpen={this.state.showModalDelete}
          event={this.modalDeleteEvent}
          toggleModal={this.toggleDeleteModal}
          buttonText={this.translation(`commonButtonTexts.remove`)}
          modalTitle={this.translation(`advisorCard.removeFromClient`)}
          modalBodyText={this.translation(
            `advisorCard.removeFromClientMessage`
          )}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(AdvisorModalClientList);
