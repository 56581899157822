import { faLink, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  UncontrolledTooltip,
  UncontrolledDropdown,
} from "reactstrap";
import axios from "../../../axios/Axios";
import DynamicTable from "../../../components/DynamicTable";
import { formatDate } from "../../../helpers/GenericHelper";
import DeleteModal from "../modal/DeleteModal";
import ModalError from "../modal/ModalError";
import ModalForm from "../modal/ModalForm";
import AddLinkedCase from "./AddLinkedCase";
import NoteTypeEnum from "../../../enums/NoteTypeEnum"

/**
 * Card that contains the list of cases linked to case that the user is
 * currently on
 */
class LinkedCasesCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalDelete: false,
      showModalError: false,
      showModalForm: false,
      modalForm: null,
      modalTitle: "",
      linkedCases: [],
    };

    this.translation = this.props.t;

    this.modalDeleteEvent = null;

    this.mainError = "";
    this.errorReason = "";
    this.errorResponse = "";

    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.toggleModalError = this.toggleModalError.bind(this);
    this.toggleModalForm = this.toggleModalForm.bind(this);
    this.redirect = this.redirect.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.showModalForm = this.showModalForm.bind(this);
    this.onModalFormSubmit = this.onModalFormSubmit.bind(this);
    this.removeLink = this.removeLink.bind(this);
  }

  // Toggles the Boolean that affects appearance of modal delete dialog box
  toggleModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  // Toggles the error modal dialog box
  toggleModalError() {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  }

  // Toggles the Boolean that affects the appearance of a modal form dialog box
  toggleModalForm() {
    this.setState({
      showModalForm: !this.state.showModalForm,
    });
  }

  componentDidMount() {
    axios.advisorService
      .get(`links?parentCaseId.equals=${this.props.whistleblowerCase?.id}`)
      .then((response) => {
        this.setState({
          linkedCases: response.data,
        });
      })
      .catch((error) => {
        this.errorHandler("Link Load Error", error);
      });
  }

  // Needed to change the contents of the case details page
  // Since it won't refresh if the path is changed
  redirect(linkedCaseId) {
    window.location.replace(`/case/${linkedCaseId}`);
  }

  // Removes the linking of cases
  removeLink(linkedCase) {
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      axios.advisorService
        .delete(
          `links?linkedCaseId.equals=${linkedCase.linkedCase?.id}&parentCaseId.equals=${linkedCase.parentCase?.id}`
        )
        .then(() => {
          this.addCaseNote(linkedCase);
          axios.advisorService
            .delete(
              `links?parentCaseId.equals=${linkedCase.linkedCase?.id}&linkedCaseId.equals=${linkedCase.parentCase?.id}`
            )
            .then(() => {   
              window.location.reload();
            })
            .catch((error) => {
              this.errorHandler("Link Deletion Error", error);
            });
        })
        .catch((error) => {
          this.errorHandler("Link Deletion Error", error);
        });
    };
  }

  async addCaseNote(linkedCase) {

    let userAccount = await axios.advisorService
      .get(`account`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });

    let newNote = {
      notes:`${linkedCase?.linkedCase.title}` + this.translation(`caseDetail.unlinkedCaseFrom`) + `${linkedCase?.parentCase.title}.`,
      creator: `${userAccount.lastName}, ${userAccount.firstName}`,
      operationType: "CREATED",
      noteType: NoteTypeEnum.AUTOMATIC,
      whistleblowercase:
          this.props.whistleblowerCase?.id !== null
          ? { id: this.props.whistleblowerCase?.id }
          : null,
    };

    axios.advisorService
      .post(`notes`, newNote)
      .then(() => {
      })
      .catch((error) => {
        this.errorHandler("Saving Notes", error);
      });
  }

  // Handles errors encountered in this component
  errorHandler(currentOperation, error) {
    switch (currentOperation) {
      case "Link Load Error":
        this.mainError = this.translation(`errorMessages.linkedCasesLoadError`);
        break;
      case "Link Update Error":
        this.mainError = this.translation(
          `errorMessages.linkedCasesListUpdateError`
        );
        break;
      case "Link Deletion Error":
        this.mainError = this.translation(`errorMessages.linkDeletionError`);
        break;
      default:
        this.mainError = this.translation(`errorMessages.internalServerError`);
        this.errorResponse = error.message;
        if (!this.state.showModalError) {
          this.toggleModalError();
        }
        return;
    }
    this.errorReason = this.translation(`errorMessages.failedToCommunicate`);
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  }

  // Sets form that shows up in the modal form dialog box
  showModalForm() {
    this.setState({
      modalForm: (
        <AddLinkedCase
          parentCase={this.props.whistleblowerCase}
          linkedCases={this.state.linkedCases}
        />
      ),
      modalTitle: this.translation(`linkCase.linkCase`),
    });
    this.toggleModalForm();
  }

  // Commands to execute after finishing up with the modal form
  async onModalFormSubmit(linkedData) {
    if (linkedData !== null) {
      // Get title of case recently linked, since linkedData only included the ID
      let caseTitle = await axios.advisorService
        .get(`whistleblowercases/${linkedData.linkedCase.id}`)
        .then((response) => {
          return response.data.title;
        })
        .catch((error) => {
          this.errorHandler("Link Update Error", error);
        });

      // Revise object structure of data to be linked
      let dataToInsert = {
        advisor: linkedData.advisor,
        createdOn: linkedData.createdOn,
        description: linkedData.description,
        id: linkedData.id,
        parentCase: linkedData.parentCase,
        linkedCase: {
          id: linkedData.linkedCase.id,
          title: caseTitle,
        },
      };

      // Push data to linked cases list
      let currentDataList = this.state.linkedCases;
      currentDataList.push(dataToInsert);

      this.toggleModalForm();

      this.setState({
        linkedCases: currentDataList,
      });
    } else {
      this.toggleModalForm();
    }
  }

  prepareTableData = (links) => {
    if (Array.isArray(links) && links.length > 0) {
      let newTableData = [];

      links.forEach((link) => {
        let deleteLink = (
          <React.Fragment>
            <UncontrolledDropdown className="d-inline-block">
              <FontAwesomeIcon
                icon={faTrash}
                id={`tooltipDelete-` + links.id}
                style={{ cursor: "pointer", transform: "translateX(12px)" }}
                onClick={() => this.removeLink(link)}
              />
              <UncontrolledTooltip
                placement="top"
                target={`tooltipDelete-` + links.id}
              >
                {this.translation(`commonButtonTexts.delete`)}
              </UncontrolledTooltip>
            </UncontrolledDropdown>
          </React.Fragment>
        );
        let entry = {
          id: link?.id,
          linkedCaseId: link?.linkedCase?.id,
          linkedCaseTitle: link?.linkedCase?.title,
          description: link?.description,
          advisor: link?.advisor,
          createdOn: formatDate(link.createdOn, true),
          delete: deleteLink,
          link: link,
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: "data",
        header: this.translation(`commonText.caseTitle`),
        accessor: "linkedCaseTitle",
        show: "true",
        filterkey: "linkedCaseTitle",
        showsearch: "true",
        linkFunc: this.redirect,
        linkFuncParameter: "linkedCaseId",
      },
      {
        type: "data",
        header: this.translation(`linkCase.description`),
        accessor: "description",
        show: "true",
        filterkey: "description",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation(`linkCase.advisorWhoLinked`),
        accessor: "advisor",
        show: "true",
        filterkey: "advisor",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation(`linkCase.linkedOn`),
        accessor: "createdOn",
        show: "true",
        filterkey: "createdOn",
        showsearch: "true",
      },
      {
        type: "data",
        header: this.translation(`commonButtonTexts.delete`),
        accessor: "delete",
        show: "true",
      },
    ];
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            {" "}
            <h1>{this.translation(`linkCase.linkedCases`)}</h1>
          </CardTitle>
          <div className="float-right" style={{ marginTop: "-2.9rem" }}>
            <Button
              color="primary"
              id="linkCaseButton"
              onClick={() => this.showModalForm()}
            >
              <FontAwesomeIcon icon={faLink} />
            </Button>
            <UncontrolledTooltip placement="left" target="linkCaseButton">
              {this.translation(`linkCase.linkCase`)}
            </UncontrolledTooltip>
          </div>
        </CardHeader>
        <CardBody>
          {Array.isArray(this.state.linkedCases) &&
            (this.state.linkedCases.length > 0 ? (
              <DynamicTable
                data={this.prepareTableData(this.state.linkedCases)}
                columns={preparedColumns}
                deleteAction={this.removeLink}
                infiniteScroll
              />
            ) : (
              <div className="text-center">
                <Label>{this.translation(`caseDetail.noCases`)}</Label>
              </div>
            ))}
        </CardBody>
        <DeleteModal
          isOpen={this.state.showModalDelete}
          onClose={this.toggleModalDelete}
          event={this.modalDeleteEvent}
          modalTitle={this.translation(`linkCase.deleteLink`)}
          modalBodyText={this.translation(`linkCase.deleteLinkQuestion`)}
        ></DeleteModal>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          eventOnSubmit={this.onModalFormSubmit}
          ref={this.state.modalForm}
          modalTitle={this.state.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
      </Card>
    );
  }
}

LinkedCasesCard.propTypes = {
  whistleblowerCase: PropTypes.object.isRequired,
};

export default withRouter(withTranslation()(LinkedCasesCard));
