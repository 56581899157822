import React, { useState, useEffect } from "react";
import Select from "react-select";
import { faEdit, faSave, faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    UncontrolledTooltip,
  } from "reactstrap";
import i18n from "../../../i18n";
import CaseGroupEnum from "../../../enums/CaseGroupEnum";
import axios from "../../../axios/Axios";
import NoteTypeEnum from "../../../enums/NoteTypeEnum"
import PropTypes from "prop-types";

export default function CaseGroup(props){
  CaseGroup.propTypes = {
    whistleblowerCase: PropTypes.object.isRequired,
    updateWhistleblowercaseState: PropTypes.func.isRequired,
    updateNotes: PropTypes.func.isRequired,
  };

    const [currentCaseGroup, setCurrentCaseGroup] = useState(props.whistleblowerCase.caseGroup)
    const [isEdit, setIsEdit] = useState(false);
    const [choices, setChoices]  = useState([]);

    useEffect(() => {
        let dropdownChoices = [];
        for(let caseGroup in CaseGroupEnum){
            let option = {
                value: caseGroup.toUpperCase(),
                label: CaseGroupEnum[caseGroup.toUpperCase()]
            };
            dropdownChoices.push(option);
        }

        setChoices(dropdownChoices);
      }, []);

    function submit() {
        const data = props.whistleblowerCase
        data.caseGroup = currentCaseGroup

        axios.advisorService
          .put("whistleblowercases", data)
          .then(response => {
                addCaseNote(currentCaseGroup)
                showEdit();
                props.updateWhistleblowercaseState(response.data);
          })
    }

    async function addCaseNote(currentCaseGroup) {
      let userAccount = await axios.advisorService
        .get(`account`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorHandler("Saving Notes", error);
        });
  
      let newNote = {
        notes: i18n.t(`caseDetail.changedCaseGroupTo`) + `${CaseGroupEnum[currentCaseGroup.toUpperCase()]}`,
        creator: `${userAccount.lastName}, ${userAccount.firstName}`,
        operationType: "CREATED",
        noteType: NoteTypeEnum.AUTOMATIC,
        whistleblowercase:
          props.whistleblowerCase?.id !== null
            ? { id: props.whistleblowerCase?.id }
            : null,
      };
      axios.advisorService
        .post(`notes`, newNote)
        .then((response)=>{
          props.updateNotes(response.data, true);
        })
        .catch((error) => {
          this.errorHandler("Saving Notes", error);
        });
    }

    function showEdit(){
        setIsEdit(!isEdit);
    }

    function changeSelectedCaseGroup(e){
        setCurrentCaseGroup(e.value)
    }

    return(
        <>
            <th>{i18n.t(`caseDetail.caseGroup`)}</th>
            <td>
                {isEdit ? (
                    <Select
                    value={choices.find(
                      (dataEntry) => dataEntry.value === currentCaseGroup
                    )}
                    options={choices}
                    isMulti={false}
                    onChange={(e) => changeSelectedCaseGroup(e)}
                  />
                ):
                props.whistleblowerCase.caseGroup ?
                i18n.t(`caseGroup.${props.whistleblowerCase.caseGroup}`)
                :
                "-"
                }
            </td>
            <td style={{"width":"30%"}}>
                {
                    isEdit ? 
                    <>
                    <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        onClick={() => submit()}
                        id="saveCaseGroup"
                        style={{ marginLeft: "5px" }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                        <UncontrolledTooltip 
                        placement="right"
                        target="saveCaseGroup">
                          {i18n.t(`commonButtonTexts.save`)}
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="primary"
                        size="m"
                        className="float-right"
                        style={{ marginLeft: "5px" }}
                        id="cancelCaseGroup"
                        onClick={() => showEdit()}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        <UncontrolledTooltip 
                        placement="right"
                        target="cancelCaseGroup">
                          {i18n.t(`commonButtonTexts.cancel`)}
                        </UncontrolledTooltip>
                      </Button>
                    </>
                    :
                    <>
                      <Button
                        className="float-right"
                        color="primary"
                        size="m"
                        id="editCaseGroup"
                        onClick={() => showEdit()}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <UncontrolledTooltip
                        placement="right"
                        target="editCaseGroup"
                      >
                        {i18n.t(`commonButtonTexts.edit`)}
                      </UncontrolledTooltip>
                    </>
                }
            </td>
        </>
    )
}