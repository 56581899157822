// List that contains values that used to set number of records per page for data table
export const recordsPerPage = [
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: 200,
    label: 200,
  },
];

export const UNREAD = "UNREAD";
export const READ = "READ";
export const IMPORTANT = "IMPORTANT";
export const VERYIMPORTANT = "VERYIMPORTANT";

export const Ascending = "asc";
export const Descending = "desc";
export const Default = '';

export const DEFAULTPAGELENGTH = 20;

//Export host URL for languages
export var language = window.location;

export var timeoutValue = 1500;
